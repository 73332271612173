import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import SucessfullyIMG from '../img/sucessfully.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';

const FormStyle = {
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '24px',
  color: 'rgba(41, 45, 63, 0.72)',
  fontFamily: 'Questrial',
};
const ButtonStyle = {
  fontFamily: 'Questrial',
  border: `2px solid #3555C8`,
  borderRadius: `12px`,
  backgroundColor: '#3555C8',
  justifyContent: 'center',
  padding: '8px 12px',
  cursor: 'pointer',
  fontSize: '14px',
  lineHeight: '24px',
  color: 'white',
  textTansform: 'none !important',
  marginLeft: '0px',
  width: '100%',
  height: '48px',
  marginTop: '18px',
};

interface Props {
  setStep: (step: number) => void;
  formValues: NonNullable<any>;
  setFormValues: React.SetStateAction<NonNullable<any>>;
  closeModal: () => void;
  updateLPT: () => void;
}

export default function PutOnSaleStep3({ setStep, formValues, setFormValues, closeModal, updateLPT }: Props) {
  const { t } = useTranslation();
  const history = useNavigate();
  const { handleSubmit } = useForm();
  const onSubmit: SubmitHandler<FieldValues> = async (values) => {
    setFormValues({
      ...formValues,
      ...values,
    });

    setStep(1);
    updateLPT();
    closeModal();

    history('/my-policies');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={FormStyle}>
      <Box
        sx={{
          textAlign: 'center',
          marginBottom: '20px',
        }}
      >
        <Box component='img' alt='Filters' src={SucessfullyIMG} sx={{ paddingRight: '10px' }} />
      </Box>
      <Box
        sx={{
          padding: '0 60px',
          color: '#292D3F',
          fontWeight: 'bold',
          marginBottom: '20px',
          textAlign: 'center',
          lineHeight: '42px',
        }}
      >
        <Typography variant='h2'>{t('put_on_sale.put_sale_success')}</Typography>
      </Box>

      <Box
        sx={{
          padding: '0 30px',
          textAlign: 'center',
        }}
      >
        <Typography variant='subtitle2'>{t('put_on_sale.manage')}</Typography>
      </Box>

      <Box>
        <Button type='submit' sx={ButtonStyle}>
          <Typography variant='button'>{t('put_on_sale.btn_finish')}</Typography>
        </Button>
      </Box>
    </form>
  );
}
