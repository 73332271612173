import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';

import { useStyles } from './styles';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InsuranceTable from './InsuranceTable';
import MyOrdersTable from 'features/policies/MyOrdersTable';
import { LiquidityProviderTable } from 'components/LiquidityProviderTable';
import { Box, Container, Typography } from '@mui/material';

export const PoliciesPage = () => {
  useDispatch();
  // const { actions } = usePoliciesSlice();
  const { classes } = useStyles();
  const { t } = useTranslation();

  useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('xl'));

  return (
    <Container maxWidth='xl' className={classes.tableStyle}>
      <Box className={classes.breadcrumbs}>
        <NavLink
          style={{
            textDecoration: 'none',
            color: '#292D3F',
            paddingRight: '14px',
          }}
          to='/home'
        >
          {t('policies.main')}
        </NavLink>
        {'>'}
        <NavLink
          style={{
            textDecoration: 'none',
            paddingLeft: '14px',
            fontWeight: 'bold',
            color: '#3555C8',
          }}
          to='/my-policies'
        >
          {t('policies.policies')}
        </NavLink>
      </Box>
      <Typography component='h1' className={classes.titleStyle}>
        {t('policies.policies')}
      </Typography>
      <Typography variant='h2' className={classes.tableTitleStyle}>
        {t('policies.insurances')}
      </Typography>
      <InsuranceTable />
      <Typography variant='h2' className={classes.tableTitleStyle}>
        {t('policies.liquidity')}
      </Typography>
      <LiquidityProviderTable />
      <Typography variant='h2' className={classes.tableTitleStyle}>
        {t('policies.orders')}
      </Typography>
      <MyOrdersTable />
      <br />
      <br />
      <br />
    </Container>
  );
};
