import React, { useState, useEffect } from 'react';
import { authStore, walletStore } from 'store';
import InputLabel, { InputLabelProps } from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { Box, styled, Typography, TypographyProps } from '@mui/material';
import { ButtonInstance, Loader } from 'components';
import { walletApi } from 'api/ws';
import { addressFormat } from 'utils/format';
import { useTranslation } from 'react-i18next';

interface IProps {
  close: () => void;
}

export const AccountsDropdown = observer(({ close }: IProps) => {
  const { t } = useTranslation();
  const accounts = toJS(walletStore.accounts);
  const [selected, setSelected] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);
    if (selected !== '' && accounts?.length) {
      authStore.signIn(accounts[Number(selected)]).finally(() => {
        setIsLoading(false);
        close();
      });
    }
  };

  useEffect(() => {
    return () => {
      walletApi.close();
    };
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    setSelected(event.target.value as string);
  };

  if (isLoading) {
    return (
      <Loader>
        <StyledLoaderText variant='h4'>{t('dropdowns.accounts.sign_message')}</StyledLoaderText>
      </Loader>
    );
  }

  return (
    <Box>
      <StyledHeader variant='h2'>{t('dropdowns.accounts.chose_account')}</StyledHeader>

      <FormControl fullWidth>
        {!accounts.length ? (
          <StyledErrorMessage>{t('dropdowns.accounts.add_account_message')}</StyledErrorMessage>
        ) : (
          <>
            <StyledLabel id='accounts-select-label'>{t('dropdowns.accounts.accounts')}</StyledLabel>
            <Select
              labelId='accounts-select-label'
              disabled={!accounts.length}
              id='accounts-simple-select'
              value={selected}
              label='account'
              onChange={handleChange}
            >
              {accounts?.map(({ address, meta }, ind) => (
                <MenuItem key={ind} value={ind}>
                  <Box sx={accountsContainer}>
                    <Typography variant='body1'>{meta.name}</Typography>
                    <Typography variant='body1'>{addressFormat(address)}</Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </>
        )}
      </FormControl>

      <Box sx={buttonContainer}>
        {!accounts.length ? (
          <ButtonInstance onClick={close} sx={{ width: '100%', margin: 0 }} title='close' />
        ) : (
          <>
            <ButtonInstance onClick={onSubmit} disabled={selected === ''} title='confirm' />
            <ButtonInstance onClick={close} title='cancel' sx={{ margin: 0 }} colorType='red' />
          </>
        )}
      </Box>
    </Box>
  );
});

const accountsContainer = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
} as React.CSSProperties;

const StyledLabel = styled(InputLabel)<InputLabelProps>({
  fontFamily: 'Poppins',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '24px',
});

const StyledHeader = styled(Typography)<TypographyProps>({
  marginBottom: '32px',
  fontFamily: 'Poppins',
  fontWeight: 600,
  fontSize: '28px !important',
  lineHeight: '40px',
  color: '#292D3F',
});

const StyledLoaderText = styled(Typography)<TypographyProps>({
  marginBottom: '32px',
  fontFamily: 'Poppins',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '28px',
  textAlign: 'center',
  color: '#292D3F',
});
const StyledErrorMessage = styled(Typography)<TypographyProps>({
  fontFamily: 'Poppins',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '28px',
  textAlign: 'center',
  color: '#292D3F',
});

const buttonContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '32px',
} as React.CSSProperties;
