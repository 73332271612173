import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Link, { LinkBaseProps } from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useTranslation } from 'react-i18next';

export const CustomBreadcrumbs = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    navigation('/home');
  };

  return (
    <Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon style={{ color: '#292D3F' }} fontSize='small' />}
        aria-label='breadcrumb'
      >
        <StyledLink underline='hover' key='1' href='/home' onClick={handleClick}>
          {t('breadcrumbs.main_page')}
        </StyledLink>
        <StyledTypography key='2'>{t('breadcrumbs.my_dao')}</StyledTypography>
      </Breadcrumbs>
    </Stack>
  );
};

const StyledLink = styled(Link)<LinkBaseProps>({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontSize: '16px',
  lineHeight: '32px',
  fontWeight: 300,
  color: '#292D3F',
});

const StyledTypography = styled(Typography)<TypographyProps>({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontSize: '16px',
  lineHeight: '32px',
  fontWeight: 500,
  color: '#3555C8',
});
