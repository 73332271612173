import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { getTimeLeft } from 'utils';

interface IInsuranceTimerProps {
  timestampMs: string;
}

const defaultRemainingTime = {
  hours: 0,
  minutes: 0,
  seconds: 0,
};

export const InsuranceTimerView = ({ timestampMs }: IInsuranceTimerProps) => {
  const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);

  useEffect(() => {
    updateRemainingTime(timestampMs);
    const intervalId = setInterval(() => {
      updateRemainingTime(timestampMs);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timestampMs]);

  const updateRemainingTime = (countdown: string) => {
    const timeLeft = getTimeLeft(countdown);
    setRemainingTime(timeLeft);
  };

  const addLeadingZero = (value: number) => {
    return String(value).padStart(2, '0');
  };

  return (
    <Box sx={{ minWidth: '120px', whiteSpace: 'nowrap' }}>
      {Object.values(remainingTime).map((value, ind) => {
        const formattedTime = addLeadingZero(value);
        return (
          <React.Fragment key={ind}>
            <span style={styleSpan}>{formattedTime}</span>
            {ind !== Object.values(remainingTime).length - 1 ? <span style={styleColon}>:</span> : null}
          </React.Fragment>
        );
      })}
    </Box>
  );
};

const styleSpan = {
  display: 'inline-block',
  padding: '4px 5px',
  width: '27px',
  backgroundColor: '#E6F0FD',
  borderRadius: '4px',
  boxSizing: 'border-box',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '24px',
  textAlign: 'center',
} as React.CSSProperties;

const styleColon = {
  padding: '0 4px',
  boxSizing: 'border-box',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '24px',
  color: 'rgba(41, 45, 63, 0.3)',
} as React.CSSProperties;

// const styleNoTime = {
//     backgroundColor: '#F2F2F3',
// } as React.CSSProperties;
