import React from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled, Typography } from '@mui/material';
import { VotesResultView } from './VotesResultView';
import { dateFormat, formatNumber } from 'utils';
import { historyColumns, locations, LocationsKeys } from 'constants/index';
import { IResults, IVotes } from 'types';

interface IProps {
  results: IResults[];
}

export const HistoryTableBody = ({ results }: IProps) => {
  const countVoteResults = (votes: IVotes[]) => {
    const approved = votes.reduce((acc, vote) => (vote.decision ? acc + 1 : acc), 0);
    const votesResult = votes.length && (approved / votes.length) * 100;
    const isApproved = votesResult >= 0.5 ? 'Approved' : 'Rejected';
    return { approved, votesResult, isApproved };
  };

  return (
    <TableBody>
      {results.map((result, index) => {
        const { insurance, votes } = result;
        const { startTime, endTime } = insurance;
        const { approved, isApproved, votesResult } = countVoteResults(votes);

        const row = {
          ...insurance,
          votesResult,
          startTime: dateFormat(startTime),
          endTime: dateFormat(endTime),
        };
        return (
          <TableRow hover role='checkbox' tabIndex={-1} key={index}>
            {historyColumns.map((column) => {
              const value = row[column.id];
              let render = null;

              switch (column.id) {
                case 'votesResult':
                  render = <VotesResultView approved={approved} length={votes.length} progress={votesResult} />;
                  break;
                case 'status':
                  render = (
                    <Typography variant='h5' sx={StyledButtonImitation}>
                      {isApproved}
                    </Typography>
                  );
                  break;
                case 'underwrite':
                case 'premium':
                  render = <Typography variant='body1'>{formatNumber(value) + ' USDT'}</Typography>;
                  break;
                case 'location':
                  render = <Typography variant='body1'>{locations[value as LocationsKeys] || value}</Typography>;
                  break;
                case 'startTime':
                case 'endTime': {
                  render = (
                    <Typography variant='body1' sx={{ maxWidth: '100px', width: '100%', float: 'right' }}>
                      {value}
                    </Typography>
                  );
                  break;
                }

                default:
                  render = <Typography variant='body1'>{value}</Typography>;
                  break;
              }

              return (
                <StyledTableCell key={column.id} align={column.align}>
                  {render}
                </StyledTableCell>
              );
            })}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

const StyledButtonImitation = {
  marginLeft: 'auto',
  padding: '8px 0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '103px',
  borderRadius: '12px',
  backgroundColor: 'rgba(41, 45, 63, 0.3)',
  color: '#FFFFFF',
} as React.CSSProperties;

const StyledTableCell = styled(TableCell)<TableCellProps>({
  color: '#292D3F',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '24px',
  textTransform: 'capitalize',
  boxSizing: 'border-box',
  [`&:first-of-type`]: {
    fontSize: '16px',
    lineHeight: '28px',
  },
});
