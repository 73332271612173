import { useTranslation } from 'react-i18next';
import SlideImg from '../img/slide.jpg';
import ModalForm from './ModalForm';
import { Box, Typography } from '@mui/material';

const slideStyle = {
  height: 'fit-content',
  minHeight: '450px',
  py: 12,
  width: '100%',
  background: `url(${SlideImg}) no-repeat`,
  backgroundSize: 'cover',
  backgroundPosition: 'top left',
  marginTop: '48px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

const slideBoxStyle = {
  // padding: '103px 120px',
  maxWidth: '1200px',
  width: '100%',
  margin: '0 auto',
  textAlign: 'center',
  boxSizing: 'border-box',
};

const slideTextStyle = {
  fontFamily: 'Questrial',
  fontWeight: 580,
  padding: '15px 0px 30px',
  color: '#FFFFFF',
  fontSize: '1.25rem !important',
};

export default function Slide() {
  const { t } = useTranslation();
  return (
    <Box sx={slideStyle}>
      <Box sx={slideBoxStyle}>
        <Typography variant='h1' sx={{ color: '#ffffff' }}>
          {t('slide.stay_safe')}
        </Typography>
        <Typography variant='h3' sx={slideTextStyle}>
          {t('slide.additional_title')}
        </Typography>
        <Box>
          <ModalForm data={{ insured: true }} />
        </Box>
      </Box>
    </Box>
  );
}
