import dayjs from 'dayjs';
import { authStore, walletStore } from 'store';
import { countryOptions } from './CountryOptions';
import axios from 'axios';
import { dateFormat } from 'utils';
import { appConfig } from '../config';

const getCountry = (value: string) => {
  const options = countryOptions.filter((countryData) => {
    return countryData.value === value;
  });

  return options[0]?.label ? options[0]?.label : '';
};

const createData = (
  id: number,
  name: string,
  location: string,
  mint: string,
  expiration: string,
  comission: string,
  underwrite: string,
  active: string,
  smtId: number,
  amount: number,
  tokenId: number,
  type: string,
  pricePerToken: number,
) => {
  return {
    id,
    name,
    location,
    mint,
    expiration,
    comission,
    underwrite,
    active,
    smtId,
    amount,
    tokenId,
    type,
    pricePerToken,
  };
};

export default async function GetMyOrders() {
  const liquidityProvider = [];

  let i = 1;

  try {
    const response = await axios.get(
      `${appConfig.apiUrl}/insurance/order?createdBy=${walletStore.walletAddress}&pageSize=1000000`,
      {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      },
    );

    if (response.data.total > 0) {
      for (const row of response.data.results) {
        liquidityProvider.push(
          createData(
            i++,
            row.insurances.risk,
            getCountry(row.insurances.location),
            dateFormat(row.insurances.startTime),
            dateFormat(row.insurances.endTime),
            `${row.insurances.premium} USDT`,
            `${row.insurances.underwrite}`,
            row.insurances.status,
            row.insurances.smtId,
            row.rest,
            row.id,
            row.type,
            row.pricePerToken,
          ),
        );
      }
    }
  } catch (error) {
    console.log('Missing data for Liquidity Providers table');
  }

  return liquidityProvider;
}
