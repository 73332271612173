import { appConfig } from '../config';
import { IAdminResponse, IAdminResponseWallets, IOracleInitEventRequest, IOracleInitEventResponse } from '../types';

import { API } from './API';

export function getAdminStats(): Promise<IAdminResponse> {
  return API.get<IAdminResponse>(`${appConfig.apiUrl}/stats`, {
    isPublic: false,
  }).then((response) => response.data);
}

export function initOracleEvent(body: IOracleInitEventRequest) {
  return API.post<IOracleInitEventRequest, IOracleInitEventResponse>(`${appConfig.apiUrl}/oracle/feed`, body, {
    isPublic: false,
  }).then((response) => response.data);
}

export function getWallets(): Promise<IAdminResponseWallets> {
  return API.get<IAdminResponseWallets>(`${appConfig.apiUrl}/oracle/user`, {
    isPublic: false,
  }).then((response) => response.data);
}
