import { ITablesActiveRequestBody, ITablesRequestBody, ITablesResponseBody } from 'types';
import { getDAOActive, getDAOHistory } from 'api/DAO';
import { createQuery } from 'react-query-kit';

export const useGetDAOHistory = createQuery<ITablesResponseBody, ITablesRequestBody>({
  queryKey: ['/voting', '/history'],
  fetcher: (variables) => getDAOHistory(variables),
});
export const useGetDAOActive = createQuery<ITablesResponseBody, ITablesActiveRequestBody>({
  queryKey: ['/voting', '/active'],
  fetcher: (variables) => getDAOActive(variables),
});
