import { appConfig } from '../config';
import axios, { AxiosPromise } from 'axios';
import { authStore } from 'store';

export const getInsurancesReq = (walletAddress: string): AxiosPromise<any> => {
  return axios.get(`${appConfig.apiUrl}/insurance/holder/${walletAddress}?pageSize=10000`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${authStore.accessToken}`,
    },
  });
};
