import { IActiveColumn } from 'types';

export const activeColumns: IActiveColumn[] = [
  { id: 'risk', label: 'dao.tables.type', minWidth: 180, align: 'left', sortType: 'character' },
  { id: 'location', label: 'location', minWidth: 120, align: 'right', sortType: 'character' },
  {
    id: 'startTime',
    label: 'start',
    minWidth: 138,
    align: 'right',
    format: (value: number) => value.toLocaleString('en-US'),
    sortType: 'date',
  },
  {
    id: 'endTime',
    label: 'expiration',
    minWidth: 140,
    align: 'right',
    format: (value: number) => value.toLocaleString('en-US'),
    sortType: 'date',
  },
  {
    id: 'premium',
    label: 'premium',
    minWidth: 150,
    align: 'right',
    format: (value: number) => value.toFixed(2),
    sortType: 'numeric',
  },
  {
    id: 'underwrite',
    label: 'underwrite',
    minWidth: 150,
    align: 'right',
    format: (value: number) => value.toFixed(2),
    sortType: 'numeric',
  },
  {
    id: 'timer',
    label: 'timer',
    minWidth: 160,
    align: 'right',
    format: (value: number) => value.toFixed(2),
    sortType: 'numeric',
    disabledSort: true,
  },
  {
    id: 'status',
    label: 'request',
    minWidth: 160,
    align: 'right',
    format: (value: number) => value.toFixed(2),
    sortType: 'numeric',
    disabledSort: true,
  },
];
