import React, { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';

import reportWebVitals from './reportWebVitals';
import { App } from 'App';
import { Loader } from 'components';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import './index.css';
import 'i18n';
import { Provider } from 'react-redux';
import { configureAppStore } from './store/configureStore';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient } from '@tanstack/react-query';
import { CssBaseline } from '@mui/material';
import { theme } from './resources';
import ErrorBoundary from './components/ErrorBoundary';

const defaultQueryConfig = {
  staleTime: 5 * 1000,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  retry: false,
};

export const queryClient = new QueryClient({
  defaultOptions: { queries: defaultQueryConfig },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

export const store = configureAppStore();
export const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

root.render(
  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <StrictMode>
              <Suspense fallback={<Loader fullSize />}>
                <ReactNotifications />
                <App />
                <ReactQueryDevtools initialIsOpen={false} />
              </Suspense>
            </StrictMode>
          </ThemeProvider>
        </CacheProvider>
      </Provider>
    </QueryClientProvider>
  </ErrorBoundary>,
);

reportWebVitals();
