import React from 'react';
import styled from '@emotion/styled';
import { Box, Modal } from '@mui/material';

interface IModalDAOProps {
  isOpen: boolean;
  close?: () => void;
  children: React.ReactNode;
}

export const ModalInstance = ({ isOpen, close, children }: IModalDAOProps) => {
  return (
    <Modal
      open={isOpen}
      onClose={close}
      sx={{ backgroundColor: 'rgba(0, 0, 0, 0.1) !important' }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <StyledContainer>
        <Box
          width='auto'
          height='100%'
          sx={{
            background: '#ffffff',
            borderRadius: '16px',
            padding: {
              xs: '16px',
              md: '32px',
            },
          }}
        >
          {children}
        </Box>
      </StyledContainer>
    </Modal>
  );
};

const StyledContainer = styled.div`
  position: relative;
  background: #ffffff;
  max-width: 480px;
  height: auto;
  top: 50%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  padding: 2px;
  color: rgba(0, 0, 139, 0.9);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08), 0px 32px 40px -2px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(41, 45, 63, 0.13);
  &:focus-visible {
    outline: none;
  }
`;
