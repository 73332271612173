import React, { useEffect, useState } from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { NavLink, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../img/logo.png';
import { Loader } from 'components';
import { useAdminRequire } from 'hooks';
import {
  Box,
  Container,
  CssBaseline,
  Drawer,
  DrawerProps,
  List,
  ListItem,
  ListItemButton,
  ListItemButtonBaseProps,
  Stack,
  styled,
} from '@mui/material';
import { i18n } from 'i18n';
import { useTranslation } from 'react-i18next';

const sideBarButtons = [
  { title: i18n.t('stats'), href: '/admin/stats' },
  { title: i18n.t('insurances'), href: '/admin/insurances' },
] as const;

export const AdminView = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { isLoading } = useAdminRequire();
  const navigation = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    sideBarButtons.forEach((item, index) => {
      if (item.href === location.pathname) setActiveTab(index);
    });
  }, [location.pathname]);

  if (isLoading) {
    return <Loader fullSize />;
  }

  const checkColors = (ind: number) => {
    return ind === activeTab
      ? { color: '#292D3F', backgroundColor: 'rgba(255, 255, 255, 0.72)' }
      : { color: '#FFFFFF', backgroundColor: 'transparent' };
  };

  return (
    <Box sx={mainBoxStyle}>
      <CssBaseline />
      <StyledDrawer
        variant='permanent'
        anchor='left'
        sx={{
          display: {
            xs: 'none',
            xl: 'block',
          },
        }}
      >
        <Box style={imgContainerStyle}>
          <Box component='img' src={logo} alt='logo' onClick={() => navigation('/home')} />
        </Box>
        <List>
          {sideBarButtons.map(({ title, href }, ind) => {
            const colors = checkColors(ind);
            return (
              <ListItem key={title}>
                <StyledListItemButton
                  sx={colors}
                  onClick={() => {
                    setActiveTab(ind);
                    navigation(href);
                  }}
                >
                  <StyledListItemText>{title}</StyledListItemText>
                </StyledListItemButton>
              </ListItem>
            );
          })}
        </List>
      </StyledDrawer>
      <Stack direction='column' flexGrow={1} justifyContent='space-between' height='100%' width='100%'>
        <Container component='main' maxWidth='xl'>
          <Outlet></Outlet>
        </Container>
        <Stack
          component='footer'
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
          sx={{
            mt: 4,
            padding: {
              xs: '15px 15px',
              sm: '20px 40px',
              md: '30px 80px',
            },
            background: '#E6F0FD',
            width: '100%',
          }}
        >
          <NavLink to='/'>
            <Box
              component='img'
              sx={{ width: '166px', height: 'auto', padding: 0, margin: 0 }}
              src={logo}
              alt='slider'
            />
          </NavLink>
          <Typography variant='h4' sx={{ color: '#3555C8' }}>
            {t('footer.decentro')}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

const mainBoxStyle = {
  display: 'flex',
  width: '100%',
  height: '100vh',
  overflowX: 'hidden',
  // backgroundColor: '#F9F9F9',
};

const StyledDrawer = styled(Drawer)<DrawerProps>({
  width: '239px',
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: '239px',
    backgroundColor: 'rgba(41, 45, 63, 0.3)',
    boxSizing: 'border-box',
    height: '100%',
    maxHeight: '100vh',
    borderRadius: '0 !important',
  },
});

const StyledListItemButton = styled(ListItemButton)<ListItemButtonBaseProps>({
  padding: '12px 16px',
  borderRadius: '12px',
});

const StyledListItemText = styled(Typography)<TypographyProps>({
  fontFamily: 'Poppins',
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '28px',
  textTransform: 'capitalize',
});

const imgContainerStyle = {
  width: '166px',
  margin: '24px 0 40px 39px',
  cursor: 'pointer',
} as React.CSSProperties;
