import PolicyImg from '../img/policy.png';
import FeeImg from '../img/fee.png';
import BlockchainImg from '../img/blockchain.png';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, Typography } from '@mui/material';

const whyStyle = {
  width: '100%',
  maxWidth: '1220px',
  margin: '0 auto',
};

const whyBlockPolicyStyle = {
  width: '100%',
  minHeight: {
    xs: '0',
    sm: '141px',
    lg: '0',
  },
  height: '100%',
  boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.08), 0px 32px 40px -2px rgba(0, 0, 0, 0.08)',
  borderRadius: '16px',
  padding: {
    xs: '16px 24px',
    lg: '24px 32px',
  },
  backgroundImage: `url(${PolicyImg})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right bottom',
  boxSizing: 'border-box',
};

const whyBlockFeeStyle = {
  ...whyBlockPolicyStyle,
  backgroundImage: `url(${FeeImg})`,
};

const whyBlockBlockchainStyle = {
  ...whyBlockPolicyStyle,
  backgroundImage: `url(${BlockchainImg})`,
};

const whyBlockTitleStyle = {
  color: '#3555C8',
  paddingBottom: '10px',
  fontWeight: '600',
  fontSize: '1.25rem !important',
};

const whyBlockTextStyle = {
  fontSize: '1rem !important',
  lineHeight: {
    xs: '20px',
    sm: '25px',
  },
  color: '#292D3F',
};

const whyTitleStyle = {
  color: '#292D3F',
  textAlign: 'center',
  padding: '34px 0 15px',
};

export default function Why() {
  const { t } = useTranslation();
  return (
    <Container maxWidth='xl'>
      <Box sx={whyStyle}>
        <Box sx={whyTitleStyle}>
          <Typography fontWeight={700} variant='h2'>
            {t('why.best')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Grid container spacing={3} justifyContent='center' maxWidth='1200px'>
            <Grid item xs={12} sm={6} lg={4} xl={4}>
              <Box sx={whyBlockPolicyStyle}>
                <Typography variant='h2' sx={whyBlockTitleStyle}>
                  {t('why.wider')}
                </Typography>
                <Typography variant='h4' sx={whyBlockTextStyle}>
                  {t('why.provides')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={4}>
              <Box sx={whyBlockFeeStyle}>
                <Typography variant='h2' sx={whyBlockTitleStyle}>
                  {t('why.fee')}
                </Typography>
                <Typography variant='h4' sx={whyBlockTextStyle}>
                  {t('why.no_administrative')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={8} lg={4} xl={4}>
              <Box sx={whyBlockBlockchainStyle}>
                <Typography variant='h2' sx={whyBlockTitleStyle}>
                  {t('why.innovative')}
                </Typography>
                <Typography variant='h4' sx={whyBlockTextStyle}>
                  {t('why.all_contract')}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
