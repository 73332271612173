import { INSURANCE_PAGE_SIZE, useGetPRMXLiquidityProviders, useGetPRMXOrders } from 'api/query/insurances';
import InsuranceTabCards from 'components/InsuranceTabCards';
import React, { useEffect, useMemo } from 'react';
import { useStyles } from './styles';
import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { InsuranceSortByKey, InsuranceOrderByKey } from './InsuranceSort';
import {
  IInsuranceRequestParams,
  IPaginationResponse,
  IUnifiedMarketCardData,
  InsuranceOrderByKeyType,
} from 'api/insurances';
import InsuranceSkeleton from './InsuranceSkeleton';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import useAuthWs from 'hooks/useAuthWs';
import { errorHandle } from 'utils/errorHandle';
import useForceUpdate from 'hooks/useForceUpdate';

interface Props {
  sortBy?: InsuranceSortByKey;
  orderBy?: InsuranceOrderByKey;
}

const MAPPING_SORT_BY: Record<InsuranceSortByKey, InsuranceOrderByKeyType> = {
  'Start date': 'startTime',
  'End date': 'endTime',
  Reward: 'premium',
  'Amount to Lock': 'amount',
  Available: 'underwrite',
  Total: 'underwrite',
  'NFT Price': 'pricePerToken',
};

const PRMXLiquidityProvider = ({ orderBy, sortBy }: Props) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const variables: IInsuranceRequestParams = {
    page: 1,
    filters: {
      order: orderBy,
      orderBy: sortBy ? MAPPING_SORT_BY[sortBy] : undefined,
    },
    pageSize: INSURANCE_PAGE_SIZE,
  };
  const { dependency, forceUpdate } = useForceUpdate();

  const {
    data: lqdProviders,
    refetch: refetchProvider,
    isFetching: isFetchingProvider,
    fetchNextPage: fetchNextProvider,
    hasNextPage: hasNextProvider,
  } = useGetPRMXLiquidityProviders({ variables, refetchOnMount: true });
  const {
    data: orders,
    refetch: refetchOrder,
    isFetching: isFetchingOrder,
    fetchNextPage: fetchNextOrder,
    hasNextPage: hasNextOrder,
  } = useGetPRMXOrders({ variables, refetchOnMount: true });

  const queryClient = useQueryClient();
  const socket = useAuthWs({
    onMessage: (event: MessageEvent<any>) => {
      const { topic, item } = JSON.parse(event.data);

      try {
        if (topic === 'liquidityProvided') {
          // *Remove item from the lqdProviders lists using item.id
          queryClient.setQueryData<InfiniteData<IPaginationResponse<IUnifiedMarketCardData>>>(
            useGetPRMXLiquidityProviders.getKey(variables),
            (data) => {
              if (data) {
                data.pages = data.pages.map((page) => {
                  page.results = page.results.filter((lp) => lp.id !== item.id);
                  return page;
                });
              }
              forceUpdate();
              return data;
            },
          );
        }

        if (topic === 'insuranceOrder') {
          // *Update item amount or remove item from the orders lists using item.id
          queryClient.setQueryData<InfiniteData<IPaginationResponse<IUnifiedMarketCardData>>>(
            useGetPRMXOrders.getKey(variables),
            (data) => {
              if (data) {
                data.pages = data.pages.map((page) => {
                  page.results = page.results.reduce((acc: IUnifiedMarketCardData[], insurance) => {
                    if (item.id === insurance.id) {
                      const availableParts = insurance.available?.split('/') || ['0', '0'];
                      const currentAvailable = Number(availableParts[0]);
                      if (item.amount === currentAvailable) {
                        return acc; // Do not include this insurance in the updated data
                      }
                      const newAvailable = `${currentAvailable - item.amount}/${Number(availableParts[1])}`;
                      return [...acc, { ...insurance, available: newAvailable }];
                    }
                    return [...acc, insurance];
                  }, []);
                  forceUpdate();
                  return page;
                });
              }
              return data;
            },
          );
        }
      } catch (error) {
        errorHandle(error);
      }
    },
  });

  const dataGroup = useMemo(() => {
    const lpData = lqdProviders?.pages.map((page) => page.results).flat() ?? [];
    const orderData = orders?.pages.map((page) => page.results).flat() ?? [];
    let results = null;
    if (['Available', 'Total', 'NFT Price'].includes(String(sortBy))) {
      results = [...orderData, ...lpData];
    } else {
      results = [...lpData, ...orderData];
    }
    const isSortedByAscend = orderBy === 'asc';

    // *Sort by Available is unable to archive on BE
    if (sortBy === 'Available') {
      results.sort((a, b) => {
        const aAvailable = (a.available || '0/0').split('/').map(Number)[0];
        const bAvailable = (b.available || '0/0').split('/').map(Number)[0];
        if (!aAvailable && !bAvailable) {
          return 0;
        } else if (!aAvailable && bAvailable) {
          return 1;
        } else if (aAvailable && !bAvailable) {
          return -1;
        }
        return isSortedByAscend ? aAvailable - bAvailable : bAvailable - aAvailable;
      });
    }

    return {
      data: results,
      lpData,
      orderData,
    };
  }, [lqdProviders, orders, orderBy, sortBy, dependency]);

  useEffect(() => {
    if (isFetchingProvider) return;
    if (dataGroup?.lpData?.length !== 0) {
      const liquidityIds = dataGroup?.lpData?.map((item) => item.id);
      socket.sendJsonMessage({ type: 'subscribe', topic: 'liquidityProvided', ids: liquidityIds });
    }
  }, [isFetchingProvider, socket.sendJsonMessage]);

  useEffect(() => {
    if (isFetchingOrder) return;

    if (dataGroup?.orderData?.length !== 0) {
      const fulfilledIds = dataGroup?.orderData?.map((item) => item.id);
      socket.sendJsonMessage({ type: 'subscribe', topic: 'insuranceOrder', ids: fulfilledIds });
    }
  }, [isFetchingOrder, socket.sendJsonMessage]);

  const refetch = () => {
    refetchOrder();
    refetchProvider();
  };

  const fetchNextPage = () => {
    if (hasNextProvider) fetchNextProvider();
    if (hasNextOrder) fetchNextOrder();
  };

  const isFetching = isFetchingProvider || isFetchingOrder;
  const hasNextPage = hasNextProvider || hasNextOrder;
  return (
    <>
      {isFetching ? <InsuranceSkeleton /> : <InsuranceTabCards data={dataGroup?.data ?? []} filters={refetch} />}
      {hasNextPage ? (
        <Stack direction='row' justifyContent='center' sx={{ width: '100%', mt: 4 }}>
          <Button
            onClick={() => {
              fetchNextPage();
            }}
            className={classes.insuranceButtonStyle}
            color='inherit'
            //   TODO: Update show more function
            //   onClick={showMore}
          >
            {t('buttons.show_more')}
          </Button>
        </Stack>
      ) : null}
    </>
  );
};

export default PRMXLiquidityProvider;
