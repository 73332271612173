export const getTimeLeft = (startDate: string) => {
  const startTime = new Date(startDate);
  startTime.setDate(startTime.getDate() + 1);
  const timeDifference = startTime.getTime() - Date.now();
  const timer = timeDifference > 0 ? timeDifference : 0;

  if (!timer) {
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  } else {
    let seconds = Math.floor(timer / 1000);
    let minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    minutes = minutes - hours * 60;
    seconds = seconds - hours * 60 * 60 - minutes * 60;

    return {
      hours,
      minutes,
      seconds,
    };
  }
};
