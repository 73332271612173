import { useMemo, useEffect, useState } from 'react';
import {
  MenuItem,
  Select,
  Checkbox,
  Box,
  Typography,
  Stack,
  IconButton,
  useMediaQuery,
  OutlinedInput,
  InputAdornment,
  SelectChangeEvent,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { web3Enable, isWeb3Injected } from '@polkadot/extension-dapp';
import { ModalInstance } from './modals';
import { useModal } from 'hooks';
import { authStore, walletStore } from 'store';
import { ButtonInstallPolkadot, ButtonInstance } from './buttons';
import { showNotification } from 'utils';
import { useTranslation } from 'react-i18next';
import { DateTimePicker, DesktopDatePicker } from '@mui/x-date-pickers';
import { locationsArr, events } from '../constants';
import { AccountsDropdown } from './dropdowns';
import { appConfig } from 'config';

import { calculateTransactionFee } from '../services/BuyPRMXBuyers';
import { Loader } from 'components';
import { NavLink } from 'react-router-dom';
import { ICardData } from 'types';
import { EventsValues } from 'features/policies/slice/types';
import { FormData } from './ModalForm';
import { useGetPricing } from 'api/query/pricing';
import { getPricingParams } from 'api/pricing';
import LoadingSpinner from './LoadingSpinner';
import { useDebouncedValue } from 'hooks/useDebounceValue';

const FormStyle = {
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '24px',
  color: 'rgba(41, 45, 63, 0.72)',
  fontFamily: 'Questrial',
};
const InfoStyle = {
  lineHeight: '20px',
  position: 'relative',
  justifyContent: 'space-between',
  marginBottom: '10px',
  marginTop: '10px',
  display: 'flex',
  flexDirection: 'row',
};
const TextFieldStyle = {
  color: 'rgba(41, 45, 63, 0.72)',
  display: 'inline-block',
  margin: '0px',
  borderRadius: '12px !important',
  background: 'rgba(41, 45, 63, 0.03)',
  width: '100%',
};

const FakeTextFieldStyle = {
  margin: 0,
  padding: '16.5px 14px',
  width: '100%',
  background: 'rgba(41, 45, 63, 0.03)',
  border: ' 1px solid rgba(118, 118, 118, 0.5)',
  borderRadius: '12px',
  color: 'rgba(41, 45, 63, 0.72)',
  boxSizing: 'border-box',
  lineHeight: '19px',
  fontFamily: 'Questrial',
};

const LabelStyle = {
  color: 'rgba(41, 45, 63, 0.72)',
  textTransform: 'capitalize',
};

const ContainerStyle = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '10px',
};

interface Props {
  setStep: (step: number) => void;
  formValues: FormData;
  setFormValues: React.Dispatch<React.SetStateAction<NonNullable<any>>>;
  data: ICardData;
  closeModal: () => void;
}

export default function Step1({ setStep, formValues, setFormValues, data, closeModal }: Props) {
  const [disabledButton, setDisabledButton] = useState(formValues.agree ? !formValues.agree : true);
  const [disabledButton2, setDisabledButton2] = useState(formValues.agree2 ? !formValues.agree2 : true);
  const isDevMode = useMemo(() => appConfig.mode === 'development', []);
  const { t } = useTranslation();
  const { isOpen, open, close } = useModal();
  const isOwner = data['holder'] === walletStore.walletAddress;

  const [txFee, setTxFee] = useState('');

  const [location, setLocation] = useState(formValues.location || '');
  const [event, setEvent] = useState(formValues.event || '');

  const [amountZero, setAmountZero] = useState(false);
  const [amount100, setAmount100] = useState(false);
  const [amountFull, setAmountFull] = useState(false);

  const [amount, setAmount] = useState(
    formValues.underwrite_amount ? formValues.underwrite_amount : !data.numberOfCopies ? 100 : data.numberOfCopies,
  );

  useEffect(() => {
    if (isOwner) {
      showNotification({
        title: 'Request error.',
        message: 'This operation is not available for owned assets.',
        type: 'warning',
        duration: 5000,
      });
    }
  }, [isOwner]);

  const checkboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisabledButton(!event.target.checked);
  };
  const checkboxChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisabledButton2(!event.target.checked);
  };

  const [isTab2, setiIsTab2] = useState(false);
  const [isTab3, setiIsTab3] = useState(false);
  const [amountZeroTab3, setAmountZeroTab3] = useState(false);

  useEffect(() => {
    function insertData() {
      if (data.country) {
        data.country = data.location !== 'Florida' ? data.country : 'Florida, USA';
        data.country = data.location !== 'California' ? data.country : 'California, USA';
        data.country = data.location !== 'San Francisco' ? data.country : 'California, USA';
        setLocation(data.country);
        setLocationZero(false);
      }
      if (data.event) {
        setEvent(data.event);
        setEventZero(false);
      }
      if (data.underwrite) {
        setAmount(data.underwrite);
      }

      // Tab2:
      if (data.holder) {
        if (data.startTime) {
          setStartDate(dayjs(data.startTime));
          setEndDate(dayjs(data.endTime));
        }

        setiIsTab2(true);

        if (data.amount) {
          setAmount(data.amount);
        }
      }

      // Tab3:
      if (data.available) {
        if (data.startTime) {
          setStartDate(dayjs(data.startTime));
          setEndDate(dayjs(data.endTime));
        }

        setiIsTab3(true);
        if (numberOfCopies && pricePerToken) {
          setAmount(Number(numberOfCopies) * pricePerToken);
        }

        if (data.title !== undefined) {
          setEvent(data.title.split(' ')[0]);
        }
      }
    }

    insertData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const extensionSetup: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    const extensions = await web3Enable('decentro.parametricx.io');
    if (!isWeb3Injected) {
      showNotification({
        title: 'No extension installed!',
        message: <ButtonInstallPolkadot />,
      });
      return;
    }
    if (extensions.length === 0) {
      showNotification({
        title: 'User rejected the request!',
        message: "Please open the extension and accept the website's permission",
      });
      return;
    }
    await walletStore.getAccounts();
    open();
  };

  const methods = useForm<FormData>();

  const formData = methods.watch();

  const onSubmit: SubmitHandler<FormData> = async (values) => {
    values.start_date = Number.isInteger(startDate) ? startDate : dayjs(startDate).unix() * 1000;
    values.end_date = Number.isInteger(endDate) ? endDate : dayjs(endDate).unix() * 1000;

    if (validDateCheck(dayjs(values.start_date), dayjs(values.end_date)) && !(isTab2 || isTab3)) return;
    if (!price?.recommended_premium) return;
    values.premium_amount = price?.recommended_premium;

    values.location = location;
    values.event = event;
    values.underwrite_amount = amount;

    if (!isTab2 && !isTab3) {
      values.step = 1;
    } else if (isTab2 && !isTab3) {
      values.step = 2;
      if (data.id) {
        values.PRMXLiquidityProvidersId = data.id;
      }
    } else if (isTab3) {
      values.step = 3;
      if (data.id) {
        values.PRMXSMLiquidityBuyersId = data.id;
        values.PRMXSMLiquidityBuyersAmount = Number(numberOfCopies);
        values.PRMXSMLiquidityBuyersPrice = data.price;
      }
    }

    setFormValues({
      ...formValues,
      ...values,
    });

    setStep(2);
  };

  const [startDate, setStartDate] = useState(() => {
    const start = isDevMode
      ? dayjs(new Date(dayjs(new Date()).add(10, 'minute').unix() * 1000))
      : dayjs(new Date(dayjs(new Date()).add(1, 'day').unix() * 1000));
    return formValues.start_date ? dayjs(formValues.start_date) : start;
  });

  const [startDateError, setStartDateZero] = useState('');

  const handleStartDate = (newValue: Dayjs | null) => {
    const minDate = isDevMode ? dayjs(new Date()).unix() : dayjs(new Date()).subtract(1, 'day').unix();
    const selectedEndDate = dayjs(endDate).unix();
    const currentDate = dayjs(newValue).unix();
    let startError = '';

    if (isNaN(Number(currentDate))) {
      startError = 'modal_steps.date_not_valid';
    } else if (currentDate < minDate) {
      startError = isDevMode ? 'modal_steps.date_should_greater' : 'modal_steps.cannot_start';
    } else if (currentDate > selectedEndDate) {
      startError = 'modal_steps.date_should_earlier';
    }

    if (currentDate > minDate && currentDate <= selectedEndDate) {
      setEndDateZero('');
    }

    if (newValue !== null) {
      setStartDate(newValue);
    }

    setStartDateZero(startError);
    return startError;
  };

  const [endDate, setEndDate] = useState(() => {
    const currentEndDate = isDevMode
      ? dayjs(new Date(dayjs(new Date()).add(20, 'minute').unix() * 1000))
      : dayjs(new Date(dayjs(new Date()).add(1, 'day').unix() * 1000)).add(1, 'year');
    return formValues.end_date ? dayjs(formValues.end_date) : currentEndDate;
  });

  const [endDateError, setEndDateZero] = useState('');

  const handleEndDate = (newValue: Dayjs | null) => {
    const minDate = isDevMode ? dayjs(new Date()).unix() : dayjs(new Date()).subtract(1, 'day').unix();
    const selectedStartDate = isDevMode
      ? dayjs(startDate).unix()
      : dayjs(startDate).add(1, 'month').subtract(1, 'day').unix();
    let endError = '';

    const maxDate = dayjs(startDate).add(1, 'year').unix();
    const current = dayjs(newValue).unix();

    if (isNaN(current)) {
      endError = 'modal_steps.date_not_valid';
    } else if (current < minDate) {
      endError = 'modal_steps.end_date_should_current';
    } else if (current < selectedStartDate) {
      endError = 'modal_steps.end_date_should_greater';
    } else if (current >= selectedStartDate && current >= maxDate) {
      endError = 'modal_steps.min_period';
    }

    if (selectedStartDate < current && current > minDate && selectedStartDate > minDate) {
      setStartDateZero('');
    }

    setEndDateZero(endError);
    if (newValue !== null) {
      setEndDate(newValue);
    }
    return endError;
  };

  const validDateCheck = (startDate: Dayjs, endDate: Dayjs) => {
    return !!handleStartDate(startDate) || !!handleEndDate(endDate);
  };

  const priceParams = useMemo(() => {
    const hoursBetween = endDate.diff(startDate, 'hour');

    return getPricingParams(Math.floor(startDate.valueOf() / 1000), Math.max(hoursBetween, 1), amount, location);
  }, [endDate, startDate, location, amount]);

  const [debouncedPriceParams] = useDebouncedValue(priceParams, 500);
  const { data: price, isFetching: isPriceFetching } = useGetPricing({
    variables: debouncedPriceParams,
  });

  // useEffect(() => {
  //   calculator();
  // });

  // TODO: Update this for USDT integration by calling some api from https://github.com/DecentroDev/4ire-rainfall-pricing
  // const calculator = () => {
  //   let calcAmount = 0;
  //   if (!data.available) {
  //     const locationCoefficient = locationsArr.find((el) => el.title === location)?.locationCoefficient || 0.1004;

  //     let amountOfDays = (dayjs(endDate).add(1, 'month').unix() - dayjs(startDate).add(1, 'month').unix()) / 86400;

  //     // For testing:
  //     if (amountOfDays < 1) {
  //       amountOfDays = 2;
  //     }

  //     const platformConstant = 1.1 / 100;

  //     if (amountOfDays) {
  //       calcAmount = Number((amountOfDays * locationCoefficient * platformConstant * amount).toFixed(4));
  //       setFormulaAmount(calcAmount);
  //     } else {
  //       setFormulaAmount(0);
  //     }
  //   } else {
  //     // Amount to receive if event occurs = premium / underwrite * 100 * amount:
  //     if (Number(numberOfCopies) && data.SMLpremium !== undefined && data.SMLunderwrite !== undefined) {
  //       calcAmount = Number(((data.SMLpremium / data.SMLunderwrite) * 100 * Number(numberOfCopies)).toFixed(4));
  //       setFormulaAmount(calcAmount);
  //     } else {
  //       setFormulaAmount(0);
  //     }
  //   }

  //   if (Number(amount) < Number(calcAmount)) {
  //     setAmountFull(true);
  //   } else {
  //     setAmountFull(false);
  //   }
  // };

  useEffect(() => {
    if (!price?.recommended_premium) return;
    if (formData.underwrite_amount && formData.start_date && formData.end_date) {
      try {
        setTimeout(
          async () =>
            setTxFee(
              await calculateTransactionFee({
                ...formData,
                start_date: startDate,
                end_date: endDate,
                premium_amount: price.recommended_premium,
              }),
            ),
          2000,
        );
      } catch (error) {
        console.log('Error fee', error);
      }
    }
  }, [formData, price?.recommended_premium]);

  // const [formulaAmount, setFormulaAmount] = useState<number>(0);

  const handleChangeAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isSafeInteger(+event.target.value)) {
      const value = Number(event.target.value);
      setAmount(value);
      if (value % 100 !== 0) {
        setAmount100(true);
      } else {
        setAmount100(false);
      }
      if (!value || +value === 0) {
        setAmountZero(true);
      } else {
        setAmountZero(false);
      }
    }
  };

  const [numberOfCopies, setNumberOfCopies] = useState(
    data.available ? data.available.split('/')[0] : data.amountPopup,
  );
  const handleChangeNumberOfCopies = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isSafeInteger(+event.target.value)) {
      const value = Number(event.target.value);
      setNumberOfCopies(value);

      if (pricePerToken !== undefined) {
        setAmount(value * pricePerToken);
      } else {
        setAmount(0);
      }

      if (
        Number(value) < 1 ||
        (data.available !== undefined && value > Number(data.available.split('/')[0])) ||
        !Number.isInteger(Number(value))
      ) {
        setAmountZeroTab3(true);
      } else {
        setAmountZeroTab3(false);
      }
    }
  };

  const [pricePerToken, setPricePerToken] = useState(data.price);
  const handleChangePricePerToken = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isSafeInteger(+event.target.value)) {
      const value = Number(event.target.value);
      setPricePerToken(value);
    }
  };

  const currentLocations = useMemo(() => {
    const filteredLocation = event
      ? locationsArr.filter((item) => item.events.includes(event as EventsValues))
      : locationsArr;
    return filteredLocation.map((location) => location.title);
  }, [event]);

  const handleChangeLocation = (event: SelectChangeEvent<string>) => {
    setLocation(event.target.value);

    if (event.target.value === '') {
      setLocationZero(true);
    } else {
      setLocationZero(false);
    }
  };

  const handleChangeEvent = (event: SelectChangeEvent<string>) => {
    const selectedEvent = event.target.value;
    setEvent(selectedEvent);
    setLocation((current) => {
      const location = locationsArr.find(
        (item) => item.title === current && item.events.includes(selectedEvent as EventsValues),
      )
        ? current
        : '';
      if (!location) {
        setLocationZero(true);
      }
      return location;
    });

    if (selectedEvent === '') {
      setEventZero(true);
    } else {
      setEventZero(false);
    }
  };

  const [locationZero, setLocationZero] = useState(location === '');
  const [eventZero, setEventZero] = useState(true);

  const isDisable =
    amountZero ||
    amountZeroTab3 ||
    disabledButton ||
    disabledButton2 ||
    amount100 ||
    amountFull ||
    !!startDateError ||
    !!endDateError ||
    locationZero ||
    eventZero ||
    isPriceFetching ||
    isOwner;

  const matchesSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} style={FormStyle}>
        <Box>
          <Stack
            direction='row'
            width='100%'
            alignItems='center'
            sx={{ justifyContent: { xs: 'space-between', md: 'left' } }}
          >
            <Typography variant='h2' sx={{ color: '#292D3F', fontWeight: 'bold', marginBottom: '10px' }}>
              {data.holder && !data.available && t('modal_steps.become_provider')}
              {!data.holder && !data.available && t('modal_steps.buy_prmx')}
              {data.available && t('modal_steps.secondary_market')}
            </Typography>
            {matchesSm && (
              <IconButton onClick={closeModal}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-x'
                >
                  <path d='M18 6 6 18' />
                  <path d='m6 6 12 12' />
                </svg>
              </IconButton>
            )}
          </Stack>

          {isTab2 || isTab3 ? (
            <Box style={ContainerStyle}>
              <Box>
                <Typography variant='subtitle1' sx={LabelStyle}>
                  {t('modal_steps.location')}
                </Typography>
                <Box {...methods.register('location')} sx={FakeTextFieldStyle}>
                  <Typography variant='subtitle2'>{location}</Typography>
                </Box>
              </Box>
              <Box>
                <Typography variant='subtitle1' sx={LabelStyle}>
                  {t('modal_steps.event')}
                </Typography>
                <Box {...methods.register('event')} sx={FakeTextFieldStyle}>
                  <Typography variant='subtitle2'>{event}</Typography>
                </Box>
              </Box>
            </Box>
          ) : (
            <>
              <Stack width='100%' direction='row' alignItems='center' gap={1}>
                <Box
                  sx={{
                    width: '100%',
                  }}
                >
                  <Typography variant='subtitle1' sx={LabelStyle}>
                    {t('modal_steps.location')}
                  </Typography>
                  <Select
                    {...methods.register('location')}
                    value={location}
                    className='InsuranceSelect'
                    style={TextFieldStyle}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={handleChangeLocation}
                    disabled={isTab2 || isTab3}
                  >
                    {currentLocations.map((title: string) => (
                      <MenuItem key={title} value={title}>
                        {title}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                  }}
                >
                  <Typography variant='subtitle1' sx={LabelStyle}>
                    {t('modal_steps.event')}
                  </Typography>
                  <Select
                    {...methods.register('event')}
                    value={event}
                    className='InsuranceSelect'
                    style={TextFieldStyle}
                    onChange={handleChangeEvent}
                    disabled={isTab2 || isTab3}
                  >
                    {events.map((event: EventsValues) => (
                      <MenuItem key={event} value={event}>
                        {event}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Stack>
              {(locationZero || eventZero) && (
                <Typography variant='subtitle1' sx={{ color: '#FF8663' }}>
                  {t('modal_steps.select_location')}
                </Typography>
              )}
            </>
          )}

          <Box
            sx={{
              width: '100%',
              borderTop: '1px solid rgba(41, 45, 63, 0.13)',
              marginTop: '10px',
              height: '10px',
            }}
          >
            &nbsp;
          </Box>

          {
            <>
              <Stack width='100%' direction='row' alignItems='center' gap={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box sx={{ width: '100%' }}>
                    <Typography
                      variant='subtitle1'
                      sx={{
                        color: 'rgba(41, 45, 63, 0.72)',
                      }}
                    >
                      {t('modal_steps.start_date')}
                    </Typography>
                    {isDevMode ? (
                      <DateTimePicker
                        inputFormat='MM/DD/YYYY HH:mm'
                        minDate={dayjs(new Date())}
                        disablePast={true}
                        value={startDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...methods.register('start_date')}
                            style={TextFieldStyle}
                            className={'DatePickerFull'}
                          />
                        )}
                        onChange={handleStartDate}
                        disabled={isTab2 || isTab3}
                      />
                    ) : (
                      <DesktopDatePicker
                        inputFormat='MM/DD/YYYY'
                        minDate={dayjs(new Date())}
                        disablePast={true}
                        value={startDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...methods.register('start_date')}
                            style={TextFieldStyle}
                            className={'DatePickerFull'}
                          />
                        )}
                        onChange={handleStartDate}
                        disabled={isTab2 || isTab3}
                      />
                    )}
                  </Box>
                  <Box
                    sx={{
                      width: '100%',
                    }}
                  >
                    <Typography
                      variant='subtitle1'
                      sx={{
                        color: 'rgba(41, 45, 63, 0.72)',
                      }}
                    >
                      {t('modal_steps.end_date')}
                    </Typography>
                    {isDevMode ? (
                      <DateTimePicker
                        inputFormat='MM/DD/YYYY HH:mm'
                        value={endDate}
                        disablePast={true}
                        minDate={dayjs(new Date())}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...methods.register('end_date')}
                            style={TextFieldStyle}
                            className={'DatePickerFull'}
                          />
                        )}
                        onChange={handleEndDate}
                        disabled={isTab2 || isTab3}
                      />
                    ) : (
                      <DesktopDatePicker
                        inputFormat='MM/DD/YYYY'
                        value={endDate}
                        minDate={dayjs(new Date())}
                        disablePast={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...methods.register('end_date')}
                            style={TextFieldStyle}
                            className={'DatePickerFull'}
                          />
                        )}
                        onChange={handleEndDate}
                        disabled={isTab2 || isTab3}
                      />
                    )}
                  </Box>
                </LocalizationProvider>
              </Stack>
              {startDateError || endDateError ? (
                <Typography variant='subtitle1' sx={{ color: '#FF8663' }}>
                  {t(startDateError || endDateError)}
                </Typography>
              ) : null}
            </>
          }

          <Box
            sx={{
              width: '100%',
              borderTop: '1px solid rgba(41, 45, 63, 0.13)',
              marginTop: '10px',
              height: '10px',
            }}
          >
            &nbsp;
          </Box>

          {data.available && (
            <Stack width='100%' direction='row' alignItems='center' gap={1}>
              <Stack
                direction='column'
                alignItems='left'
                sx={{
                  width: '100%',
                }}
              >
                <Typography
                  variant='subtitle1'
                  sx={{
                    color: 'rgba(41, 45, 63, 0.72)',
                    position: 'relative',
                  }}
                >
                  {t('modal_steps.number_of_copies')}
                </Typography>
                <TextField
                  {...methods.register('number_of_copies')}
                  style={TextFieldStyle}
                  className={'TextFieldFull'}
                  value={numberOfCopies}
                  onChange={handleChangeNumberOfCopies}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Typography variant='subtitle2'>{t('modal_steps.copies')}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>

              <Stack
                direction='column'
                alignItems='left'
                sx={{
                  width: '100%',
                }}
              >
                <Typography
                  variant='subtitle1'
                  sx={{
                    color: 'rgba(41, 45, 63, 0.72)',
                    position: 'relative',
                  }}
                >
                  {t('modal_steps.price')}
                </Typography>
                <TextField
                  {...methods.register('price_per_token')}
                  style={TextFieldStyle}
                  className={'TextFieldFull'}
                  value={pricePerToken}
                  onChange={handleChangePricePerToken}
                  disabled={isTab2 || isTab3}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Typography variant='subtitle2'>{t('USDT')}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Stack>
          )}

          {amountZeroTab3 && isTab3 && data.available !== undefined && (
            <Typography variant='subtitle1' sx={{ color: '#FF8663' }}>
              {t('modal_steps.enter')} {data.available.split('/')[0]}.
            </Typography>
          )}

          <Typography
            variant='subtitle1'
            sx={{
              position: 'relative',
              color: 'rgba(41, 45, 63, 0.72)',
            }}
          >
            <Typography variant='subtitle1'>
              {!data.holder && t('modal_steps.underwrite')}
              {data.holder && !data.available && t('modal_steps.amount_invest')}
              {data.holder && data.available && t('modal_steps.amount_pay')}
            </Typography>
          </Typography>
          <TextField
            {...methods.register('underwrite_amount')}
            style={TextFieldStyle}
            className={'TextFieldFull'}
            value={amount}
            onChange={handleChangeAmount}
            disabled={isTab2 || isTab3}
            variant='outlined'
            error={amount100 && !amountZero}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Typography variant='subtitle2'>{t('USDT')}</Typography>
                </InputAdornment>
              ),
            }}
          />
          <Box
            sx={{
              color: 'rgba(41, 45, 63, 0.72)',
              marginBottom: '10px',
            }}
          >
            <Typography variant='subtitle1' sx={{ fontSize: { xs: '0.75rem' } }}>
              {!data.holder && t('modal_steps.amount_money')}
            </Typography>
            {amountZero && (
              <Typography variant='subtitle1' sx={{ color: '#FF8663' }}>
                {t('modal_steps.choose_underwrite')}
              </Typography>
            )}

            {amount100 && !amountZero && (
              <Typography variant='subtitle1' sx={{ color: '#FF8663' }}>
                {t('modal_steps.underwrite_amount')}
              </Typography>
            )}

            {amountFull && (
              <Typography variant='subtitle1' sx={{ color: '#FF8663' }}>
                {t('modal_steps.the_insurance')}
              </Typography>
            )}
          </Box>

          <>
            <Typography
              variant='subtitle1'
              sx={{
                position: 'relative',
                color: 'rgba(41, 45, 63, 0.72)',
              }}
            >
              <Box>
                {!data.holder && t('modal_steps.premium')}
                {(data.available || data.holder) && t('modal_steps.amount_to_receive')}
              </Box>
            </Typography>

            <TextField
              {...methods.register('premium_amount')}
              style={TextFieldStyle}
              className={'TextFieldFull'}
              disabled={true}
              value={price?.recommended_premium}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    {isPriceFetching ? (
                      <LoadingSpinner size={20} />
                    ) : (
                      <Typography variant='subtitle2'>
                        {t('USDT')} {!data.available && t('modal_steps.per_contract')}
                      </Typography>
                    )}
                  </InputAdornment>
                ),
              }}
            />

            {data.holder && !data.available && (
              <Typography
                variant='subtitle1'
                sx={{
                  color: 'rgba(41, 45, 63, 0.72)',
                  marginBottom: '10px',
                }}
              >
                <Box>{t('modal_steps.if_event')}</Box>
              </Typography>
            )}

            {authStore.isAuthorized && !data.available && (
              <Box sx={InfoStyle}>
                <Typography variant='subtitle1'>{t('modal_steps.fee_per')}</Typography>
                <Box
                  sx={{
                    width: '100px',
                    borderTop: '1px solid rgba(41, 45, 63, 0.72)',
                    marginTop: 'inherit',
                  }}
                >
                  &nbsp;
                </Box>
                <Typography variant='subtitle1' sx={{ marginLeft: { xs: '15px', sm: '0' } }}>
                  {txFee ? txFee : <Loader size={10} small />}
                </Typography>
              </Box>
            )}
          </>
          <Stack direction='row' alignItems='center'>
            <Checkbox {...methods.register('agree')} onChange={checkboxChange} checked={!disabledButton} />{' '}
            <Typography variant='subtitle2'>
              {t('modal_steps.agree')} &nbsp;
              <NavLink to='/'>{t('modal_steps.terms')}</NavLink>
            </Typography>
          </Stack>

          <Stack direction='row' alignItems='center'>
            <Checkbox {...methods.register('agree2')} onChange={checkboxChange2} checked={!disabledButton2} />{' '}
            <Typography variant='subtitle2'>
              {t('modal_steps.agree')} &nbsp;
              <NavLink to='/'>{t('modal_steps.privacy')}</NavLink>
            </Typography>
          </Stack>

          <Box>
            <ModalInstance isOpen={isOpen} close={close}>
              <AccountsDropdown close={close} />
            </ModalInstance>
            {!authStore.isAuthorized && (
              <ButtonInstance
                style={{ width: '100%', marginTop: '18px' }}
                onClick={extensionSetup}
                title='Connect Polkadot.js'
              />
            )}

            {authStore.isAuthorized && !data.available && (
              <ButtonInstance
                type='submit'
                style={{ width: '100%', padding: '10px 16px', marginTop: '10px' }}
                disabled={isDisable}
                title={'send request'}
              />
            )}

            {authStore.isAuthorized && data.available && (
              <ButtonInstance
                type='submit'
                style={{ width: '100%', padding: '10px 16px', marginTop: '10px' }}
                disabled={isDisable}
                title={'continue'}
              />
            )}
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
}
