import OneSignal from 'react-onesignal';

export default async function runOneSignal() {
  await OneSignal.init({
    appId: 'fe580345-3e22-40f5-bdbc-0300e2a44bc5',
    allowLocalhostAsSecureOrigin: true,
  });
  OneSignal.Slidedown.promptPush().then(async () => {
    await OneSignal.User.PushSubscription.optIn();
  });
}

export const oneSignalRegister = async () => {
  await OneSignal.User.PushSubscription.optIn();
};

export const oneSignalLogin = async (walletAddress: string) => {
  await OneSignal.login(walletAddress);
};

export const oneSignalLogout = async () => {
  await OneSignal.logout();
};

export const getOneSignalPlayerID = async () => await OneSignal.User.PushSubscription.id;
