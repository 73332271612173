import { autorun } from 'mobx';
import React, { useEffect, useState } from 'react';
import { authStore } from 'store';

const useObserverAccessToken = () => {
  const [accessToken, setAccessToken] = useState(authStore.accessToken);

  useEffect(
    () =>
      autorun(() => {
        if (authStore.accessToken) {
          setAccessToken(authStore.accessToken);
        } else {
          setAccessToken('');
        }
      }),
    [],
  );

  return accessToken;
};

export default useObserverAccessToken;
