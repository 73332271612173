import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    status: StatusColor;
    statusActive: StatusColor;
    icons: iconsColor;
    menu: MenuColor;
    text: TypeText;
  }

  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    '2xl': true;
  }

  interface PaletteOptions {
    status: StatusColor;
    icons: iconsColor;
    menu: MenuColor;
    text?: Partial<TypeText>;
    type?: string;
  }

  interface TypeText {
    primary: string;
    secondary: string;
    disabled: string;
    hint: string;
    highlight: string;
  }
}

export interface StatusColor {
  assigned: string;
  in_progress: string;
  delivered: string;
  picked_up: string;
  canceled: string;
  active: string;
  deactivated: string;
}

export interface MenuColor {
  see_details: string;
  edit: string;
  cancel: string;
  deactivate: string;
  activate: string;
  delete: string;
}

export interface iconsColor {
  main: string;
}

const theme = createTheme({
  breakpoints: {
    values: { xs: 0, sm: 640, md: 768, lg: 1024, xl: 1280, '2xl': 1536 },
  },
  typography: {
    fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: 'Questrial',
      fontWeight: 700,
      lineHeight: '1.167',
      fontSize: '1.75rem',
      '@media (min-width:640px)': {
        fontSize: '3rem',
      },
    },
    h2: {
      fontWeight: 500,
      fontSize: '1.5rem',
      '@media (min-width:640px)': {
        fontSize: '1.5625rem',
      },
    },
    h3: {
      fontWeight: 500,
      fontSize: '1.125rem',
      '@media (min-width:640px)': {
        fontSize: '1.25rem',
      },
    },
    h4: {
      fontWeight: 350,
      fontSize: '.875rem',
      '@media (min-width:640px)': {
        fontSize: '1.125rem',
      },
    },
    h5: {
      fontWeight: 500,
      fontSize: '.875rem',
      '@media (min-width:640px)': {
        fontSize: '1rem',
        fontWeight: 300,
      },
    },
    h6: {
      fontFamily: 'Questrial',
      fontWeight: 400,
      fontSize: '.75rem',
      '@media (min-width:640px)': {
        fontSize: '.75rem',
      },
    },
    subtitle1: {
      fontFamily: 'Questrial',
      fontWeight: 400,
      fontSize: '0.875rem',
      '@media (min-width:640px)': {
        fontSize: '0.75rem',
      },
    },
    subtitle2: {
      fontFamily: 'Questrial',
      fontWeight: 400,
      fontSize: '1rem',
      '@media (min-width:640px)': {
        fontSize: '0.875rem',
      },
    },
    body1: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '.875rem',
      '@media (min-width:640px)': {
        fontSize: '1rem',
      },
    },
    body2: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '1rem',
      '@media (min-width:640px)': {
        fontSize: '1.125rem',
      },
    },
    button: {
      textTransform: 'capitalize',
      fontWeight: 500,
      fontSize: '.875rem',
      '@media (min-width:768px)': {
        fontSize: '1rem',
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        textPrimary: {
          ['&:hover']: {
            color: '#3555c8',
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          padding: '0 !important',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto',
          fontSize: '0.875rem',
          padding: '0',
        },
        selectLabel: ({ theme }) => ({
          fontFamily: 'Roboto',
          fontSize: '0.875rem',

          [theme.breakpoints.up('sm')]: {
            fontFamily: 'Roboto',
            fontSize: '0.875rem',
            fontWeight: 500,
          },
        }),
        displayedRows: ({ theme }) => ({
          fontFamily: 'Roboto',
          fontSize: '0.875rem',
          [theme.breakpoints.up('sm')]: {
            fontFamily: 'Roboto',
            fontSize: '0.875rem',
            fontWeight: 500,
          },
        }),
        select: ({ theme }) => ({
          fontSize: '0.875rem',
          fontFamily: 'Roboto',
          padding: '0px',
          marginRight: '0px',
          [theme.breakpoints.up('sm')]: {
            fontFamily: 'Roboto',
            fontSize: '0.875rem',
            fontWeight: 500,
          },
        }),
        actions: ({ theme }) => ({
          marginLeft: '0px',
          [theme.breakpoints.up('sm')]: {
            marginLeft: '20px',
          },
        }),
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionEnd: {
          width: 'fit-content !important',
          height: 'fit-content',
          float: 'right',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontFamily: 'Roboto',
          fontSize: '0.875rem',
          fontWeight: '400 !important',
          [theme.breakpoints.up('sm')]: {
            fontFamily: 'Roboto',
            fontSize: '0.875rem',
            fontWeight: '500 !important',
          },
        }),
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          subtitle1: 'div',
          subtitle2: 'div',
          body1: 'div',
          body2: 'div',
        },
      },
    },
    // MuiSelect: {
    //   styleOverrides: {
    //     standard: {
    //       margin: '0 16px !important',
    //       gap: '2px',
    //     },
    //   },
    // },
  },
});

export { theme };
