import React, { useCallback, useState } from 'react';
import FiltersLogo from '../../../img/filters-logo.png';
import { SelectChangeEvent } from '@mui/material/Select';
import { Box, Icon, MenuItem, Select, Stack, Typography } from '@mui/material';

export const useInsuranceSort = () => {
  const [orderBy, onOrderByChange] = useState<InsuranceOrderByKey>('asc');
  const [sortBy, onSortByChange] = useState<InsuranceSortByKey>();

  return {
    orderBy,
    sortBy,
    onOrderByChange,
    onSortByChange,
  };
};

type Props = ReturnType<typeof useInsuranceSort>;

const textStyles = { fontFamily: 'Questrial', fontWeight: 'bold', display: 'inline-block' };

const InsuranceSort: React.FC<Props> = ({ orderBy, onSortByChange, onOrderByChange, sortBy }) => {
  const sort = useCallback(
    (event: SelectChangeEvent<string>) => {
      const tmp = event.target.value as InsuranceSortByKey;
      if (!orderBy) {
        onOrderByChange('desc');
      }
      onSortByChange(tmp);
    },
    [orderBy],
  );

  const changeOrderBy = (event: SelectChangeEvent<string>) => {
    const tmp = event.target.value as InsuranceOrderByKey;
    onOrderByChange(tmp);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {sortBy && (
          <Select
            label='Sort by'
            value={orderBy}
            sx={{
              border: '2px solid #3555C8 !important',
              borderRadius: `12px`,
              backgroundColor: 'white',
              justifyContent: 'center',
              alignItems: 'center',
              width: 'auto',
              height: '40px',
              color: '#3555C8',
              marginRight: '10px',
              '.MuiOutlinedInput-notchedOutline': { border: 'none' },
              '&.Mui-focused': { outline: 'none' },
              '.MuiSvgIcon-root': { color: '#3555C8' },
              '.MuiSelect-select': { padding: '9px 14px' },
            }}
            onChange={changeOrderBy}
          >
            {orderByOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Typography variant='subtitle2' sx={textStyles}>
                  {option.label}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        )}

        <Select
          label='Sort by'
          defaultValue='Sort by'
          sx={{
            border: '2px solid #3555C8 !important',
            borderRadius: `12px`,
            backgroundColor: 'white',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'auto',
            height: '40px',
            color: '#3555C8',
            '.MuiOutlinedInput-notchedOutline': { border: 'none' },
            '&.Mui-focused': { outline: 'none' },
            '.MuiSvgIcon-root': { color: '#3555C8' },
            '.MuiSelect-select': { padding: '9px 14px' },
          }}
          onChange={sort}
          renderValue={(value) => (
            <Stack direction='row' alignItems='center' gap={1}>
              <Box component='img' alt='Filters' src={FiltersLogo} />
              <Typography variant='subtitle2' sx={{ ...textStyles, marginTop: '2px' }}>
                {value}
              </Typography>
            </Stack>
          )}
        >
          {sortByOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Typography variant='subtitle2' sx={textStyles}>
                {option.value}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
};

export default InsuranceSort;

const orderByOptions: { label: string; value: InsuranceOrderByKey }[] = [
  {
    label: 'Ascending',
    value: 'asc',
  },
  {
    label: 'Descending',
    value: 'desc',
  },
];

const sortByOptions = [
  {
    value: 'Start date',
  },
  {
    value: 'End date',
  },
  {
    value: 'Reward',
  },
  {
    value: 'Amount to Lock',
  },
  {
    value: 'Available',
  },
  {
    value: 'Total',
  },
  {
    value: 'NFT Price',
  },
] as const;

export type InsuranceOrderByKey = 'asc' | 'desc';

export type InsuranceSortByKey = (typeof sortByOptions)[number]['value'];
