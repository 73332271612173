import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

interface ILoader {
  children?: React.ReactNode;
  fullSize?: boolean;
  size?: number;
  small?: boolean;
}

export const Loader = ({ children, fullSize = false, size = 90, small = false }: ILoader) => {
  const boxStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: small ? undefined : '100%',
    minHeight: small ? undefined : '300px',
    boxSizing: 'border-box',
  } as React.CSSProperties;

  const height = fullSize ? '100vh' : 'fit-content';
  return (
    <Box sx={{ ...boxStyle, height }}>
      {children ? children : null}
      <CircularProgress size={size} thickness={4.5} />
    </Box>
  );
};
