export const countryOptions = [
  { value: '0', label: 'Florida, USA', long: 119.871, lat: 15.3886 },
  { value: '1', label: 'Philippines', long: 119.871, lat: 15.3886 },
  { value: '2', label: 'Japan', long: 119.871, lat: 15.3886 },
  { value: '3', label: 'Cuba', long: 119.871, lat: 15.3886 },
  { value: '4', label: 'Bahamas', long: 119.871, lat: 15.3886 },
  { value: '5', label: 'Madagascar', long: 119.871, lat: 15.3886 },
  { value: '6', label: 'Mexico', long: 119.871, lat: 15.3886 },
  { value: '7', label: 'India', long: 119.871, lat: 15.3886 },
  { value: '8', label: 'Ecuador', long: 119.871, lat: 15.3886 },
  { value: '9', label: 'Indonesia', long: 119.871, lat: 15.3886 },
  { value: '10', label: 'California, USA', long: 119.871, lat: 15.3886 },
];

export const getCountry = (label: string) => {
  const options = countryOptions.filter((countryData) => {
    return countryData.label === label;
  });

  return options[0] ? options[0] : countryOptions[1];
};
