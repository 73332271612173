import { Skeleton } from '@mui/material';

type SkeletonAlignParam = 'center' | 'left' | 'right';
type SkeletonAnimationParam = 'wave' | false;
type SkeletonVariantParam = 'circular' | 'rectangular' | 'rounded';

interface IProps {
  align?: SkeletonAlignParam;
  animation?: SkeletonAnimationParam;
  height?: number | string;
  width?: number | string;
  variant?: SkeletonVariantParam;
}

const TableRowSkeleton = ({
  align = 'left',
  animation = false,
  height = 40,
  width = '100%',
  variant = 'rounded',
}: IProps) => {
  return (
    <Skeleton
      className={`block ${!animation && 'shadow-xl'}`}
      style={
        align === 'center'
          ? { margin: '0 auto' }
          : {
              float: align === 'right' ? align : 'none',
              marginLeft: align === 'left' ? '-12px' : 0,
              marginRight: align === 'right' ? '-12px' : 0,
            }
      }
      animation={animation}
      height={height}
      width={width}
      variant={variant}
    ></Skeleton>
  );
};

export default TableRowSkeleton;
