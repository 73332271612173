import * as React from 'react';
import Box from '@mui/material/Box';
import { Table, TableBody, TableCell, TableContainer, TableRow, Checkbox, Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { initOracleEvent } from 'api';
import { showNotification } from 'utils';
import { errorHandle } from 'utils/errorHandle';
import { web3Enable, web3FromAddress } from '@polkadot/extension-dapp';
import { authStore, walletStore } from 'store';
import { stringToHex } from '@polkadot/util';
import { locations } from 'constants/index';
import { differenceInDays } from 'date-fns';

import { useDispatch, useSelector } from 'react-redux';

import { initialState, usePoliciesSlice } from 'features/policies/slice';
import GetInsurances from 'services/GetInsurances';
import { RootState } from 'store/RootState';
import ConfirmEvent from 'components/ConfirmEvent';
import CustomTablePagination from 'components/pagination/CustomTablePagination';
import { PdfLinkButton } from './PdfLinkButton';
import { EventStylesConfig, EventStylesConfigBuilder, StatusLabel } from './StatusLabel';
import { HeadCell, SortableTableHead } from 'components/table/SortableTableHead';
import useTableSort from 'hooks/useTableSort';
import TableRowSkeleton from './TableRowSkeleton';

type Insurance = Awaited<ReturnType<typeof GetInsurances>>[0] & {
  isChecked: boolean;
  trueId: {
    insuranceId: string;
    collectionId: string;
  };
};

const headCells: HeadCell<Insurance>[] = [
  {
    id: 'event',
    sortType: 'character',
    disablePadding: true,
    label: 'policies.tables.event status',
    align: 'center',
  },
  {
    id: 'name',
    sortType: 'character',
    disablePadding: true,
    label: 'policies.tables.type',
    align: 'left',
  },
  {
    id: 'location',
    sortType: 'character',
    disablePadding: true,
    label: 'policies.tables.location',
    align: 'left',
  },
  {
    id: 'mint',
    sortType: 'date',
    disablePadding: true,
    label: 'policies.tables.mint',
    align: 'right',
  },
  {
    id: 'expiration',
    sortType: 'date',
    disablePadding: true,
    label: 'policies.tables.expiration',
    align: 'right',
  },
  {
    id: 'comission',
    sortType: 'numeric',
    disablePadding: true,
    label: 'policies.tables.commission',
    align: 'right',
  },
  {
    id: 'underwrite',
    sortType: 'numeric',
    disablePadding: true,
    label: 'policies.tables.underwrite',
    align: 'right',
  },
  {
    id: 'until',
    sortType: 'numeric',
    disablePadding: true,
    label: 'policies.tables.until',
    align: 'right',
  },
];

export default function InsuranceTable() {
  const [insurancesData, setInsurancesData] = useState<Insurance[]>([]);
  const [fetchData, setFetchData] = useState(true);
  const { handleSubmit, reset } = useForm();

  useEffect(() => {
    async function fetchData() {
      setInsurancesData(
        (await GetInsurances()).map((item) => ({
          ...item,
          isChecked: false,
          trueId: {
            insuranceId: item.trueId.split(':').map(Number).reverse()[0],
            collectionId: item.trueId.split(':').map(Number).reverse()[1],
          },
        })),
      );
      setFetchData(false);
    }

    fetchData();
  }, []);

  const { getSortedData, onRequestSort, order, orderBy } = useTableSort({
    headCells,
    defaultOrder: 'desc',
    defaultOrderBy: 'mint',
  });

  // const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [selectAll, setSelectAll] = useState(false);

  const dispatch = useDispatch();
  const { actions } = usePoliciesSlice();

  const { insurances, loading } = useSelector((state: RootState) => state?.policies || initialState);

  const handleCheckboxChange = (itemId: number) => {
    setInsurancesData((prevData) =>
      prevData.map((item) => (item.id === itemId ? { ...item, isChecked: !item.isChecked } : item)),
    );
  };

  useEffect(() => {
    dispatch(actions.getInsurances(walletStore.walletAddress));
    setInsurancesData(
      insurances.map((item: any) => ({
        ...item,
        isChecked: false,
        trueId: {
          insuranceId: item.trueId?.split(':').map(Number).reverse()[0],
          collectionId: item.trueId?.split(':').map(Number).reverse()[1],
        },
      })),
    );
  }, []);

  const handleSelectAllChange = () => {
    const updatedData = insurancesData.map((item) => ({ ...item, isChecked: !selectAll }));
    setInsurancesData(updatedData);
    setSelectAll(!selectAll);
  };

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      //   const newSelected = data.map((n) => n.name);
      // setSelected(newSelected);
      return;
    }
    // setSelected([]);
  };

  const handleClick = (event: any, name: any) => {
    // const selectedIndex = selected.indexOf(name);
    // let newSelected = [];
    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, name);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    // }
    // setSelected(newSelected);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleTriggerEvent = () => {
    // SubmitOracleEvent();

    return true;
  };

  const SubmitOracleEvent = async (rowId?: number) => {
    if (rowId) {
      setFetchData(true);
      try {
        await web3Enable('decentro.parametricx.io');
        const injector = await web3FromAddress(walletStore.walletAddress);
        const signRaw = injector?.signer?.signRaw;
        if (signRaw) {
          await signRaw({
            address: walletStore.walletAddress,
            data: stringToHex('admin event request'),
            type: 'bytes',
          });

          const response = await initOracleEvent({
            location: '1', //TODO fix this
            event: insurancesData[0].name!,
            insurance: [insurancesData[rowId - 1].trueId!],
          });
          if (response) {
            showNotification({ title: 'Success', message: 'Event generated successfully' });
          }
        }
      } catch (err) {
        errorHandle(err);
        console.log('error', err);
      } finally {
        reset();
        setFetchData(false);
      }
    } else if (insurancesData.some((item) => item.isChecked === true)) {
      setFetchData(true);
      try {
        await web3Enable('decentro.parametricx.io');
        const injector = await web3FromAddress(walletStore.walletAddress);
        const signRaw = injector?.signer?.signRaw;
        if (signRaw) {
          await signRaw({
            address: walletStore.walletAddress,
            data: stringToHex('admin event request'),
            type: 'bytes',
          });

          const response = await initOracleEvent({
            location: '1', //TODO fix this
            event: insurancesData[0].name!,
            insurance: insurancesData.filter((item) => item.isChecked === true).map((item) => item.trueId),
          });
          if (response) {
            showNotification({ title: 'Success', message: 'Event generated successfully' });
          }
        }
      } catch (err) {
        errorHandle(err);
        console.log('error', err);
      } finally {
        reset();
        setFetchData(false);
      }
    } else {
      showNotification({ title: 'Error', message: 'Please fill all fields', type: 'warning' });
    }
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - insurancesData.length) : 0;

  const admin = authStore.isAdmin;

  const eventStylesConfig: EventStylesConfig = new EventStylesConfigBuilder()
    .withConfig('Approved')
    .withConfig('Active')
    .withConfig('Paid Out')
    .withConfig('Expired')
    .withConfig('Default')
    .build();

  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        sx={{
          width: '100%',
          mb: 2,
          maxWidth: {
            xs: '100vw',
            xl: admin ? '80vw' : '100vw',
          },
          overflowX: 'auto',
        }}
      >
        <TableContainer sx={{ py: 1 }}>
          <Table aria-labelledby='tableTitle' size={dense ? 'small' : 'medium'} sx={{ width: '100%' }}>
            <SortableTableHead
              withSelect={admin}
              headCells={headCells}
              onRequestSort={onRequestSort}
              onSelectAll={handleSelectAllChange}
              selectAll={selectAll}
              order={order}
              orderBy={orderBy}
              tableCellProps={{ sx: admin ? {} : { padding: '16px' } }}
            >
              {admin ? <TableCell></TableCell> : <></>}
            </SortableTableHead>
            <TableBody>
              {getSortedData(
                insurancesData.map((row) => ({
                  ...row,
                  until:
                    differenceInDays(new Date(row.expiration), new Date()) > 0
                      ? differenceInDays(new Date(row.expiration), new Date())
                      : 0,
                })),
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index: number) => {
                  // const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const forOracle = {
                    location: Object.keys(locations).find((key) => locations[key] === row.location),
                    event: row.name,
                    id: row.trueId,
                  };

                  const comissionParsed = parseFloat(
                    row.comission ? row.comission.toString().split(' ')?.[0] : '0',
                  ).toFixed(2);
                  const underwriteParsed = row.underwrite ? row.underwrite.toString().split(' ')?.[0] : 0;

                  return (
                    !loading && (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.name)}
                        role='checkbox'
                        aria-checked={row.isChecked}
                        tabIndex={-1}
                        key={row.id}
                        // selected={isItemSelected}
                      >
                        {admin && (
                          <TableCell sx={{ padding: '13px 0px 13px 12px' }}>
                            <Checkbox checked={row.isChecked} onChange={() => handleCheckboxChange(row.id)} />
                          </TableCell>
                        )}
                        <TableCell align='center' sx={{ padding: '8px 8px 8px 0' }}>
                          <StatusLabel event={row.event} eventStyles={eventStylesConfig[row.event]} />
                        </TableCell>
                        <TableCell align='left' sx={{ padding: '8px' }}>
                          <Box id={labelId}>
                            <Stack direction='row' alignItems='center'>
                              <Typography variant='body1'>{row.name}</Typography>&nbsp;
                              <PdfLinkButton row={row} />
                            </Stack>
                          </Box>
                        </TableCell>
                        <TableCell align='left' sx={{ padding: '8px' }}>
                          <Typography variant='body1'>{row.location}</Typography>
                        </TableCell>
                        <TableCell align='right' sx={{ padding: admin ? '8px 0 8px 8px' : '8px' }}>
                          <Typography variant='body1' sx={{ maxWidth: '110px', textAlign: 'right', float: 'right' }}>
                            {row.mint}
                          </Typography>
                        </TableCell>
                        <TableCell align='right' sx={{ padding: admin ? '8px 0 8px 8px' : '8px' }}>
                          <Typography
                            variant='body1'
                            sx={{ width: '100%', maxWidth: '110px', textAlign: 'right', float: 'right' }}
                          >
                            {row.expiration}
                          </Typography>
                        </TableCell>
                        <TableCell align='right' sx={{ padding: '8px' }}>
                          <Typography variant='body1'>{comissionParsed}</Typography>
                        </TableCell>
                        <TableCell align='right' sx={{ padding: '8px' }}>
                          <Typography variant='body1'>{underwriteParsed}</Typography>
                        </TableCell>
                        <TableCell align='right' sx={{ padding: '8px' }}>
                          <Typography variant='body1'>
                            {row.until === 1 ? `${row.until} Day` : `${row.until} Days`}
                          </Typography>
                        </TableCell>
                        {admin ? (
                          <TableCell
                            align='center'
                            sx={{
                              padding: '13px 16px 13px 10px',
                              color: '#939AAB',
                              textAlign: 'center',
                              position: 'relative',
                              lineHeight: '24px',
                              paddingLeft: '8px',
                              paddingRight: '8px',
                            }}
                          >
                            <ConfirmEvent rowId={row.id} triggerEvent={SubmitOracleEvent} />
                          </TableCell>
                        ) : (
                          <></>
                        )}
                      </TableRow>
                    )
                  );
                })}
              {loading &&
                Array.from({ length: rowsPerPage }, (_, index) => (
                  <TableRow key={index}>
                    {admin && (
                      <TableCell>
                        <TableRowSkeleton animation='wave' align='center' width='20px' />
                      </TableCell>
                    )}
                    <TableCell>
                      <TableRowSkeleton align='center' animation='wave' width='100px' />
                    </TableCell>
                    <TableCell>
                      <TableRowSkeleton animation='wave' width='150px' />
                    </TableCell>
                    <TableCell>
                      <TableRowSkeleton animation='wave' width='120px' />
                    </TableCell>
                    <TableCell>
                      <TableRowSkeleton animation='wave' align='right' width='100px' />
                    </TableCell>
                    <TableCell>
                      <TableRowSkeleton animation='wave' align='right' width='100px' />
                    </TableCell>
                    <TableCell>
                      <TableRowSkeleton animation='wave' align='right' width='50px' />
                    </TableCell>
                    <TableCell>
                      <TableRowSkeleton animation='wave' align='right' width='50px' />
                    </TableCell>
                    <TableCell>
                      <TableRowSkeleton animation='wave' align='right' width='70px' />
                    </TableCell>
                    {admin && (
                      <TableCell>
                        <TableRowSkeleton animation='wave' width='100px' />
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                    lineHeight: '24px',
                  }}
                >
                  <TableCell colSpan={admin ? 10 : 8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack direction='row' alignItems='center' sx={{ justifyContent: { xs: 'center', lg: 'flex-end' } }}>
          <CustomTablePagination
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            data={insurancesData}
            isAdmin={admin}
            triggerSelection={SubmitOracleEvent}
          />
        </Stack>
      </Paper>
    </Box>
  );
}
