import { ApiPromise, WsProvider } from '@polkadot/api';
import { appConfig } from 'config';
import { handleTransactionResult } from './handleTransactionResult';

export default async function CancelPutOnSaleOrder(orderId: number, setStatus: (status: string) => void) {
  const wsProvider = new WsProvider(appConfig.webSocket);
  const api = await ApiPromise.create({
    provider: wsProvider,
    types: {},
  });
  await api.isReady;

  const request = api.tx.marketplace.cancelOrder(orderId);

  const result = await handleTransactionResult(request, api, 'cancel sale order', setStatus);

  return result;
}
