import { ApiPromise, WsProvider } from '@polkadot/api';
import { web3Enable, web3FromAddress } from '@polkadot/extension-dapp';
import { appConfig, pricingConfig } from 'config';
import { walletStore } from 'store';
// import { Keyring } from "@polkadot/keyring";
import { getCountry } from './CountryOptions';
import { handleTransactionResult } from './handleTransactionResult';
import dayjs from 'dayjs';
import type { FormData } from '../components/ModalForm';

function getBlockByUnixTime(
  currentBlockNumber: number,
  targetBlockTime: number,
  blockTime: number,
  currentBlockTime: number,
) {
  return currentBlockNumber + Math.floor((targetBlockTime - currentBlockTime) / blockTime);
}

export default async function BuyPRMXBuyers(
  PRMXData: FormData,
  setStatus: React.Dispatch<React.SetStateAction<NonNullable<string>>>,
) {
  const wsProvider = new WsProvider(appConfig.webSocket);
  const api = await ApiPromise.create({
    provider: wsProvider,
    types: {},
  });
  await api.isReady;

  const currentBlockNumber = (await api.rpc.chain.getHeader()).number.toNumber();
  const blockTime = Number(await api.call.auraApi.slotDuration());
  const currentBlockTime = Number(
    (await api.query.timestamp.now.at(await api.rpc.chain.getFinalizedHead())).toBigInt().toString(),
  );

  const startsOn = getBlockByUnixTime(currentBlockNumber, PRMXData.start_date as number, blockTime, currentBlockTime);

  const endsOn = getBlockByUnixTime(currentBlockNumber, PRMXData.end_date as number, blockTime, currentBlockTime);

  // For testing:
  // if (startsOn === endsOn) {
  //   console.log('An insurance will be created with a lifetime of 2 hours.');
  //   startsOn = currentBlockNumber + 100;
  //   endsOn = startsOn + 1200;
  // }
  const { value: locationValue, long, lat } = getCountry(PRMXData.location);

  const metadata = {
    name: PRMXData.event,
    location: locationValue,
    creator: walletStore.walletAddress,
    status: 'Active',
    underwriteAmount: PRMXData.underwrite_amount * Math.pow(10, appConfig.USDT_DECIMAL_SCALE),
    premiumAmount: Math.round(PRMXData.premium_amount * Math.pow(10, appConfig.USDT_DECIMAL_SCALE)),
    contractLink: 'blank.pdf',
    startsOn: startsOn,
    endsOn: endsOn,
  };

  const startDate = dayjs(PRMXData.start_date);
  const endDate = dayjs(PRMXData.end_date);

  const hoursBetween = endDate.diff(startDate, 'hour');

  const params = {
    longtitude: Math.pow(10, 12) * long,
    latitude: Math.pow(10, 12) * lat,
    startDate: Math.floor(startDate.valueOf() / 1000),
    // End date - Start date in hour
    durationInHours: Math.max(hoursBetween, 1),
    // Just for testing we should calculate it on PROD
    threshold: pricingConfig.threshold,
    coverage: PRMXData.underwrite_amount,
    numberOfSimulations: pricingConfig.numberOfSimulations,
    roc: Math.pow(10, 12) * pricingConfig.ROC,
  };

  // Create a proposal (i.e. callback) which will be executed if DAO approves insurance request.
  // Request insurance flow:
  const proposal = api.tx.dao.allocateLiquidity(metadata);

  const request = api.tx.dao.prepareRequestInsurance(params, metadata, proposal);

  const result = await handleTransactionResult(request, api, 'request insurance', setStatus);

  return result;
}

export const calculateTransactionFee = async (data: FormData) => {
  const wsProvider = new WsProvider(appConfig.webSocket);
  const api = await ApiPromise.create({
    provider: wsProvider,
    types: {},
  });
  await api.isReady;

  const currentBlockNumber = (await api.rpc.chain.getHeader()).number.toNumber();
  const blockTime = Number(await api.call.auraApi.slotDuration());
  const currentBlockTime = Number(
    (await api.query.timestamp.now.at(await api.rpc.chain.getFinalizedHead())).toBigInt().toString(),
  );

  const startsOn = getBlockByUnixTime(
    currentBlockNumber,
    dayjs(data.start_date).unix() * 1000,
    blockTime,
    currentBlockTime,
  );

  const endsOn = getBlockByUnixTime(
    currentBlockNumber,
    Number(dayjs(data.end_date).unix() * 1000),
    blockTime,
    currentBlockTime,
  );
  const { value: locationValue, long, lat } = getCountry(data.location);

  //TODO fix this hardcoded data
  const metadata = {
    name: 'Earthquake',
    location: locationValue,
    creator: walletStore.walletAddress,
    status: 'Active',
    underwriteAmount: Number(data.underwrite_amount) * Math.pow(10, appConfig.USDT_DECIMAL_SCALE),
    premiumAmount: Math.round(data.premium_amount * Math.pow(10, appConfig.USDT_DECIMAL_SCALE)),
    contractLink: 'blank.pdf',
    startsOn: startsOn,
    endsOn: endsOn,
  };

  const startDate = dayjs(data.start_date);
  const endDate = dayjs(data.end_date);

  const hoursBetween = endDate.diff(startDate, 'hour');

  const params = {
    longtitude: Math.pow(10, 12) * long,
    latitude: Math.pow(10, 12) * lat,
    startDate: Math.floor(startDate.valueOf() / 1000),
    // End date - Start date in hour
    durationInHours: Math.max(hoursBetween, 1),
    // Just for testing we should calculate it on PROD
    threshold: pricingConfig.threshold,
    coverage: Number(data.underwrite_amount),
    numberOfSimulations: pricingConfig.numberOfSimulations,
    roc: Math.pow(10, 12) * pricingConfig.ROC,
  };

  // Create a proposal (i.e. callback) which will be executed if DAO approves insurance request.
  // Request insurance flow:
  const proposal = api.tx.dao.allocateLiquidity(metadata);

  const request = api.tx.dao.prepareRequestInsurance(params, metadata, proposal);

  const info = await request.paymentInfo(walletStore.walletAddress);
  const txFee = info.partialFee.toHuman();

  return txFee;
};
