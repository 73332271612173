import { showNotification } from './showNotification';

export const errorHandle = (error: unknown) => {
  let message = 'Unknown Error';
  if (error instanceof Error) {
    message = error.message;
  } else if (typeof error === 'string') {
    message = error;
  }

  showNotification({ title: 'Error', message, type: 'warning' });
};
