import { ApiPromise, WsProvider } from '@polkadot/api';
import { appConfig } from 'config';
import { handleTransactionResult } from './handleTransactionResult';
import { walletStore } from 'store';

export default async function PutOnSale(
  tokenId: number,
  tokenAmount: number,
  pricePerToken: number,
  setStatus: React.Dispatch<React.SetStateAction<NonNullable<string>>>,
) {
  const wsProvider = new WsProvider(appConfig.webSocket);
  const api = await ApiPromise.create({
    provider: wsProvider,
    types: {},
  });
  await api.isReady;

  const request = api.tx.marketplace.createOrder(
    tokenId,
    tokenAmount,
    pricePerToken * Math.pow(10, appConfig.USDT_DECIMAL_SCALE),
    'Sell',
  );

  const result = await handleTransactionResult(request, api, 'put on sale', setStatus);

  return result;
}

export const calculateTransactionFeeForSale = async (tokenId: number, tokenAmount: number, pricePerToken: number) => {
  const wsProvider = new WsProvider(appConfig.webSocket);
  const api = await ApiPromise.create({
    provider: wsProvider,
    types: {},
  });
  await api.isReady;

  const request = api.tx.marketplace.createOrder(
    tokenId,
    tokenAmount,
    pricePerToken * Math.pow(10, appConfig.USDT_DECIMAL_SCALE),
    'Sell',
  );

  const info = await request.paymentInfo(walletStore.walletAddress);
  const txFee = info.partialFee.toHuman();

  return txFee;
};
