import { INSURANCE_PAGE_SIZE, useGetPRMXBuyers } from 'api/query/insurances';
import InsuranceTabCards from 'components/InsuranceTabCards';
import React from 'react';
import { useStyles } from './styles';
import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InsuranceSkeleton from './InsuranceSkeleton';

const PRMXBuyer = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { data, refetch, hasNextPage, isFetching, fetchNextPage } = useGetPRMXBuyers({
    variables: { page: 1, pageSize: INSURANCE_PAGE_SIZE },
    refetchOnMount: true,
  });

  return (
    <>
      {isFetching ? (
        <InsuranceSkeleton />
      ) : (
        <InsuranceTabCards data={data?.pages?.map((x) => x.results).flat() ?? []} filters={refetch} />
      )}

      {hasNextPage ? (
        <Stack direction='row' justifyContent='center' sx={{ width: '100%', mt: 4 }}>
          <Button
            onClick={() => {
              fetchNextPage();
            }}
            className={classes.insuranceButtonStyle}
            color='inherit'
            //   TODO: Update show more function
            //   onClick={showMore}
          >
            {t('buttons.show_more')}
          </Button>
        </Stack>
      ) : null}
    </>
  );
};

export default PRMXBuyer;
