import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { authStore } from 'store';

interface IHookOption {
  isRedirect?: boolean;
  redirectRoute?: string;
}

export const useAdminRequire = ({ isRedirect = true, redirectRoute = '/' }: IHookOption = {}): {
  isLoading: boolean;
} => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const isAdmin = authStore.isAdmin;

  useEffect(() => {
    if (!isAdmin && isRedirect) {
      return navigate(redirectRoute);
    }
    setIsLoading(false);
  }, [isAdmin, redirectRoute, navigate, isRedirect]);

  return { isLoading };
};
