import { Button, Stack, Typography } from '@mui/material';
import React, { Component, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Stack direction='column' justifyContent='center' alignItems='center' minHeight={'100vh'} width={'100%'}>
          <Stack spacing={4} direction='column' justifyContent='center' alignItems='center' textAlign='center'>
            <Typography variant='h2'>Sorry, We&apos;re getting Error 500</Typography>
            <Typography variant='h6' fontWeight={400}>
              Something went wrong and we&apos;re already working on it.
            </Typography>
            <Button
              size='large'
              sx={{ minHeight: '50px' }}
              onClick={() => window.location.reload()}
              variant='contained'
              color='error'
            >
              Reload Page
            </Button>
          </Stack>
        </Stack>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
