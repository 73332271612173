import { ApiPromise, WsProvider } from '@polkadot/api';
import { appConfig } from 'config';

export default async function GetBalance(wallet: string) {
  const wsProvider = new WsProvider(appConfig.webSocket);
  const api = await ApiPromise.create({
    provider: wsProvider,
    types: {},
  });
  await api.isReady;

  const { nonce: previousNonce } = await api.query.system.account(wallet);
  const response = await api.query.assets.account(appConfig.USDT, wallet);

  const balance = response.value.get('balance')?.toString() ?? '0';

  await api.disconnect();

  console.log(`Wallet: ${wallet}, balance: ${balance.toString()}.`);
  return { balance: balance.toString(), nonce: previousNonce.toString() };
}
