import { Box, Container, Typography } from '@mui/material';
import Faq from 'react-faq-component';
import { useTranslation } from 'react-i18next';

const faqData = {
  rows: [
    {
      title: 'faq.rows.title_1',
      content: 'faq.rows.content_1',
    },
    {
      title: 'faq.rows.title_2',
      content: 'faq.rows.content_2',
    },
    {
      title: 'faq.rows.title_3',
      content: 'faq.rows.content_3',
    },
    {
      title: 'faq.rows.title_4',
      content: 'faq.rows.content_4',
    },
    {
      title: 'faq.rows.title_5',
      content: 'faq.rows.content_5',
    },
    {
      title: 'faq.rows.title_6',
      content: 'faq.rows.content_6',
    },
    {
      title: 'faq.rows.title_7',
      content: 'faq.rows.content_7',
    },
    {
      title: 'faq.rows.title_8',
      content: 'faq.rows.content_8',
    },
    {
      title: 'faq.rows.title_9',
      content: 'faq.rows.content_9',
    },
    {
      title: 'faq.rows.title_10',
      content: 'faq.rows.content_10',
    },
    {
      title: 'faq.rows.title_11',
      content: 'faq.rows.content_11',
    },
    {
      title: 'faq.rows.title_12',
      content: 'faq.rows.content_12',
    },
    {
      title: 'faq.rows.title_13',
      content: 'faq.rows.content_13',
    },
    {
      title: 'faq.rows.title_14',
      content: 'faq.rows.content_14',
    },
    {
      title: 'faq.rows.title_15',
      content: 'faq.rows.content_15',
    },
  ],
};

const faqBoxStyle = {
  padding: '100px 0px 0px 0px',
};

const faqTitleStyle = {
  fontWeight: 600,
  textAlign: 'center',
  color: '#404449',
  marginBottom: '25px',
};

const faqTextStyle = {
  textAlign: 'center',
  color: 'rgba(41, 45, 63, 0.72)',
  paddingBottom: '45px',
};

export default function FAQ() {
  const { t } = useTranslation('faq');
  const rows = faqData.rows.map(({ title, content }) => ({
    title: (
      <Typography
        variant='h3'
        lineHeight={1.5}
        fontSize={{ xs: '16px', lg: '20px' }}
        fontWeight={{
          xs: 500,
          lg: 700,
        }}
        fontFamily='Poppins'
      >
        {t(`${title}`)}
      </Typography>
    ),
    content: t(`${content}`),
  }));
  return (
    <Container maxWidth='xl'>
      <Box sx={faqBoxStyle}>
        <Typography variant='h2' sx={faqTitleStyle}>
          {t('faq.frequently')}
        </Typography>
        <Typography variant='body2' fontFamily='Poppins' sx={faqTextStyle}>
          {t('faq.a_collection')}
        </Typography>
        <Faq data={{ ...faqData, rows }} styles={{ rowTitleColor: '#292D3F', rowContentPaddingLeft: '0px' }} />
      </Box>
    </Container>
  );
}
