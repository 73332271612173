import axios, { AxiosPromise } from 'axios';
import { appConfig } from '../config';
import {
  IRefreshAccessTokenRequestBody,
  IRefreshAccessTokenResponseBody,
  ISignInRequestBody,
  ISignInResponseBody,
} from '../types';

import { API } from './API';
import { authStore } from 'store';

export function signIn(body: ISignInRequestBody): Promise<ISignInResponseBody> {
  return API.post<ISignInRequestBody, ISignInResponseBody>(`${appConfig.apiUrl}/auth/getTokenPair`, body, {
    isPublic: true,
  }).then((response) => response.data);
}

export function refreshAccessTokenReq(body: IRefreshAccessTokenRequestBody): Promise<IRefreshAccessTokenResponseBody> {
  return API.post<IRefreshAccessTokenRequestBody, IRefreshAccessTokenResponseBody>(
    `${appConfig.apiUrl}/auth/refreshTokenPair`,
    body,
    {
      isPublic: true,
    },
  ).then((response) => response.data);
}

export const signInReq = (body: ISignInRequestBody): AxiosPromise<any> => {
  return axios.post(`${appConfig.apiUrl}/auth/getTokenPair`, body, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${authStore.accessToken}`,
    },
  });
};
