import React, { useEffect, useState } from 'react';
import { styled, Typography, TypographyProps } from '@mui/material';
import { ButtonInstance, Loader } from 'components';
import { useForm } from 'react-hook-form';
import { events, EventsValues, locations, LocationsKeys } from 'constants/index';
import { getWallets, initOracleEvent } from 'api';
import { showNotification } from 'utils';
import { errorHandle } from 'utils/errorHandle';
import { OracleEventSelect } from './OracleEventSelect';
import { web3Enable, web3FromAddress } from '@polkadot/extension-dapp';
import { authStore, walletStore } from 'store';
import { stringToHex } from '@polkadot/util';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { appConfig } from 'config';
import axios from 'axios';
import { useFetch } from 'hooks';
import { IAdminResponseWallets, IAdminWallets } from 'types';

export interface IOracleFormValues {
  location: LocationsKeys;
  event: EventsValues;
  id?: number[];
  setIsFetching: (a: boolean) => void;
}

const SubmitOracleEvent = ({ location, event, id, setIsFetching }: IOracleFormValues) => {
  const { handleSubmit, reset } = useForm<IOracleFormValues>();

  handleSubmit(async ({ location, event }) => {
    if (location && event) {
      setIsFetching(true);
      try {
        await web3Enable('decentro.parametricx.io');
        const injector = await web3FromAddress(walletStore.walletAddress);
        const signRaw = injector?.signer?.signRaw;
        if (signRaw) {
          await signRaw({
            address: walletStore.walletAddress,
            data: stringToHex('admin event request'),
            type: 'bytes',
          });
          const response = await initOracleEvent({
            location,
            event,
            insurance: undefined,
          });
          if (response) {
            showNotification({ title: 'Success', message: 'Event generated successfully' });
          }
        }
      } catch (err) {
        errorHandle(err);
      } finally {
        reset();
        setIsFetching(false);
      }
    } else {
      showNotification({ title: 'Error', message: 'Please fill all fields', type: 'warning' });
    }
  });
};

export const OracleInitEventView = () => {
  const { handleSubmit, register, reset } = useForm<IOracleFormValues>();
  const [isFetching, setIsFetching] = useState(false);

  const initialWallets: IAdminResponseWallets = {
    results: [],
    total: 0,
  };

  const walletAddresses: string[] = [];

  const { loading, response } = useFetch(getWallets, initialWallets);

  const onSubmit = handleSubmit(async ({ location, event }) => {
    console.log('location', location, 'event', event);
    if (location && event) {
      setIsFetching(true);
      try {
        await web3Enable('decentro.parametricx.io');
        const injector = await web3FromAddress(walletStore.walletAddress);
        const signRaw = injector?.signer?.signRaw;
        if (signRaw) {
          await signRaw({
            address: walletStore.walletAddress,
            data: stringToHex('admin event request'),
            type: 'bytes',
          });
          const response = await initOracleEvent({
            location,
            event,
            insurance: undefined,
          });
          if (response) {
            showNotification({ title: 'Success', message: 'Event generated successfully' });
          }
        }
      } catch (err) {
        errorHandle(err);
      } finally {
        reset();
        setIsFetching(false);
      }
    } else {
      showNotification({ title: 'Error', message: 'Please fill all fields', type: 'warning' });
    }
  });

  const [value, setValue] = React.useState<string | null>(walletAddresses[0]);
  const [inputValue, setInputValue] = React.useState('');

  // console.log('response wallets', response);

  useEffect(() => {
    function insertData() {
      if (response.total > 0) {
        initialWallets.total = response.total;

        for (const row of response.results) {
          initialWallets.results.push(row);
          if (row.role != 'DAO' && !walletAddresses.includes(row.walletAddress))
            walletAddresses.push(row.walletAddress);
        }
      }

      console.log('wallets', walletAddresses);
    }

    insertData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    console.log('value', value);
  }, [value]);

  return (
    <form style={formStyle} onSubmit={onSubmit}>
      <StyledTypography>Event</StyledTypography>
      {(loading && response) || isFetching ? (
        <Loader />
      ) : (
        <>
          <OracleEventSelect register={register('location')} menuItems={locations} />
          <OracleEventSelect register={register('event')} menuItems={events} />
          <Autocomplete
            value={value}
            onChange={(event: any, newValue: string | null) => {
              setValue(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            id='controllable-states-demo'
            selectOnFocus
            handleHomeEndKeys
            options={walletAddresses}
            sx={{ width: '100%' }}
            renderInput={(params) => <TextField {...params} label='user' />}
          />
          <ButtonInstance title='submit' type='submit' />
        </>
      )}
    </form>
  );
};

const formStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
} as React.CSSProperties;

const StyledTypography = styled(Typography)<TypographyProps>({
  margin: '64px 0 24px',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '34px',
  lineHeight: '48px',
});
