import { Store } from 'react-notifications-component';
import { IShowNotify } from 'types';

export const showNotification = ({ title, message, type = 'info', duration = 4000 }: IShowNotify) => {
  Store.addNotification({
    title,
    message,
    type,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration,
    },
  });
};

export const showTransactionStatus = (status: string, name: string) => {
  let message;
  switch (status) {
    case 'Ready':
      message = `Transaction "${name}" ready.`;
      break;
    case 'InBlock':
      message = `Transaction "${name}" included at block.`;
      break;
    case 'Finalized':
      message = `Your "${name}" request has been successfully completed.`;
      break;
    default:
      message = status;
  }
  showNotification({
    title: 'Current status:',
    message: message,
    type: 'info',
  });
};
