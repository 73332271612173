import { HeadCell } from 'components/table/SortableTableHead';
import dayjs from 'dayjs';
import { SortOrderTypes } from 'features/policies/slice/types';
import React, { useCallback, useState } from 'react';

export type TableSortType = 'character' | 'date' | 'numeric';

export const SortOrder = {
  ASC: 'asc',
  DESC: 'desc',
} as const;

export type SortOrderType = (typeof SortOrder)[keyof typeof SortOrderTypes];

interface UseTableSortParam<TOrderBy extends string = any> {
  defaultOrderBy: TOrderBy;
  defaultOrder: SortOrderType;
  headCells: HeadCell[];
}

// TODO: Type safe for defaultOrderBy pick from headCells id column
export const useTableSort = <TOrderBy extends string = any>({
  defaultOrderBy,
  defaultOrder,
  headCells,
}: UseTableSortParam<TOrderBy>) => {
  const [{ order, orderBy }, setSortState] = useState({
    order: defaultOrder,
    orderBy: defaultOrderBy,
  });

  const onRequestSort = (event: React.MouseEvent<unknown>, property: TOrderBy) => {
    const isAsc = orderBy === property && order === 'asc';
    setSortState({
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    });
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  function descendingComparator(a: Record<TOrderBy & {}, any>, b: Record<TOrderBy & {}, any>, sortType: TableSortType) {
    switch (sortType) {
      case 'character':
        return String(a[orderBy]).localeCompare(b[orderBy]);
      case 'date':
        if (dayjs(b[orderBy]).unix() < dayjs(a[orderBy]).unix()) {
          return -1;
        } else {
          return 1;
        }
      case 'numeric':
        if (parseFloat(b[orderBy]) < parseFloat(a[orderBy])) {
          return -1;
        } else {
          return 1;
        }
      default:
        if (b[orderBy] < a[orderBy]) {
          return -1;
        } else {
          return 1;
        }
    }
  }

  const getSortedData = useCallback(
    <T extends any[] = any[]>(data: T) => {
      if (!orderBy || !order) return data;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const sortColumn = headCells.find((cell) => cell.id === orderBy);
      const sortType = sortColumn?.sortType ?? 'character';
      if (sortColumn?.onSort) {
        return data.sort(sortColumn.onSort);
      }
      return data.sort((a, b) => {
        return order === 'desc' ? descendingComparator(a, b, sortType) : -descendingComparator(a, b, sortType);
      });
    },
    [order, orderBy, headCells],
  );

  return {
    order,
    orderBy,
    onRequestSort,
    getSortedData,
  };
};

export default useTableSort;
