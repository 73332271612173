import { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Skeleton, Stack, styled, Typography } from '@mui/material';
import { DaoPagination } from 'components';
import { historyColumns, REFRESH_TABLE_TIMEOUT, ROWS_PER_PAGE_OPTIONS } from 'constants/index';
import { HistoryTableBody } from './HistoryTableBody';
import { useTranslation } from 'react-i18next';
import { useGetDAOHistory } from 'api/query/DAO';
import useTableSort from 'hooks/useTableSort';
import { SortableTableHead } from 'components/table/SortableTableHead';

export const MyDAOHistoryTableView = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_OPTIONS[0]);

  const { onRequestSort, order, orderBy } = useTableSort({
    headCells: historyColumns,
    defaultOrder: 'desc',
    defaultOrderBy: 'startTime',
  });

  const { data: votingHistory, isFetching } = useGetDAOHistory({
    variables: { page: page + 1, pageSize: rowsPerPage, order, orderBy },
    refetchInterval: REFRESH_TABLE_TIMEOUT,
  });

  return (
    <Paper sx={{ width: '100%' }}>
      <TableContainer sx={{ py: 1 }}>
        <Table aria-label='table'>
          <SortableTableHead headCells={historyColumns} onRequestSort={onRequestSort} order={order} orderBy={orderBy} />
          {isFetching ? (
            <>
              {Array.from({ length: rowsPerPage }).map((_, index) => (
                <TableRow key={index}>
                  <TableCell colSpan={historyColumns.length}>
                    <Skeleton animation='wave' width='full' height='43px'></Skeleton>
                  </TableCell>
                </TableRow>
              ))}
            </>
          ) : (
            <HistoryTableBody
              results={votingHistory && votingHistory.results.length > 0 ? votingHistory.results : []}
            />
          )}
        </Table>
      </TableContainer>
      <Stack direction='row' alignItems='center' sx={{ justifyContent: { xs: 'center', lg: 'flex-end' } }}>
        <DaoPagination
          count={votingHistory ? votingHistory.total : 0}
          setPage={setPage}
          page={page}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Stack>
    </Paper>
  );
};

const StyledTableCellHeader = styled(TableCell)<TableCellProps>({
  padding: '16px 16px 16px 12px',
  color: 'rgba(41, 45, 63, 0.48)',
  textTransform: 'capitalize',
  boxSizing: 'border-box',
});
