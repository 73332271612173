export enum ErrorKeys {
  SignInError = 'SignInError',
  ConnectError = 'ConnectError',
}

export enum ErrorReasons {
  WalletRequestRejected = 'WalletRequestRejected',
  WalletNotConnected = 'WalletNotConnected',
  SignInFailed = 'SignInFailed',
  LinkAlreadyUsed = 'LinkAlreadyUsed',
  Unknown = 'Unknown',
}
