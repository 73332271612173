import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { REQUEST_TIMEOUT } from 'constants/index';
import { authStore } from 'store';

interface ConfigModel extends AxiosRequestConfig {
  isPublic?: boolean;
}

const instance = axios.create({
  timeout: REQUEST_TIMEOUT,
});

let refreshTokensPromise: null | Promise<void> = null;

// Set access token to Authorization header for each secured request
instance.interceptors.request.use(async (config: ConfigModel) => {
  if (!config.isPublic) {
    // If Token is refreshing, wait for new access token
    if (refreshTokensPromise) {
      await refreshTokensPromise;
    }

    if (authStore.accessToken) {
      config.headers || (config.headers = {});
      config.headers.Authorization = `Bearer ${authStore.accessToken}`;
    }
  }
  return config;
});

// Refresh access token if it expired
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { status } = error.response || {};

    if (status === 401) {
      const refreshToken = authStore.refreshToken;
      const shouldRefreshTokens = !error.config.isPublic && !refreshTokensPromise && !!refreshToken;

      if (shouldRefreshTokens) {
        try {
          refreshTokensPromise = authStore.refreshAccessToken();
          await refreshTokensPromise;
          refreshTokensPromise = null;

          return axios.request({
            ...error.config,
            headers: {
              ...error.config.headers,
              Authorization: `Bearer ${authStore.accessToken}`,
            },
          });
        } catch (error) {
          authStore.signOut();
          throw error;
        }
      }
      authStore.signOut();
    }

    return Promise.reject(error);
  },
);

// ** API instance for all requests **
export const API = {
  get<ResBody>(path: string, config?: ConfigModel): Promise<AxiosResponse<ResBody>> {
    return instance.get(path, config);
  },

  post<ReqBody, ResBody>(path: string, data?: ReqBody, config?: ConfigModel): Promise<AxiosResponse<ResBody>> {
    return instance.post(path, data, config);
  },

  put<ReqBody, ResBody>(path: string, data?: ReqBody, config?: ConfigModel): Promise<AxiosResponse<ResBody>> {
    return instance.put(path, data, config);
  },

  patch<ReqBody, ResBody>(path: string, data?: ReqBody, config?: ConfigModel): Promise<AxiosResponse<ResBody>> {
    return instance.patch(path, data, config);
  },

  delete<ResBody>(path: string, config?: ConfigModel): Promise<AxiosResponse<ResBody>> {
    return instance.delete(path, config);
  },
};
