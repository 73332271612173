import { PayloadAction } from '@reduxjs/toolkit';
import { getInsurancesReq } from 'api/policies';
import { call, put, takeLatest } from 'redux-saga/effects';
import { policiesActions as actions } from './index';

export function* getInsurances(action: PayloadAction<string>) {
  try {
    const { data } = yield call(getInsurancesReq, action.payload);
    yield put(actions.receivedInsurances(data));
  } catch (err: any) {
    if (err?.response?.data?.error) {
      yield put(actions.policiesError(err.response.data.error));
    }
  }
}

export function* policiesSaga() {
  yield takeLatest(actions.getInsurances.type, getInsurances);
}
