import styled from '@emotion/styled';
import Footer from 'components/Footer';
import Header from 'components/Header';
import { Outlet, useLocation } from 'react-router-dom';

export const MainLayoutView = () => {
  const location = useLocation();
  return (
    <StyledContainer>
      <Header />
      <StyledMain>
        <Outlet />
      </StyledMain>
      {!location.pathname.startsWith('/admin') && <Footer />}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  margin: auto;
  background: transparent;
`;

const StyledMain = styled.main`
  flex: 1 1 auto;
  width: 100%;
`;
