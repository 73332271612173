import { SubmitHandler, useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import LoadingSpinner from './LoadingSpinner';
import dayjs from 'dayjs';
import PutOnSale from 'services/PutOnSale';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Typography } from '@mui/material';
import { IDataType } from './PutOnSaleModalForm';

const FormStyle = {
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '24px',
  color: 'rgba(41, 45, 63, 0.72)',
  fontFamily: 'Questrial',
};

export interface FormDataStep2 {
  event: string;
  location: string;
  start_date: number;
  end_date: number;
  amount: number;
  price: number;
  smtId: number;
}

interface Props {
  setStep: (step: number) => void;
  formValues: FormDataStep2;
  setFormValues: React.Dispatch<React.SetStateAction<NonNullable<FormDataStep2>>>;
  closeModal: () => void;
  data: IDataType;
  postData: boolean;
  setPostData: React.Dispatch<React.SetStateAction<NonNullable<boolean>>>;
}

export default function PutOnSaleStep2({
  setStep,
  formValues,
  setFormValues,
  closeModal,
  data,
  postData,
  setPostData,
}: Props) {
  const ButtonStyle = {
    fontFamily: 'Questrial',
    border: '2px solid #3555C8',
    borderRadius: `12px`,
    backgroundColor: '#3555C8',
    justifyContent: 'center',
    padding: '8px 12px',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '24px',
    color: 'white',
    textTansform: 'none !important',
    marginLeft: '0px',
    width: '100%',
    height: '48px',
    marginTop: '18px',
  };

  const ButtonWhiteStyle = {
    fontFamily: 'Questrial',
    border: '2px solid #3555C8',
    borderRadius: `12px`,
    backgroundColor: 'white',
    justifyContent: 'center',
    padding: '8px 12px',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#3555C8',
    textTansform: 'none !important',
    marginLeft: '0px',
    width: '100%',
    height: '48px',
    marginTop: '18px',
  };

  // eslint-disable-next-line no-unused-vars
  const { register, handleSubmit } = useForm<FormDataStep2>();
  const [status, setStatus] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (status === 'Finalized') {
      setPostData(false);
      setStep(3);
    }

    if (status === 'error') {
      setPostData(false);
      closeModal();
    }
  }, [status]);

  const onSubmit: SubmitHandler<FormDataStep2> = async (values) => {
    setPostData(true);
    setFormValues({
      ...formValues,
      ...values,
    });

    await PutOnSale(formValues.smtId, formValues.amount, formValues.price, setStatus);
  };

  const BackOpen = () => {
    setFormValues({
      ...formValues,
    });

    setStep(1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={FormStyle}>
      <Box
        sx={{
          color: '#292D3F',
          fontWeight: 'bold',
          marginBottom: '20px',
        }}
      >
        <Typography variant='h2'>{t('put_on_sale.put_on_sale')}</Typography>
      </Box>

      <Box
        sx={{
          position: 'relative',
          color: 'rgba(41, 45, 63, 0.72)',
          width: '100%',
        }}
      >
        <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ width: '100%' }}>
          <Typography variant='subtitle1'>{t('put_on_sale.type')}</Typography>
          <Box
            sx={{
              display: 'inline-block',
              float: 'right',
              paddingRight: '10px',
              color: 'black',
            }}
          >
            <Typography variant='subtitle1'>{data.event}</Typography>
          </Box>
        </Stack>
        <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ width: '100%' }}>
          <Typography variant='subtitle1'>{t('put_on_sale.location')}</Typography>
          <Box
            sx={{
              display: 'inline-block',
              float: 'right',
              paddingRight: '10px',
              color: 'black',
            }}
          >
            <Typography variant='subtitle1'>{data.country}</Typography>
          </Box>
        </Stack>
        <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ width: '100%' }}>
          <Typography variant='subtitle1'>{t('put_on_sale.start_date')}</Typography>
          <Box
            sx={{
              display: 'inline-block',
              float: 'right',
              paddingRight: '10px',
              color: 'black',
            }}
          >
            <Typography variant='subtitle1'>{dayjs(formValues.start_date).format('MM/DD/YYYY')}</Typography>
          </Box>
        </Stack>
        <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ width: '100%' }}>
          <Typography variant='subtitle1'>{t('put_on_sale.expiration')}</Typography>
          <Box
            sx={{
              display: 'inline-block',
              float: 'right',
              paddingRight: '10px',
              color: 'black',
            }}
          >
            <Typography variant='subtitle1'>{dayjs(formValues.end_date).format('MM/DD/YYYY')}</Typography>
          </Box>
        </Stack>
        <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ width: '100%' }}>
          <Typography variant='subtitle1'>{t('put_on_sale.number_of_copies')}</Typography>
          <Box
            sx={{
              display: 'inline-block',
              float: 'right',
              paddingRight: '10px',
              color: 'black',
            }}
          >
            <Typography variant='subtitle1'>{formValues.amount}</Typography>
          </Box>
        </Stack>
        <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ width: '100%' }}>
          <Typography variant='subtitle1'>{t('put_on_sale.price')}</Typography>
          <Box
            sx={{
              display: 'inline-block',
              float: 'right',
              paddingRight: '10px',
              color: 'black',
            }}
          >
            <Typography variant='subtitle1'>{formValues.price}</Typography>
          </Box>
        </Stack>
      </Box>

      {postData ? (
        <Box sx={{ marginTop: '20px', height: '50px' }}>
          <LoadingSpinner size={32} />
        </Box>
      ) : (
        <Stack direction='row' justifyContent='space-between' alignItems='center' gap={2} sx={{ width: '100%' }}>
          <Button onClick={BackOpen} sx={ButtonWhiteStyle}>
            <Typography variant='button'>{t('put_on_sale.btn_back')}</Typography>
          </Button>
          <Button type='submit' sx={ButtonStyle}>
            <Typography variant='button'>{t('put_on_sale.put_on_sale')}</Typography>
          </Button>
        </Stack>
      )}
    </form>
  );
}
