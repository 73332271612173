import React, { useState } from 'react';
import { CircularProgress, IconButton, SvgIcon } from '@mui/material';
import { PictureAsPdfOutlined } from '@mui/icons-material';
import axios from 'axios';
import dayjs from 'dayjs';
import { getTimezone } from 'utils';
import { authStore } from 'store';
import { appConfig } from 'config';

interface PdfLinkButtonProps {
  row: any;
}

export const PdfLinkButton: React.FC<PdfLinkButtonProps> = ({ row }) => {
  const [loading, setLoading] = useState(false);

  const handlePdfClick = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    setLoading(true);

    try {
      const timezone = getTimezone();
      const response = await axios.post(
        `${appConfig.apiUrl}/insurance/contract`,
        {
          risk: row.name,
          location: row.location,
          startTime: dayjs(row.mint).toISOString(),
          endTime: dayjs(row.expiration).toISOString(),
          premium: row.comission ? String(row?.comission)?.split(' ')?.[0] : 0,
          underwrite: row.underwrite ? String(row?.underwrite)?.split(' ')?.[0] : 0,
          timezone,
        },
        {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${authStore.accessToken}`,
          },
        },
      );
      const link = await axios.get(`${appConfig.apiUrl}/insurance/contract/${response.data.id}`, {
        headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
        },
        responseType: 'blob',
      });
      const blob = new Blob([link.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error generating PDF', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <a
      onClick={handlePdfClick}
      target='_blank'
      rel='noreferrer'
      style={{
        textDecoration: 'none',
        color: 'inherit',
      }}
    >
      <IconButton color='primary' aria-label='download pdf'>
        {loading ? <CircularProgress size={16} /> : <SvgIcon component={PictureAsPdfOutlined} inheritViewBox />}
      </IconButton>
    </a>
  );
};
