import { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '../../../store/toolkit';
import { useInjectReducer, useInjectSaga } from '../../../store/redux-injectors';
import { InsuranceMetaData, PoliciesState } from './types';
import { policiesSaga } from './saga';

export const initialState: PoliciesState = {
  loading: false,
  insurances: [],
  insurancesCount: 0,
  liquidityProviders: [],
  liquidityProvidersCount: 0,
  orders: [],
  ordersCount: 0,
  error: null,
};

const slice = createSlice({
  name: 'policies',
  initialState,
  reducers: {
    getInsurances(state, action: PayloadAction<string>) {
      state.loading = true;
    },
    receivedInsurances(state, action: PayloadAction<InsuranceMetaData>) {
      state.insurances = action.payload.results;
      state.insurancesCount = action.payload.total;
      state.loading = false;
    },
    policiesError(state, action: PayloadAction<Error | null>) {
      state.error = action.payload;
      state.loading = false;
    },
    clearError(state) {
      state.error = null;
    },
    clearState(state) {
      state.loading = false;
      state.error = null;
    },
  },
});

export const { actions: policiesActions } = slice;

export const usePoliciesSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: policiesSaga });
  return { actions: slice.actions };
};
