import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import LoadingSpinner from '../../components/LoadingSpinner';
import GetMyOrders from 'services/GetMyOrders';
import { differenceInDays } from 'date-fns';
import { REFRESH_MO_TABLE } from '../../constants';
import { useFetch } from 'hooks';
import CancelPutOnSaleOrder from 'services/CancelPutOnSaleOrder';
import DoneIcon from '@mui/icons-material/Done';
import ScheduleIcon from '@mui/icons-material/Schedule';
import BlockIcon from '@mui/icons-material/Block';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import { PdfLinkButton } from './PdfLinkButton';
import { EventStylesConfig, EventStylesConfigBuilder, StatusLabel } from './StatusLabel';
import { HeadCell, SortableTableHead } from 'components/table/SortableTableHead';
import useTableSort from 'hooks/useTableSort';
import { Stack, Typography } from '@mui/material';
import TableRowSkeleton from './TableRowSkeleton';

type MyOrder = Awaited<ReturnType<typeof GetMyOrders>>[0];
const headCells: HeadCell<MyOrder>[] = [
  {
    id: 'active',
    sortType: 'character',
    disablePadding: true,
    label: 'policies.tables.status',
    align: 'center',
  },
  {
    id: 'name',
    sortType: 'character',
    disablePadding: true,
    label: 'policies.tables.type',
    align: 'left',
  },
  {
    id: 'location',
    sortType: 'character',
    disablePadding: true,
    label: 'policies.tables.location',
    align: 'left',
  },
  {
    id: 'startDate',
    sortType: 'date',
    disablePadding: true,
    label: 'policies.tables.start date',
    align: 'right',
  },
  {
    id: 'expiration',
    sortType: 'date',
    disablePadding: true,
    label: 'policies.tables.expiration',
    align: 'right',
  },
  {
    id: 'comission',
    sortType: 'numeric',
    disablePadding: true,
    label: 'policies.tables.rewards',
    align: 'right',
    maxWidth: '90px',
  },
  {
    id: 'price',
    sortType: 'numeric',
    disablePadding: true,
    label: 'policies.tables.price',
    align: 'right',
    maxWidth: '70px',
  },
  {
    id: 'tokens',
    sortType: 'numeric',
    disablePadding: true,
    label: 'policies.tables.tokens',
    align: 'right',
  },
  {
    id: 'until',
    sortType: 'numeric',
    disablePadding: true,
    label: 'policies.tables.until',
    align: 'right',
  },
  {
    id: 'action',
    sortType: 'character',
    disablePadding: true,
    label: 'policies.tables.actions',
    align: 'right',
    disabledSort: true,
  },
];

export default function MyOrdersTable() {
  const { response: data, loading, autoUpdate, retry } = useFetch(GetMyOrders, [], [], true, REFRESH_MO_TABLE);

  const { getSortedData, onRequestSort, order, orderBy } = useTableSort({
    headCells,
    defaultOrder: 'asc',
    defaultOrderBy: 'mint',
  });

  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [orderStatus, setStatus] = React.useState({} as any);

  // console.log(orderStatus);

  React.useEffect(() => {
    if (Object.values(orderStatus).includes('loading')) {
      autoUpdate(false);
    } else {
      autoUpdate(true);
      retry();
    }
  }, [orderStatus]);

  const handleOpen = async (id: number) => {
    setStatus((current: any) => ({ ...current, [id]: 'loading' }));
    await CancelPutOnSaleOrder(id, (status: string) =>
      setStatus((current: any) => {
        if (status === 'Finalized' || status === 'error') {
          return { ...current, [id]: status };
        }
        return current;
      }),
    );
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const eventStylesConfig: EventStylesConfig = new EventStylesConfigBuilder()
    .withConfig('Expired')
    .withConfig('Occurred')
    .withConfig('Approved')
    .withConfig('Active')
    .build();

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer sx={{ py: 1 }}>
          <Table aria-labelledby='tableTitle' size={dense ? 'small' : 'medium'}>
            {/* <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} /> */}
            <SortableTableHead
              headCells={headCells}
              onRequestSort={onRequestSort}
              order={order}
              orderBy={orderBy}
              tableCellProps={{ sx: { padding: '16px' } }}
            />
            <TableBody>
              {getSortedData(
                data.map((row) => ({
                  ...row,
                  startDate: row.mint,
                  tokens: row.amount,
                  price: row.pricePerToken,
                  until:
                    differenceInDays(new Date(row.expiration), new Date()) > 0
                      ? differenceInDays(new Date(row.expiration), new Date())
                      : 0,
                  action:
                    row.type === 'Sold'
                      ? 'Sold'
                      : row.type === 'Canceled'
                      ? 'Canceled'
                      : row.type === 'Expired'
                      ? 'Expired'
                      : 'Cancel Order',
                })),
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover role='checkbox' tabIndex={-1} key={row.id}>
                      <TableCell align='center'>
                        {row.type === 'Sold' ? (
                          <DoneIcon />
                        ) : row.type === 'Sell' ? (
                          <ScheduleIcon />
                        ) : row.type === 'Canceled' ? (
                          <BlockIcon />
                        ) : (
                          <RunningWithErrorsIcon />
                        )}
                      </TableCell>
                      <TableCell align='left' style={{ padding: '8px' }}>
                        <Box id={labelId}>
                          <Stack direction='row' alignItems='center'>
                            <Typography variant='body1'>{row.name}</Typography>&nbsp;
                            <PdfLinkButton row={row} />
                          </Stack>
                        </Box>
                      </TableCell>
                      <TableCell align='left' style={{ padding: '8px' }}>
                        <Typography variant='body1'>{row.location}</Typography>
                      </TableCell>
                      <TableCell align='right' style={{ padding: '8px' }}>
                        <Typography variant='body1' sx={{ maxWidth: '110px', textAlign: 'right' }}>
                          {row.mint}
                        </Typography>
                      </TableCell>
                      <TableCell align='right' style={{ padding: '8px' }}>
                        <Typography
                          variant='body1'
                          sx={{ width: '100%', float: 'right', maxWidth: '110px', textAlign: 'right' }}
                        >
                          {row.expiration}
                        </Typography>
                      </TableCell>
                      <TableCell align='right' style={{ padding: '8px' }}>
                        <Typography variant='body1'>{row.comission}</Typography>
                      </TableCell>
                      <TableCell align='right' style={{ padding: '8px' }}>
                        <Typography variant='body1'>{row.pricePerToken} USDT</Typography>
                      </TableCell>
                      <TableCell align='right' style={{ padding: '8px' }}>
                        <Typography variant='body1'>
                          {row.amount}/{Number(row.underwrite) / 100}
                        </Typography>
                      </TableCell>
                      <TableCell align='right' style={{ padding: '8px' }}>
                        <Typography variant='body1'>
                          {row.until === 1 ? `${row.until} Day` : `${row.until} Days`}
                        </Typography>
                      </TableCell>
                      <TableCell
                        style={{
                          lineHeight: '24px',
                        }}
                        align='center'
                      >
                        {orderStatus[row.tokenId] === 'loading' ? (
                          <Box sx={{ paddingTop: '0px' }}>
                            <LoadingSpinner />
                          </Box>
                        ) : (
                          <Button
                            style={{
                              fontFamily: 'Questrial',
                              border: row.type === 'Sell' ? '2px solid #3555C8' : '2px solid rgba(41, 45, 63, 0.3)',
                              borderRadius: '12px',
                              backgroundColor: row.type === 'Sell' ? '#3555C8' : 'rgba(41, 45, 63, 0.3)',
                              justifyContent: 'center',
                              padding: '4px 6px',
                              width: 'auto',
                              fontSize: '12px',
                              color: 'white',
                              whiteSpace: 'nowrap',
                              // textTansform: 'none !important',
                            }}
                            {...{
                              key: 'Cancel',
                              color: 'inherit',
                            }}
                            onClick={() => handleOpen(row.tokenId)}
                            disabled={row.type !== 'Sell'}
                          >
                            {row.type === 'Sold'
                              ? 'Sold'
                              : row.type === 'Canceled'
                              ? 'Canceled'
                              : row.type === 'Expired'
                              ? 'Expired'
                              : 'Cancel Order'}
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {loading &&
                Array.from({ length: rowsPerPage }, (_, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <TableRowSkeleton animation='wave' align='center' width='20px' height='20px' variant='circular' />
                    </TableCell>
                    <TableCell>
                      <TableRowSkeleton animation='wave' width='150px' />
                    </TableCell>
                    <TableCell>
                      <TableRowSkeleton animation='wave' width='120px' />
                    </TableCell>
                    <TableCell>
                      <TableRowSkeleton animation='wave' align='right' width='100px' />
                    </TableCell>
                    <TableCell>
                      <TableRowSkeleton animation='wave' align='right' width='100px' />
                    </TableCell>
                    <TableCell>
                      <TableRowSkeleton animation='wave' align='right' />
                    </TableCell>
                    <TableCell>
                      <TableRowSkeleton animation='wave' align='right' />
                    </TableCell>
                    <TableCell>
                      <TableRowSkeleton animation='wave' align='right' />
                    </TableCell>
                    <TableCell>
                      <TableRowSkeleton animation='wave' align='right' width='70px' />
                    </TableCell>
                    <TableCell>
                      <TableRowSkeleton animation='wave' align='center' width='80px' />
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                    lineHeight: '24px',
                  }}
                >
                  <TableCell colSpan={10} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack direction='row' alignItems='center' sx={{ justifyContent: { xs: 'center', md: 'flex-end' } }}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage='Items per page'
            style={{
              color: '#939AAB',
              overflowX: 'hidden',
            }}
          />
        </Stack>
      </Paper>
    </Box>
  );
}
