import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import logoImg from '../img/logo.png';
import { web3Enable, isWeb3Injected } from '@polkadot/extension-dapp';
import { useState, useEffect } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import ModalForm from './ModalForm';
import { authStore, walletStore } from '../store';
import { observer } from 'mobx-react-lite';
import { useModal } from 'hooks';
import { Box, CssBaseline, IconButton, InputLabel, Menu, MenuItem, Stack, styled, Typography } from '@mui/material';
import { ButtonInstallPolkadot, ButtonInstance } from './buttons';
import { ModalInstance } from './modals';
import { showNotification } from 'utils';
import { AccountsDropdown } from './dropdowns';
import { LanguageSelect } from './dropdowns/LanguageSelect';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const Header = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { isOpen, open, close } = useModal();
  const { isOpen: isOpenConfirmLogoutModal, open: openConfirmLogoutModal, close: closeConfirmLogoutModal } = useModal();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpenMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const extensionSetup = async () => {
    const extensions = await web3Enable('decentro.parametricx.io');
    if (!isWeb3Injected) {
      showNotification({
        title: 'No extension installed!',
        message: <ButtonInstallPolkadot />,
      });
      return;
    }
    if (extensions.length === 0) {
      showNotification({
        title: 'User rejected the request!',
        message: "Please open the extension and accept the website's permission",
      });
      return;
    }
    await walletStore.getAccounts();
    open();
  };

  const extensionClear = () => {
    authStore.signOut();
    navigate('/home');
  };

  const handleClose = () => {
    close();
    walletStore.setAccounts([]);
  };

  const renderAdminMenuItems = () => {
    if (!authStore.isAdmin) return;
    const menuItems = [];

    menuItems.push(
      <MenuItem
        key={'stats'}
        sx={menuItemStyles}
        onClick={() => {
          handleCloseMenu();
          navigate('/admin/stats');
        }}
      >
        <Typography variant='button'>{t('buttons.stats')}</Typography>
      </MenuItem>,
      <MenuItem
        key={'active_insurance'}
        sx={menuItemStyles}
        onClick={() => {
          handleCloseMenu();
          navigate('/admin/insurances');
        }}
      >
        <Typography variant='button'>{t('buttons.active_insurance')}</Typography>
      </MenuItem>,
    );

    return menuItems;
  };

  const menuItemStyles = {
    display: { xl: 'none', xs: 'flex' },
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const menu = (
    <Menu
      id='basic-menu'
      anchorEl={anchorEl}
      open={isOpenMenu}
      onClose={handleCloseMenu}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      {renderAdminMenuItems()}

      {authStore.isDao && (
        <MenuItem
          sx={menuItemStyles}
          onClick={() => {
            handleCloseMenu();
            navigate('/my-dao');
          }}
        >
          <Typography variant='button'>{t('buttons.my dao')}</Typography>
        </MenuItem>
      )}

      {authStore.isAuthorized && !authStore.isAdmin && (
        <MenuItem
          sx={menuItemStyles}
          onClick={() => {
            handleCloseMenu();
            navigate('/my-policies');
          }}
        >
          <Typography variant='button'>{t('buttons.my policies')}</Typography>
        </MenuItem>
      )}

      {authStore.isAuthorized ? (
        <MenuItem
          sx={menuItemStyles}
          onClick={() => {
            handleCloseMenu();
            openConfirmLogoutModal();
          }}
        >
          <Typography variant='button'>{t('buttons.disconnect')}</Typography>
        </MenuItem>
      ) : (
        <MenuItem
          sx={menuItemStyles}
          onClick={() => {
            handleCloseMenu();
            extensionSetup();
          }}
        >
          <Typography variant='button'>{t('buttons.connect polkadot.js')}</Typography>
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <Stack direction='row' alignItems='center'>
      <CssBaseline />
      <StyledAppBar
        position='fixed'
        elevation={0}
        sx={{
          padding: {
            xs: '6px 5px',
            sm: '6px 15px',
            lg: '8px 35px',
            xl: '8px 50px',
          },
          borderRadius: '0 !important',
        }}
      >
        <StyledToolbar>
          <NavLink to='/home'>
            <Box
              component='img'
              width={166}
              src={logoImg}
              alt='slider'
              sx={{
                ...imgStyles,
                display: {
                  xs: 'inline-block',
                  xl: location.pathname.startsWith('/admin') ? 'none' : 'inline-block',
                },
              }}
            />
          </NavLink>

          <Stack justifyContent='flex-end' flex={1} direction='row' gap={{ xs: 1, md: 3 }}>
            {/* 
          //remove this comment to show language select
          <LanguageSelect />
         */}
            {authStore.isAdmin ? (
              <ButtonInstance
                title='Admin'
                sx={{ display: { xl: 'inline-flex', xs: 'none' } }}
                colorType='white'
                onClick={() => navigate('/admin/stats')}
              />
            ) : null}
            {authStore.isDao ? (
              <ButtonInstance
                title='My DAO'
                sx={{ display: { xl: 'inline-flex', xs: 'none' } }}
                colorType='white'
                onClick={() => navigate('/my-dao')}
              />
            ) : null}
            {authStore.isAuthorized && !authStore.isAdmin && (
              <ButtonInstance
                sx={{ display: { xl: 'inline-flex', xs: 'none' } }}
                colorType='white'
                title='My policies'
                onClick={() => navigate('/my-policies')}
              />
            )}

            <ModalForm data={{ insured: true }} filters={undefined} />

            {!authStore.isAuthorized && (
              <ButtonInstance
                sx={{ display: { xl: 'inline-flex', xs: 'none' } }}
                title='connect polkadot.js'
                onClick={extensionSetup}
              />
            )}

            {authStore.isAuthorized && (
              <ButtonInstance
                sx={{ display: { xl: 'inline-flex', xs: 'none' } }}
                title='disconnect'
                onClick={() => openConfirmLogoutModal()}
              />
            )}
            <IconButton
              id='basic-button'
              aria-controls={isOpenMenu ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={isOpenMenu ? 'true' : undefined}
              onClick={handleClick}
              sx={{
                display: {
                  xs: 'inline-block',
                  xl: 'none',
                },
              }}
            >
              <Box
                component='svg'
                width='20px'
                height='20px'
                viewBox='0 0 16 16'
                xmlns='http://www.w3.org/2000/svg'
                fill='#3555c8'
                className='bi bi-three-dots-vertical'
              >
                <path d='M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z' />
              </Box>
            </IconButton>
          </Stack>
        </StyledToolbar>
        <ModalInstance isOpen={isOpen} close={handleClose}>
          <AccountsDropdown close={handleClose} />
        </ModalInstance>

        <ModalInstance isOpen={isOpenConfirmLogoutModal} close={closeConfirmLogoutModal}>
          <Box>
            {/* <Box sx={style} className='modal-content'> */}
            <Typography
              style={{
                marginBottom: '32px',
                fontFamily: 'Poppins',
                fontWeight: 600,
                fontSize: '28px',
                lineHeight: '40px',
                color: '#292D3F',
              }}
              variant='h2'
            >
              {t('modal_form.confirmation_title')}
            </Typography>
            <InputLabel
              style={{
                fontFamily: 'Poppins',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '24px',
              }}
              id='accounts-select-label'
            >
              {t('modal_form.confirmation_logout')}
            </InputLabel>
            <Box
              className='modal-actions'
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '32px',
              }}
            >
              <ButtonInstance onClick={closeConfirmLogoutModal} title='cancel' style={{ margin: 0 }} colorType='red' />
              <ButtonInstance
                onClick={() => {
                  extensionClear();
                  navigate('/');
                  closeConfirmLogoutModal();
                }}
                title='confirm'
              />
            </Box>
          </Box>
        </ModalInstance>
      </StyledAppBar>
      {menu}
    </Stack>
  );
});

export default Header;

const StyledAppBar = styled(AppBar)({
  backgroundColor: 'white',
  width: '100%',
});

const StyledToolbar = styled(Toolbar)({
  width: '100%',
  flex: 1,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const imgStyles = {
  width: 'auto',
  maxWidth: '100%',
  height: 'auto',
  padding: 0,
  margin: 0,
};
