import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import { AdminStatsView, AdminView, MainLayoutView, MyDAOView, AdminInsurancesView } from './views';
import './App.css';
import { PoliciesPage } from 'features/policies';
import { HomePage } from 'features/home';

export const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<MainLayoutView />}>
          <Route index element={<Navigate to='/home' />} />
          <Route path='/home' element={<HomePage />} />
          <Route path='my-policies' element={<PoliciesPage />} />
          <Route path='my-dao' element={<MyDAOView />} />
          <Route path='*' element={<Navigate to='/home' />} />
          <Route path='admin' element={<AdminView />}>
            <Route index element={<Navigate to='/admin/stats' />} />
            <Route path='stats' element={<AdminStatsView />} />
            <Route path='insurances' element={<AdminInsurancesView />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
