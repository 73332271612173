import { Button, SxProps, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type ColorType = 'blue' | 'white' | 'red';

interface ButtonProps extends React.ComponentPropsWithoutRef<typeof Button> {
  colorType?: ColorType;
  title?: string;
  style?: React.CSSProperties;
}

interface IColors {
  [key: string]: string;
}

export const ButtonInstance = ({
  colorType = 'blue',
  onClick,
  title,
  style,
  children,
  disabled = false,
  type = 'button',
  sx: sxProps = {},
}: ButtonProps) => {
  const { t } = useTranslation();
  const colors = calculateColors(colorType);
  const sx = sxStyle(colors);
  return (
    <Button
      type={type}
      onClick={onClick}
      disabled={disabled}
      style={style}
      sx={{ ...sx, ...sxProps } as SxProps<Theme>}
    >
      {title ? <Typography variant='button'>{t(`buttons.${title?.toLowerCase()}`)}</Typography> : children}
    </Button>
  );
};

const sxStyle = ({ color, backgroundColor, hoverColor, backgroundHover, borderColor }: IColors) => ({
  padding: '6px 12px',
  boxShadow: `inset 0 0 0 2px ${borderColor}`,
  borderRadius: `12px`,
  backgroundColor: backgroundColor,
  justifyContent: 'center',
  width: 'fit-content',
  color: color,
  whiteSpace: 'nowrap',
  [`&.Mui-disabled`]: {
    backgroundColor: 'rgba(41, 45, 63, 0.3)',
    boxShadow: 'none',
    color: '#FFFFFF',
  },
  [`&:hover`]: {
    color: hoverColor,
    backgroundColor: backgroundHover,
  },
});

export const calculateColors = (colorType: ColorType): IColors => {
  if (colorType === 'blue') {
    return {
      color: '#FFFFFF',
      backgroundColor: '#3555C8',
      hoverColor: '#3555C8',
      backgroundHover: '#FFFFFF',
      borderColor: '#3555C8',
    };
  } else if (colorType === 'white') {
    return {
      color: '#3555C8',
      backgroundColor: '#FFFFFF',
      hoverColor: '#FFFFFF',
      backgroundHover: '#3555C8',
      borderColor: '#3555C8',
    };
  } else {
    return {
      color: '#FFFFFF',
      backgroundColor: 'red',
      hoverColor: 'red',
      backgroundHover: '#FFFFFF',
      borderColor: 'red',
    };
  }
};
