import axios from 'axios';
import { appConfig } from 'config';
import { countryOptions } from 'services/CountryOptions';
import qs from 'qs';
export interface IGetPRMXBuyersResponseData {
  title: string;
  country: string;
  status: string;
  image: string;
  premium: number;
  underwrite: number;
  event: string;
  location: string;
}

export type InsuranceOrderByKeyType =
  | 'id'
  | 'insuranceId'
  | 'type'
  | 'amount'
  | 'pricePerToken'
  | 'premium'
  | 'underwrite'
  | 'startTime'
  | 'endTime'
  | 'createdBy'
  | 'id'
  | 'smtId'
  | 'rest'
  | 'status';
export interface InsuranceFiltersParams {
  id?: number[];
  risk?: string[];
  location?: string[];
  premiumFrom?: number;
  premiumTo?: number;
  underwriteFrom?: number;
  underwriteTo?: number;
  order?: 'asc' | 'desc';
  orderBy?: InsuranceOrderByKeyType;
}

export interface IUnifiedMarketCardData {
  title: string;
  country: string;
  status: string;
  image: string;
  amount?: number; // Optional property from LqdtPrvdrsItemType
  reward?: number; // Optional property from LqdtPrvdrsItemType
  available?: string; // Optional property from scndrMrktLqdtItemType
  price?: number; // Optional property from scndrMrktLqdtItemType
  startTime: string;
  endTime: string;
  holder: string;
  event: string;
  SMLpremium?: number; // Optional property from scndrMrktLqdtItemType
  SMLunderwrite?: number; // Optional property from scndrMrktLqdtItemType
  numberOfCopies?: number; // Optional property from scndrMrktLqdtItemType
  amountPopup?: number; // Optional property from scndrMrktLqdtItemType
  id: string | number; // Common property in both interfaces
}
export interface IInsuranceRequestParams {
  page: number;
  pageSize: number;
  filters?: InsuranceFiltersParams;
}

export interface IPaginationResponse<T> {
  total: number;
  results: T[];
}

export const getPRMXBuyers = async ({
  page,
  pageSize,
  filters,
}: IInsuranceRequestParams): Promise<IPaginationResponse<IGetPRMXBuyersResponseData>> => {
  const data: IGetPRMXBuyersResponseData[] = [];
  const Earthquake = ['insurance4', 'insurance11', 'insurance15'];
  const Cyclone = ['insurance2', 'insurance7', 'insurance13'];
  // const eventFilter = filters && filters[0] !== 'Event' ? `&risk=${filters[0]}` : '';
  // let locationFilter = filters && filters[1] !== 'Location' ? `&location=${filters[1]}` : '';

  // locationFilter = locationFilter !== '&location=Miami, Florida, USA' ? locationFilter : '&location=Florida, USA';
  // locationFilter =
  //   locationFilter !== '&location=San Francisco, California, USA' ? locationFilter : '&location=San Francisco, USA';

  // ?page=${page}&pageSize=${pageSize}&status=Approved${eventFilter}${locationFilter}
  try {
    const response = await axios.get(`${appConfig.apiUrl}/insuranceTemplate`, {
      params: {
        page,
        pageSize,
        status: 'Approved',
        ...filters,
      },
      paramsSerializer: (p) => {
        return qs.stringify(p, { arrayFormat: 'repeat' });
      },
      headers: { 'Content-Type': 'application/json' },
    });

    if (response.data.total > 0) {
      for (const row of response.data.results) {
        const location = row.location.split(', ');

        const tmp: IGetPRMXBuyersResponseData = {
          title: `${row.risk} Insurance`,
          country: location[1],
          status: 'Active',
          image: row.risk !== 'Earthquake' ? Cyclone[row.id % 2] : Earthquake[row.id % 2],
          premium: row.premium,
          underwrite: row.underwrite,
          event: row.risk,
          // For search:
          location: location[0],
        };
        data.push(tmp);
      }
    }
    return {
      total: response.data.total,
      results: data,
    };
  } catch (error) {
    console.log('Missing data for PRMX Buyers tab');
    return {
      total: 0,
      results: [],
    };
  }
};

export const getPRMXLiquidityProviders = async ({
  page,
  pageSize,
  filters,
}: IInsuranceRequestParams): Promise<IPaginationResponse<IUnifiedMarketCardData>> => {
  const data: IUnifiedMarketCardData[] = [];
  const liquidityProvidedIds = [];
  const Earthquake = ['insurance4', 'insurance11', 'insurance15'];
  const Cyclone = ['insurance2', 'insurance7', 'insurance13'];
  // const eventFilter = filters && filters[0] !== 'Event' ? `&risk=${filters[0]}` : '';
  // const locationFilter = filters && filters[1] !== 'Location' ? `&location=${filters[1]}` : '';
  // ?page = ${ page }& pageSize=${ pageSize }& status=Approved & status=Active${ eventFilter }${ getCountryRevert(locationFilter,) }

  const ignoreOrderBy = ['amount', 'pricePerToken'];

  try {
    const response = await axios.get(`${appConfig.apiUrl}/insurance/liquidity`, {
      params: {
        page,
        pageSize,
        status: ['Approved', 'Active'],
        ...filters,
        order: filters?.order,
        orderBy: ignoreOrderBy.includes(String(filters?.orderBy)) ? 'underwrite' : filters?.orderBy,
      },
      paramsSerializer: (p) => {
        return qs.stringify(p, { arrayFormat: 'repeat' });
      },
      headers: { 'Content-Type': 'application/json' },
    });

    if (response.data.total > 0) {
      for (const row of response.data.results) {
        const tmp: IUnifiedMarketCardData = {
          title: `${row.risk} Insurance`,
          country: getCountry(row.location),

          status: row.status,
          image:
            row.risk !== 'Earthquake'
              ? Cyclone[Math.floor(Math.random() * 3)]
              : Earthquake[Math.floor(Math.random() * 3)],

          amount: row.underwrite,
          reward: row.premium,
          id: row.id,
          startTime: row.startTime,
          endTime: row.endTime,
          holder: row.holder,
          event: row.risk,
        };
        data.push(tmp);
        liquidityProvidedIds.push(row.id);
      }
    }
    return {
      total: response.data.total,
      results: data,
    };
  } catch (error) {
    console.log('Missing data for PRMX Liquidity Providers tab');
    return {
      total: 0,
      results: [],
    };
  }
};

export async function getPRMXOrders({ page, pageSize, filters }: IInsuranceRequestParams) {
  const data: IUnifiedMarketCardData[] = [];
  const orderPartiallyFulfilledIds = [];
  const Earthquake = ['insurance4', 'insurance11', 'insurance15'];
  const Cyclone = ['insurance2', 'insurance7', 'insurance13'];
  // const eventFilter = filters && filters[0] !== 'Event' ? `&risk=${filters[0]}` : '';
  // const locationFilter = filters && filters[1] !== 'Location' ? `&location=${filters[1]}` : '';

  // page=${page}&pageSize=${pageSize}&type=Sell&status=Approved&status=Active${eventFilter}${getCountryRevert(locationFilter)},

  try {
    const response = await axios.get(`${appConfig.apiUrl}/insurance/order`, {
      params: {
        page,
        pageSize,
        type: 'Sell',
        status: ['Approved', 'Active'],
        ...filters,
      },
      paramsSerializer: (p) => {
        return qs.stringify(p, { arrayFormat: 'repeat' });
      },
      headers: { 'Content-Type': 'application/json' },
    });

    const { total, results } = response.data;

    if (total > 0) {
      for (const row of results) {
        const { insurances, createdBy, pricePerToken, amount, id, rest } = row;
        const { location, status, startTime, endTime, premium, underwrite, risk } = insurances;

        orderPartiallyFulfilledIds.push(id);

        const tmp: IUnifiedMarketCardData = {
          title: `${risk} Insurance`,
          country: getCountry(location),
          status,
          image:
            risk !== 'Earthquake' ? Cyclone[Math.floor(Math.random() * 3)] : Earthquake[Math.floor(Math.random() * 3)],
          available: `${rest}/${underwrite / 100}`,
          price: pricePerToken,
          holder: createdBy,
          startTime,
          endTime,
          SMLpremium: premium,
          SMLunderwrite: underwrite,
          numberOfCopies: Math.trunc((underwrite / pricePerToken) * 100),
          amountPopup: amount,
          id,
          event: risk,
        };
        data.push(tmp);
      }
    }
    return {
      total: total,
      results: data,
    };
  } catch (error) {
    console.log('Missing data for PRMX Secondary Market Liquidity buyers tab');
    return {
      total: 0,
      results: [],
    };
  }
}

const getCountry = (locationId: string) => {
  const { label } = countryOptions.find(({ value }) => {
    return value === locationId;
  }) || { label: '' };
  return label;
};

// const getCountryRevert = (value: string) => {
//   let data = value.split(', ')[1];
//   if (data === 'California') {
//     data = 'California, USA';
//   }
//   if (data === 'Florida') {
//     data = 'Florida, USA';
//   }
//   const options = countryOptions.filter((countryData) => {
//     return countryData.label === data;
//   });

//   let filter = options[0]?.value ? options[0]?.value : '';

//   if (filter !== '') {
//     filter = `&location=${filter}`;
//   }

//   return filter;
// };
