import { useTranslation } from 'react-i18next';
import StaySafeImg from '../img/stay-safe.png';
import ModalForm from './ModalForm';
import { Box, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

const staySafeStyle = {
  width: '100%',
  maxWidth: '1200px',
  margin: '20px auto',
  padding: {
    xs: '0 24px',
    xl: '0',
  },
};

const staySafeBoxStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: {
    xs: 'center',
    lg: 'flex-start',
  },
  flexWrap: 'wrap',
  gap: '40px',
  padding: '45px 0px',
  flexDirection: {
    xs: 'column',
    lg: 'row',
  },
};

const staySafeTextStyle = {
  flex: 1,
  fontWeight: 500,
  lineHeight: '28px',
  color: 'rgba(41, 45, 63, 0.72)',
  paddingTop: '12px',
  width: {
    xs: '100%',
    lg: '40%',
  },
};

const staySafeTitleStyle = {
  fontFamily: 'Poppins',
  fontWeight: 'bold',
  lineHeight: {
    xs: '36px',
    lg: '48px',
  },
  letterSpacing: '0px',
  color: '#292D3F',
};

const staySafeTextBoxStyle = {
  padding: {
    xs: '13px 0px',
    sm: '26px 0px',
  },
  fontWeight: 'bold',
  lineHeight: {
    xs: '20px',
    sm: '25px',
  },
};

export default function StaySafe() {
  const { t } = useTranslation();
  return (
    <Box sx={staySafeStyle}>
      <Box sx={staySafeBoxStyle}>
        <Box sx={staySafeTextStyle}>
          <Typography variant='h1' sx={staySafeTitleStyle}>
            {t('safe.humanizing_insurance')}
          </Typography>
          <Typography variant='h4' sx={staySafeTextBoxStyle}>
            {t('safe.text')}
          </Typography>

          <ModalForm
            data={{ insured: true }}
            triggerProps={{
              sx: {
                display: 'block',
                marginX: {
                  lg: '20px',
                  xs: 'auto',
                },
              },
            }}
          />
        </Box>
        <Box>
          <NavLink to='/'>
            <Box
              component='img'
              sx={{ width: '100%', maxWidth: '420px', height: 'auto', padding: 0, margin: 0 }}
              src={StaySafeImg}
              alt='slider'
            />
          </NavLink>
        </Box>
      </Box>
    </Box>
  );
}
