import { FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import validator from 'validator';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Checkbox from '@mui/material/Checkbox';
import BuyPRMXBuyers from '../services/BuyPRMXBuyers';
import LoadingSpinner from './LoadingSpinner';
import { authStore, walletStore } from 'store';
import GetBalance from 'services/GetBalance';
import BuyPRMXLiquidityProviders from '../services/BuyPRMXLiquidityProviders';
import BuyPRMXSMLiquidityBuyers from '../services/BuyPRMXSMLiquidityBuyers';
import runOneSignal, { getOneSignalPlayerID, oneSignalLogin } from 'onesignal';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Typography } from '@mui/material';
import { FormData } from './ModalForm';
import { ButtonInstance } from './buttons';
import { appConfig } from 'config';

function browserDetect() {
  const userAgent = navigator.userAgent;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    return 'Chrome';
  } else if (userAgent.match(/firefox|fxios/i)) {
    return 'Firefox';
  } else if (userAgent.match(/safari/i)) {
    return 'Safari';
  } else if (userAgent.match(/opr\//i)) {
    return 'Opera';
  } else if (userAgent.match(/edg/i)) {
    return 'Edge';
  } else {
    return 'No browser detection';
  }
}

const FormStyle = {
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '24px',
  color: 'rgba(41, 45, 63, 0.72)',
  fontFamily: 'Questrial',
};
const TextFieldStyle = {
  color: 'rgba(41, 45, 63, 0.72)',
  display: 'inline-block',
  margin: '0px 36px 0px 0px',
  borderRadius: '12px !important',
  background: 'rgba(41, 45, 63, 0.03)',
  width: '100%',
};

export interface FormDataStep2 extends FormData {
  email: string;
  emailChange: boolean;
  pushChange: boolean;
  disabledButton: boolean;
  disabledEmail: boolean;
  step: number;
  PRMXLiquidityProvidersId?: number | string;
  PRMXSMLiquidityBuyersId?: number | string;
  PRMXSMLiquidityBuyersAmount?: number;
  PRMXSMLiquidityBuyersPrice?: number;
  premium_amount: number;
  underwrite_amount: number;
  update: boolean;
}

interface Props {
  modalType: string;
  setStep: (step: number) => void;
  formValues: FormDataStep2;
  setFormValues: React.Dispatch<React.SetStateAction<NonNullable<FormDataStep2>>>;
  handleClose: () => void;
  postData: boolean;
  setPostData: React.Dispatch<React.SetStateAction<NonNullable<boolean>>>;
}

interface ISettingProps {
  pushWeb: string;
  pushWebChannelId: string | null | undefined;
  email: string;
}

export default function Step2({
  modalType,
  setStep,
  formValues,
  setFormValues,
  handleClose,
  postData,
  setPostData,
}: Props) {
  const { t } = useTranslation();
  const [disabledButton, setDisabledButton] = useState(
    formValues.disabledButton !== undefined ? formValues.disabledButton : true,
  );
  const [disabledEmail, setDisabledEmail] = useState(
    formValues.disabledEmail !== undefined ? formValues.disabledEmail : true,
  );

  const ButtonStyle = {
    fontFamily: 'Questrial',
    borderRadius: `12px`,
    justifyContent: 'center',
    padding: '8px 12px',
    cursor: 'pointer',
    lineHeight: '24px',
    color: 'white',
    textTansform: 'none !important',
    marginLeft: '0px',
    width: '100%',
    height: '48px',
    marginTop: '18px',
  };

  const ButtonWhiteStyle = {
    fontFamily: 'Questrial',
    borderRadius: `12px`,
    backgroundColor: 'white',
    justifyContent: 'center',
    padding: '8px 12px',
    cursor: 'pointer',
    lineHeight: '24px',
    color: '#3555C8',
    textTansform: 'none !important',
    marginLeft: '0px',
    width: '100%',
    height: '48px',
    marginTop: '18px',
  };

  const methods = useForm<FormDataStep2>();
  const [orderStatus, setOrderStatus] = useState<string>('');

  useEffect(() => {
    if (orderStatus === 'error') {
      setPostData(false);
    }

    if (orderStatus === 'Finalized') {
      setPostData(false);
      setStep(3);
    }
  }, [orderStatus]);

  const onSubmit: SubmitHandler<FormDataStep2> = async (values) => {
    setPostData(true);
    setFormValues({
      ...formValues,
      ...values,
    });

    const settings: ISettingProps = { pushWeb: '', pushWebChannelId: null, email: '' };
    if (checkboxPushChange) {
      settings.pushWeb = browserDetect();

      try {
        settings.pushWebChannelId = await getOneSignalPlayerID();
      } catch (error) {
        console.log('Error getting OneSignal Player ID');
      }
      // console.log('settings', settings);
    }
    if (checkboxEmailChange) {
      settings.email = email;
    }

    try {
      await axios.put(
        'https://notif.dev.decentro.parametricx.io/user/me',
        {
          settings,
        },
        {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${authStore.accessToken}`,
          },
        },
      );
    } catch (error) {
      console.log('Missing post data for user notifications');
    }

    let result = 'success';

    if (formValues.step === 1) {
      result = await BuyPRMXBuyers(formValues, setOrderStatus);
    }
    if (formValues.step === 2) {
      if (formValues.PRMXLiquidityProvidersId) {
        result = await BuyPRMXLiquidityProviders(String(formValues.PRMXLiquidityProvidersId), setOrderStatus);
      }
    }
    if (formValues.step === 3) {
      if (formValues.PRMXSMLiquidityBuyersId && formValues.PRMXSMLiquidityBuyersAmount !== undefined) {
        result = await BuyPRMXSMLiquidityBuyers(
          Number(formValues.PRMXSMLiquidityBuyersId),
          formValues.PRMXSMLiquidityBuyersAmount,
          setOrderStatus,
        );
      }
    }

    if (result === 'cancel') {
      setPostData(false);
      handleClose();
    }
  };

  const BackOpen = () => {
    formValues.email = email;
    formValues.emailChange = checkboxEmailChange;
    formValues.pushChange = checkboxPushChange;
    formValues.disabledButton = disabledButton;
    formValues.disabledEmail = disabledEmail;

    setFormValues({
      ...formValues,
    });

    setStep(1);
  };

  const [email, setEmail] = useState(formValues.email ? formValues.email : '');
  const [emailError, setEmailError] = useState(false);

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (validator.isEmail(event.target.value)) {
      setEmail(event.target.value);
      setDisabledButton(false);
      setEmailError(false);
    } else {
      setEmail(event.target.value);
      setEmailError(true);
      setDisabledButton(true);
    }
  };

  const [balance, setBalance] = useState(Number(walletStore.balance));

  useEffect(() => {
    async function fetchData() {
      setPostData(true);

      const { balance } = await GetBalance(walletStore.walletAddress);
      setBalance(Number(balance));

      // Money sufficiency check.
      if (formValues.step === 1) {
        if (Number(balance) / Math.pow(10, appConfig.USDT_DECIMAL_SCALE) < Number(formValues.premium_amount) + 1) {
          setBalance(Number(0));
        }
      } else if (formValues.step === 2) {
        if (Number(balance) / Math.pow(10, appConfig.USDT_DECIMAL_SCALE) < Number(formValues.underwrite_amount) + 1) {
          setBalance(Number(0));
        }
      } else if (formValues.step === 3) {
        if (Number(balance) / Math.pow(10, appConfig.USDT_DECIMAL_SCALE) < Number(formValues.underwrite_amount) + 1) {
          setBalance(Number(0));
        }
      }

      let response;
      try {
        response = await axios.get(`https://notif.dev.decentro.parametricx.io/user/me?browser=${browserDetect()}`, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${authStore.accessToken}`,
          },
        });
      } catch (error) {
        console.log('Missing data for user', error);
        setPostData(false);
      }

      // If the user does not have more than one checkbox checked, we set both:
      if (response && response.data && !response.data.email && !response.data.pushWeb) {
        setCheckboxEmailChange(true);
        setCheckboxPushChange(true);
        runOneSignal();
      }

      if (response && response.data && response.data.email) {
        setCheckboxEmailChange(true);
        setEmail(response.data.email);
        setDisabledEmail(true);
        setDisabledButton(false);
      } else {
        setCheckboxEmailChange(false);
        setDisabledButton(false);
        setDisabledEmail(false);
      }

      if (response && response.data && response.data.pushWeb) {
        // console.log('2');
        // setCheckboxPushChange(true);
        // oneSignalRegister(walletStore.walletAddress);
        // runOneSignal();
      } else {
        setCheckboxPushChange(false);
      }

      formValues.update = true;

      setPostData(false);
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [checkboxEmailChange, setCheckboxEmailChange] = useState(false);
  const checkboxEmailChangeUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxEmailChange(event.target.checked);

    if (!event.target.checked) {
      setDisabledEmail(!true);
      setDisabledButton(!true);
      setEmail('');
      setEmailError(false);
    } else {
      setDisabledEmail(true);
      !email && setDisabledButton(true);
    }
  };

  const [checkboxPushChange, setCheckboxPushChange] = useState(false);

  const checkboxPushChangeUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxPushChange(event.target.checked);
    if (event.target.checked) {
      oneSignalLogin(walletStore.walletAddress);
      runOneSignal();
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} style={FormStyle}>
          <Typography
            variant='h2'
            sx={{
              color: '#292D3F',
              fontWeight: 'bold',
              marginBottom: '20px',
            }}
          >
            {balance !== 0 && t('modal_steps.complete')}
            {balance === 0 && t('modal_steps.not_enough')}
          </Typography>

          {balance !== 0 && (
            <>
              <TextField
                {...methods.register('email')}
                sx={TextFieldStyle}
                className={'TextFieldFull'}
                value={email}
                onChange={handleChangeEmail}
                disabled={!disabledEmail}
                variant='outlined'
                label={t('modal_steps.email')}
                error={emailError}
              />
              {emailError && (
                <Typography variant='subtitle1' sx={{ color: '#FF8663' }}>
                  {t('modal_steps.email_error')}
                </Typography>
              )}
              <Stack direction='row' alignItems='center'>
                <Checkbox
                  {...methods.register('emailChange')}
                  onChange={checkboxEmailChangeUpdate}
                  checked={checkboxEmailChange}
                />{' '}
                <Typography variant='subtitle2' sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>
                  {t('modal_steps.email_notifications')}
                </Typography>
              </Stack>

              <Stack direction='row' alignItems='center'>
                <Checkbox
                  {...methods.register('pushChange')}
                  onChange={checkboxPushChangeUpdate}
                  checked={checkboxPushChange}
                />
                <Typography variant='subtitle2' sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>
                  {t('modal_steps.push_notifications')}
                </Typography>
              </Stack>

              {postData ? (
                <Box sx={{ paddingTop: '27px' }}>
                  <LoadingSpinner />
                </Box>
              ) : (
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  gap={2}
                  sx={{ width: '100%' }}
                >
                  <ButtonInstance colorType='white' onClick={BackOpen} sx={ButtonWhiteStyle}>
                    <Typography variant='button'>{t('modal_steps.btn_back')}</Typography>
                  </ButtonInstance>
                  <ButtonInstance type='submit' sx={ButtonStyle} disabled={disabledButton}>
                    <Typography variant='button'>
                      {['token_buyer', 'insurance_buyer'].includes(modalType)
                        ? t('modal_steps.btn_buy')
                        : t('modal_steps.btn_apply')}
                    </Typography>
                  </ButtonInstance>
                </Stack>
              )}

              <Box
                sx={{
                  width: '100%',
                  borderTop: '1px solid rgba(41, 45, 63, 0.13)',
                  marginTop: '32px',
                }}
              >
                &nbsp;
              </Box>

              <Typography
                variant='subtitle1'
                sx={{
                  color: 'rgba(41, 45, 63, 0.72)',
                  marginBottom: '10px',
                  lineHeight: '18px',
                }}
              >
                <Box>{t('modal_steps.please_note')}</Box>
              </Typography>
            </>
          )}

          {balance === 0 && (
            <>
              <Typography variant='button'>{t('modal_steps.please_add')}</Typography>
              <ButtonInstance onClick={BackOpen} sx={ButtonWhiteStyle} colorType='white'>
                <Typography variant='button'>{t('modal_steps.go_back_btn')}</Typography>
              </ButtonInstance>
            </>
          )}
        </form>
      </FormProvider>
    </Box>
  );
}
