const API_URL = process.env.REACT_APP_API_URL || 'https://backend-new.dev.decentro.parametricx.io';
const PRICING_API_URL = process.env.REACT_APP_PRICING_API_URL || 'https://pricing.dev.decentro.parametricx.io';
const SOCKET_BC = process.env.REACT_APP_WEB_SOCKET_BC || 'wss://parachain-ws.dev.decentro.parametricx.io:443';
const SOCKET_UPD = process.env.REACT_APP_WEB_SOCKET_UPD || 'wss://backend-wss-upd-new.dev.decentro.parametricx.io:443';

const appEnv = (window as any)._env_?.APP_ENV;
const nodeEnv = (window as any)._env_?.NODE_ENV;

console.log('appEnv => ', appEnv);

console.log('nodeEnv => ', nodeEnv);

export const appConfig = {
  mode: appEnv || 'development',
  apiUrl: API_URL,
  pricingApiUrl: PRICING_API_URL,
  webSocket: SOCKET_BC,
  wsUpdateServices: SOCKET_UPD,
  USDT: 1984,
  USDT_DECIMAL_SCALE: 12,
};

export const pricingConfig = {
  threshold: 30,
  coverage: 1000,
  numberOfSimulations: 100000,
  ROC: 0.08,
} as const;
