import {
  Box,
  Checkbox,
  FormControlLabel,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { SortOrderType, TableSortType } from 'hooks/useTableSort';
import { ChangeEvent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { authStore } from 'store';

export type HeadCellAlign = 'inherit' | 'left' | 'center' | 'right' | 'justify';

export interface HeadCell<T extends object = any> {
  /**
   * The key id of the column using to sort
   */
  id: string;

  /**
   * The label to display in the header cell (i18n key)
   */
  label: string;

  /**
   * Whether the padding should be removed or not
   */
  disablePadding?: boolean;

  /**
   * The alignment of the header cell
   */
  align?: HeadCellAlign;

  /**
   * The type of sorting to use. Can be either 'desc' or 'asc'
   */
  sortType?: TableSortType;

  /**
   * Whether sorting should be disabled for this column
   */
  disabledSort?: boolean;

  /**
   * The max value of width (px) a HeadCell could have
   */
  maxWidth?: string;

  /**
   * A custom sorting function to use when sorting this column.
   * This function should take two values from the row and return a number indicating
   * their order.
   */
  onSort?: (a: T, b: T) => number;
}

interface Props {
  headCells: HeadCell[];
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: SortOrderType;
  orderBy: string;
  withSelect?: boolean;
  selectAll?: boolean;
  onSelectAll?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  tableCellProps?: TableCellProps;
  children?: ReactNode;
}

export const SortableTableHead = ({
  order,
  withSelect,
  onSelectAll,
  selectAll,
  orderBy,
  headCells,
  onRequestSort,
  tableCellProps,
  children,
}: Props) => {
  const createSortHandler = (property: string | number) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        {withSelect ? (
          <TableCell
            colSpan={1}
            key='checkboxes'
            align='center'
            sx={{
              color: 'rgba(41, 45, 63, 0.48)',
              lineHeight: '24px',
              padding: '13px 0px 13px 16px',
              textTransform: 'capitalize',
            }}
          >
            <FormControlLabel control={<Checkbox checked={selectAll} onChange={onSelectAll} />} label={''} />
          </TableCell>
        ) : null}
        {headCells.map((cell) => (
          <TableCell
            {...tableCellProps}
            key={cell.id}
            align={cell.align}
            padding={cell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === cell.id ? order : false}
            sx={{
              color: 'rgba(41, 45, 63, 0.48)',
              lineHeight: '24px',
              paddingTop: '16px',
              paddingBottom: '16px',
              ['&:first-of-type()']: {
                paddingLeft: '16px', // Add 16px padding to the first element
              },
              '&:last-of-type': {
                paddingRight: '16px', // Add 16px padding to the last element
              },
              [`&:not(:first-of-type()):not(:last-of-type)`]: {
                paddingLeft: '8px', // Add 8px padding to elements that are neither first nor last
                paddingRight: '8px',
              },
              textTransform: 'capitalize',
              whiteSpace: cell.sortType === 'date' ? 'nowrap' : 'normal',
              ...tableCellProps?.sx,
            }}
          >
            {cell.disabledSort ? (
              <Typography variant='h5'>{t(cell.label as any)}</Typography>
            ) : (
              <TableSortLabel
                active={orderBy === cell.id}
                direction={orderBy === cell.id ? order : 'asc'}
                onClick={createSortHandler(cell.id)}
                sx={{
                  width: 'fit-content',
                  maxWidth: cell.maxWidth || {
                    xs: '140px',
                    lg: '110px',
                  },
                }}
              >
                <Typography variant='h5'>{t(cell.label as any)}</Typography>
                {orderBy === cell.id ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
        {children}
      </TableRow>
    </TableHead>
  );
};
