import { makeAutoObservable } from 'mobx';

import { LSKeys } from 'constants/index';
import { addressFormat, getFromLS, removeFromLS, setToLS, showNotification } from 'utils';

import type { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';
import { walletApi } from 'api/ws';
import { web3Accounts } from '@polkadot/extension-dapp';

class WalletStore {
  walletAddress: string = getFromLS(LSKeys.WalletAddress, '');
  accounts: InjectedAccountWithMeta[] = getFromLS(LSKeys.Accounts, []);
  account: InjectedAccountWithMeta | Record<string, never> = getFromLS(LSKeys.Account, {});
  balance: string = getFromLS(LSKeys.Balance, '');
  nonce: string = getFromLS(LSKeys.Nonce, '');

  constructor() {
    makeAutoObservable(this);
  }

  setWallet(wallet: string) {
    this.walletAddress = wallet;
    setToLS(LSKeys.WalletAddress, wallet);
  }

  setAccounts(accounts: InjectedAccountWithMeta[]) {
    this.accounts = accounts;
    setToLS(LSKeys.Accounts, accounts);
  }

  setAccount(account: InjectedAccountWithMeta) {
    this.account = account;
    setToLS(LSKeys.Account, account);
  }

  async getAccounts() {
    const accounts = await web3Accounts();
    if (accounts.length) {
      this.setAccounts(accounts);
    } else {
      this.setAccounts([]);
      showNotification({
        title: 'Authorization Error',
        message: 'Please add a new account or change account visibility in your extension and try again',
      });
    }
  }

  async getBalances() {
    if (this.walletAddress) {
      try {
        const { balance, nonce } = await walletApi.getBalance(this.walletAddress);
        this.balance = balance;
        this.nonce = nonce;
        setToLS(LSKeys.Balance, balance);
        setToLS(LSKeys.Nonce, nonce);
      } catch (error) {
        let message = '';
        if (error instanceof Error) {
          message = error.message;
        } else {
          message = 'Get balances: Unknown error';
        }
        showNotification({ title: 'Error', message, type: 'warning' });
      }
    }
  }

  signOut() {
    if (this.walletAddress) {
      showNotification({ title: 'Sign out successful', message: `${addressFormat(this.walletAddress)}` });
    }
    this.accounts = [];
    this.account = {};
    this.walletAddress = '';
    this.balance = '';
    this.nonce = '';
    removeFromLS(LSKeys.WalletAddress);
    removeFromLS(LSKeys.Accounts);
    removeFromLS(LSKeys.Account);
    removeFromLS(LSKeys.Balance);
    removeFromLS(LSKeys.Nonce);
    removeFromLS(LSKeys.VotedArr);
  }
}

export const walletStore = new WalletStore();
