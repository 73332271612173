import React from 'react';
import styled from '@emotion/styled';
import { Box, LinearProgress, LinearProgressProps, Typography } from '@mui/material';

interface IVotesResultProps {
  approved: number;
  length: number;
  progress: number;
}

export const VotesResultView = ({ approved, length, progress }: IVotesResultProps) => {
  return (
    <Box sx={StyledContainer}>
      <Typography variant='button' sx={{ alignSelf: 'start' }}>{`${approved}/${length - approved}`}</Typography>
      <StyledLinearProgress variant='determinate' value={progress} />
    </Box>
  );
};

const StyledContainer = {
  marginLeft: 'auto',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100px',
} as React.CSSProperties;

const StyledLinearProgress = styled(LinearProgress)<LinearProgressProps>({
  height: '10px',
  width: '100px',
  '&.MuiLinearProgress-root': {
    backgroundColor: '#FF8663',
  },
  '&.MuiLinearProgress-root .MuiLinearProgress-bar': {
    backgroundColor: '#ADDC7B',
  },
});
