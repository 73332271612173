import FAQ from 'components/FAQ';
import Slide from 'components/Slide';
import StaySafe from 'components/StaySafe';
import Why from 'components/Why';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import FilterSortByButtons, { useInsuranceSort } from 'features/home/components/InsuranceSort';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import PRMXLiquidityProvider from './components/PRMXLiquidityProvider';
import { Box, Typography } from '@mui/material';
import PRMXBuyer from './components/PRMXBuyer';

export const HomePage = observer(() => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [activeTab, setActive] = useState(0);
  const sorter = useInsuranceSort();

  return (
    <>
      <Slide />
      <Why />
      <Box className={classes.insuranceStyle}>
        <Typography
          variant='h2'
          fontWeight={700}
          sx={{
            fontFamily: 'Questrial',
            color: '#292D3F',
            margin: '30px 0 10px',
            padding: { xs: '0 24px', xl: '0 12px' },
          }}
        >
          {t('insurance.select_insurance')}
        </Typography>
        <Tabs selectedIndex={activeTab} onSelect={setActive}>
          <Box
            sx={{
              m: '0px 10px',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              position: 'relative',
              marginBottom: {
                xs: activeTab === 1 ? '40px' : 0,
                md: activeTab === 1 ? '20px' : 0,
                lg: 0,
              },
            }}
          >
            <TabList className={classes.insuranceTabsStyle}>
              <Tab className={classes.insuranceTabStyle}>
                <Typography variant='h5' sx={{ fontFamily: 'Questrial', fontWeight: 'bold !important' }}>
                  {t('insurance.prmx_buyers')}
                </Typography>
              </Tab>
              <Tab className={classes.insuranceTabStyle}>
                <Typography variant='h5' sx={{ fontFamily: 'Questrial', fontWeight: 'bold !important' }}>
                  {t('insurance.prmx_liquidity')}
                </Typography>
              </Tab>
            </TabList>
            <Box
              sx={{
                position: {
                  xs: 'absolute',
                  md: 'relative',
                  lg: 'absolute',
                },
                top: {
                  xs: '45px',
                  md: '0px',
                  lg: '5px',
                },
                right: 0,
                width: {
                  xs: '100%',
                  md: 'fit-content',
                },
                display: {
                  xs: 'flex',
                  md: 'block',
                },
                justifyContent: {
                  xs: 'center',
                },
              }}
            >
              {activeTab === 1 ? <FilterSortByButtons {...sorter} /> : null}
            </Box>
          </Box>

          <TabPanel key={1}>
            <PRMXBuyer />
          </TabPanel>

          <TabPanel key={2}>
            <PRMXLiquidityProvider orderBy={sorter.orderBy} sortBy={sorter.sortBy} />
          </TabPanel>
        </Tabs>
      </Box>
      <FAQ />
      <StaySafe />
    </>
  );
});
