import { appConfig } from 'config';
import { autorun } from 'mobx';
import { useEffect } from 'react';
import useWebSocket, { type Options } from 'react-use-websocket';
import { authStore } from 'store';
import { showNotification } from 'utils';
import useObserverAccessToken from './useObserverAccessToken';

interface UseAuthWs extends Options {
  url?: string;
}

const useAuthWs = ({ url = appConfig.wsUpdateServices, ...options }: UseAuthWs) => {
  const accessToken = useObserverAccessToken();

  const handleError = () => {
    showNotification({ title: 'Connection failed', message: 'Please refresh the page', type: 'warning' });
  };

  const socket = useWebSocket(
    url,
    {
      share: true,
      onError: handleError,
      retryOnError: true,
      shouldReconnect: () => !!accessToken,
      reconnectInterval: 3000,
      reconnectAttempts: 10,
      protocols: accessToken,
      ...options,
    },
    !!accessToken,
  );

  useEffect(() => {
    if (!accessToken) socket.getWebSocket()?.close();
  }, [!accessToken]);

  return socket;
};

export default useAuthWs;
