export enum LSKeys {
  WalletAddress = 'walletAddress',
  AccessToken = 'accessToken',
  RefreshToken = 'refreshToken',
  Account = 'account',
  Accounts = 'accounts',
  Balance = 'balance',
  Nonce = 'nonce',
  Language = 'i18nextLng',
  VotedArr = 'voted',
}
