import { appConfig } from '../config';
import { ITablesResponseBody, ITablesActiveRequestBody, ITablesRequestBody } from '../types';

import { API } from './API';

export function getDAOHistory(params: ITablesRequestBody): Promise<ITablesResponseBody> {
  return API.get<ITablesResponseBody>(`${appConfig.apiUrl}/insurance/voting/history`, {
    params,
    isPublic: false,
  }).then((response) => response.data);
}

export function getDAOActive(params?: ITablesActiveRequestBody): Promise<ITablesResponseBody> {
  return API.get<ITablesResponseBody>(`${appConfig.apiUrl}/insurance/voting`, {
    params,
    isPublic: false,
  }).then((response) => response.data);
}
