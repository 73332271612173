import React, { useMemo } from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getAdminStats } from 'api';
import { StatsType } from 'types';
import { formatNumber } from 'utils';
import { Box, Skeleton, styled, useMediaQuery } from '@mui/material';
import { useFetch } from 'hooks/useFetch';
import { Loader } from 'components';
import { useTranslation } from 'react-i18next';

const initialValue = {
  activeInsurances: 0,
  activePremium: 0,
  activeUnderwrite: 0,
  concludedInsurances: 0,
  concludedPremium: 0,
  concludedUnderwrite: 0,
};

export const AdminStatsView = () => {
  const { t } = useTranslation();
  const { loading, response } = useFetch(getAdminStats, initialValue);
  const { active, concluded } = useMemo(() => {
    const formattedData = Object.entries(response) as StatsType;

    return formattedData.reduce(
      (acc, [name, value]) => {
        const flatWord = t(`admin.${name}`);

        if (name.includes('active')) {
          return { ...acc, active: [...acc.active, [flatWord, value] as [string, number]] };
        } else {
          return { ...acc, concluded: [...acc.concluded, [flatWord, value] as [string, number]] };
        }
      },
      { active: [] as [string, number][], concluded: [] as [string, number][] },
    );
  }, [response, t]);

  const formatOutputValues = (name: string, value: number) => {
    const formatted = formatNumber(value);
    return name.toLocaleLowerCase().match(/underwrite|premium/) ? `${formatted} USDT` : formatted;
  };

  const calculatePercent = (concludedValue: number, activeValue: number) => {
    const percent = (concludedValue / activeValue) * 100 || 0;
    const color = percent >= 0 ? '#ADDC7B' : '#FF8663';

    return { percent: percent.toFixed(2), color };
  };

  const matchesMd = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <StyledTypography variant='h1'>Stats</StyledTypography>
      <Box sx={{ width: '100%' }}>
        <TableContainer component={Paper} sx={{ maxWidth: '100%', borderRadius: '12px', overflowX: 'hidden' }}>
          <Table sx={{ width: '100%' }}>
            <TableBody>
              {matchesMd ? (
                <>
                  <TableRow>
                    {active.map(([name, value]) => {
                      const outputValue = formatOutputValues(name, value);
                      return (
                        <StyledTableCell
                          key={name}
                          sx={{
                            padding: { xs: '10px 16px', xl: '20px 32px' },
                            textAlign: { xs: 'center', xl: 'left' },
                          }}
                        >
                          <StyledTableTitle
                            variant='h3'
                            sx={{ fontSize: { md: '1rem !important', xl: '1.25rem !important' } }}
                          >
                            {name}
                          </StyledTableTitle>
                          {loading ? (
                            <Skeleton animation='wave' width='full' height='45px'></Skeleton>
                          ) : (
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                flexDirection: { xs: 'column', xl: 'row' },
                              }}
                            >
                              <StyledTableMessage variant='h2' sx={{ lineHeight: 1.25 }}>
                                {outputValue}
                              </StyledTableMessage>
                              <StyledTablePercent variant='h4'>&nbsp;</StyledTablePercent>
                            </Box>
                          )}
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                  <TableRow>
                    {concluded.map(([name, value], ind) => {
                      const outputValue = formatOutputValues(name, value);
                      const { percent, color } = calculatePercent(value, active[ind][1]);

                      return (
                        <StyledTableCell
                          key={name}
                          sx={{
                            padding: { xs: '10px 16px', xl: '20px 32px' },
                            textAlign: { xs: 'center', xl: 'left' },
                          }}
                        >
                          <StyledTableTitle
                            variant='h3'
                            sx={{ fontSize: { md: '1rem !important', xl: '1.25rem !important' } }}
                          >
                            {name}
                          </StyledTableTitle>
                          {loading ? (
                            <Skeleton animation='wave' width='full' height='45px'></Skeleton>
                          ) : (
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                flexDirection: { xs: 'column', xl: 'row' },
                              }}
                            >
                              <StyledTableMessage variant='h2' sx={{ lineHeight: 1.25 }}>
                                {outputValue}
                              </StyledTableMessage>
                              <StyledTablePercent variant='h4' sx={{ color }}>
                                {percent}%
                              </StyledTablePercent>
                            </Box>
                          )}
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                </>
              ) : (
                <>
                  {Array.from({ length: 3 }).map((_, index) => {
                    {
                      const concludedOutputValue = formatOutputValues(concluded[index][0], concluded[index][1]);
                      const { percent, color } = calculatePercent(concluded[index][1], active[index][1]);
                      return (
                        <TableRow key={index}>
                          <StyledTableCell
                            key={active[index][0]}
                            sx={{
                              padding: { xs: '10px 16px', xl: '20px 32px' },
                              textAlign: { xs: 'center', xl: 'left' },
                            }}
                          >
                            <StyledTableTitle
                              variant='h4'
                              sx={{
                                fontSize: { xs: '0.75rem !important', sm: '0.875rem !important' },
                              }}
                            >
                              {active[index][0]}
                            </StyledTableTitle>
                            {loading ? (
                              <Skeleton animation='wave' width='full' height='45px'></Skeleton>
                            ) : (
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  flexDirection: { xs: 'column', xl: 'row' },
                                }}
                              >
                                <StyledTableMessage variant='h2' sx={{ lineHeight: 1.25 }}>
                                  {formatOutputValues(active[index][0], active[index][1])}
                                </StyledTableMessage>
                                <StyledTablePercent variant='h4'>&nbsp;</StyledTablePercent>
                              </Box>
                            )}
                          </StyledTableCell>
                          <StyledTableCell
                            key={concluded[index][0]}
                            sx={{
                              padding: {
                                xs: '10px 16px',
                                xl: '20px 32px',
                              },
                              textAlign: {
                                xs: 'center',
                                xl: 'left',
                              },
                            }}
                          >
                            <StyledTableTitle
                              variant='h4'
                              sx={{ fontSize: { xs: '0.75rem !important', sm: '0.875rem !important' } }}
                            >
                              {concluded[index][0]}
                            </StyledTableTitle>
                            {loading ? (
                              <Skeleton animation='wave' width='full' height='45px'></Skeleton>
                            ) : (
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  flexDirection: { xs: 'column', xl: 'row' },
                                }}
                              >
                                <StyledTableMessage variant='h2' sx={{ lineHeight: 1.25 }}>
                                  {concludedOutputValue}
                                </StyledTableMessage>
                                <StyledTablePercent variant='h4' sx={{ color }}>
                                  {percent}%
                                </StyledTablePercent>
                              </Box>
                            )}
                          </StyledTableCell>
                        </TableRow>
                      );
                    }
                  })}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

const StyledTypography = styled(Typography)<TypographyProps>({
  width: 'fit-content',
  margin: '84px 0 24px',
  fontFamily: 'Poppins',
});

const StyledTableCell = styled(TableCell)<React.CSSProperties>({
  border: '1px solid rgba(41, 45, 63, 0.13)',
  width: '33.33%',
});

const StyledTableTitle = styled(Typography)<TypographyProps>({
  margin: '0 0 8px 0',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '32px',
  color: 'rgba(41, 45, 63, 0.48)',
  textTransform: 'lowercase',
  whiteSpace: 'nowrap',
  [`&:first-letter`]: {
    textTransform: 'uppercase',
  },
});

const StyledTableMessage = styled(Typography)<TypographyProps>(({ theme }) => ({
  lineHeight: 1.25,
  color: '#292D3F',
  fontWeight: 700,
  [theme.breakpoints.down('sm')]: {
    minHeight: 60,
  },
}));

const StyledTablePercent = styled(Typography)<TypographyProps>({
  lineHeight: 1.25,
  color: '#292D3F',
});
