import { useTranslation } from 'react-i18next';
import logoImg from '../img/logo.png';
import { NavLink, useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

const footerStyle = {
  backgroundColor: '#E6F0FD',
  height: {
    xs: '125px',
    md: '250px',
  },
  // minWidth: '800px',
  width: '100%',
};

const footerBoxStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 8%',
  height: '100%',
};

const footerTextStyle = {
  color: '#3555C8',
};

export const Footer = () => {
  const { t } = useTranslation();

  const location = useLocation();

  return location.pathname !== '/admin' ? (
    <Box component='footer' sx={footerStyle}>
      <Box sx={footerBoxStyle}>
        <Box>
          <NavLink to='/'>
            <Box
              component='img'
              sx={{
                width: 'auto',
                maxWidth: '100%',
                height: 'auto',
                padding: 0,
                margin: 0,
              }}
              src={logoImg}
              alt='slider'
            />
          </NavLink>
        </Box>
        <Typography variant='h4' sx={footerTextStyle}>
          {t('footer.decentro')}
        </Typography>
      </Box>
    </Box>
  ) : (
    <></>
  );
};

export default Footer;
