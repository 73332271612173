import * as React from 'react';
import Box from '@mui/material/Box';
import { useState } from 'react';
import Button from '@mui/material/Button';
import PutOnSaleStep1, { FormData } from './PutOnSaleStep1';
import PutOnSaleStep2, { FormDataStep2 } from './PutOnSaleStep2';
import PutOnSaleStep3 from './PutOnSaleStep3';
import { useTranslation } from 'react-i18next';
import { ModalInstance } from './modals';
import { Typography } from '@mui/material';

const getCardButtonStyle = ({ status, amount }: { status: string; amount: number }) => ({
  fontFamily: 'Questrial',
  border:
    (status === 'Active' || status === 'Approved') && amount > 0
      ? '2px solid #3555C8'
      : '2px solid rgba(41, 45, 63, 0.3)',
  borderRadius: '12px',
  backgroundColor: (status === 'Active' || status === 'Approved') && amount > 0 ? '#3555C8' : 'rgba(41, 45, 63, 0.3)',
  justifyContent: 'center',
  padding: '4px 6px',
  width: 'auto',
  color: 'white',
  textTransform: 'none !important',
});

export interface IDataType {
  status: string;
  amount: number;
  country: string;
  event: string;
  price: number;
  smtId: number;
  available: string;
  startTime: number;
  expireTime: number;
}

interface Props {
  data: IDataType;
  updateLPT: () => void;
  autoUpdate: (status: boolean) => void;
}

export default function PutOnSaleModalForm({ data, updateLPT, autoUpdate }: Props) {
  const cardButtonStyle = getCardButtonStyle({
    status: data.status,
    amount: data.amount,
  });

  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const [formValues, setFormValues] = useState<FormData | FormDataStep2>();
  const [postData, setPostData] = useState(false);

  const handleOpen = async () => {
    setOpen(true);
    if (autoUpdate) {
      autoUpdate(false);
    }
  };

  const handleClose = () => {
    if (step === 2 && postData) return;
    setStep(1);
    setOpen(false);
    if (autoUpdate) {
      autoUpdate(true);
    }
  };

  return (
    <>
      <Button
        sx={{ ...cardButtonStyle, whiteSpace: 'nowrap' }}
        onClick={handleOpen}
        key='Put on Sale'
        disabled={(data.status === 'Active' || data.status === 'Approved') && data.amount > 0 ? false : true}
      >
        <Typography variant='subtitle1'>{t('put_on_sale.put_on_sale')}</Typography>
      </Button>

      <ModalInstance isOpen={open} close={handleClose}>
        <Box
          sx={{
            width: '100%',
            maxHeight: '100vh',
            overflowY: 'auto',
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {step === 1 && (
            <PutOnSaleStep1
              setStep={setStep}
              formValues={formValues as FormData}
              setFormValues={setFormValues as React.Dispatch<React.SetStateAction<NonNullable<FormData>>>}
              closeModal={handleClose}
              data={data}
            />
          )}

          {step === 2 && (
            <PutOnSaleStep2
              setStep={setStep}
              formValues={formValues as FormDataStep2}
              setFormValues={setFormValues as React.Dispatch<React.SetStateAction<NonNullable<FormDataStep2>>>}
              closeModal={handleClose}
              data={data}
              postData={postData}
              setPostData={setPostData}
            />
          )}
          {step === 3 && (
            <PutOnSaleStep3
              setStep={setStep}
              formValues={formValues}
              setFormValues={setFormValues}
              closeModal={handleClose}
              updateLPT={updateLPT}
            />
          )}
        </Box>
      </ModalInstance>
    </>
  );
}
