import { authStore, walletStore } from 'store';
import { countryOptions } from './CountryOptions';
import axios from 'axios';
import { dateFormat } from 'utils';
import { appConfig } from '../config';

const getCountry = (value: string) => {
  const options = countryOptions.filter((countryData) => {
    return countryData.value === value;
  });

  return options[0]?.label ? options[0]?.label : '';
};

export default async function GetInsurances() {
  const insurances = [];
  let i = 1;

  const admin = authStore.isAdmin;
  let response;

  try {
    if (admin) {
      response = await axios.get(`${appConfig.apiUrl}/insurance?status=Active&pageSize=10000`, {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      });
    } else {
      response = await axios.get(`${appConfig.apiUrl}/insurance/holder/${walletStore.walletAddress}?pageSize=10000`, {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      });
    }

    if (response.data.total > 0) {
      for (const row of response.data.results) {
        insurances.push({
          id: i++,
          name: row.risk,
          location: getCountry(row.location),
          mint: dateFormat(row.startTime),
          expiration: dateFormat(row.endTime),
          comission: `${row.premium} USDT`,
          underwrite: `${row.underwrite} USDT`,
          event: row.status === 'Occurred' ? 'Paid Out' : row.status,
          smtId: row.smtId,
          trueId: row.id,
        });
      }
    }
  } catch (error) {
    console.log('Missing data for Insurances table', error);
  }

  return insurances;
}
