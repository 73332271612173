import { makeAutoObservable } from 'mobx';
import { LSKeys } from 'constants/index';
import { addressFormat, getFromLS, removeFromLS, setToLS, showNotification } from 'utils';
import { refreshAccessTokenReq, signIn } from 'api';

import { web3FromSource } from '@polkadot/extension-dapp';
import { stringToHex } from '@polkadot/util';
import jwt_decode from 'jwt-decode';
import { IDecoded } from 'types';
import type { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';
import { walletStore } from './wallet';
import { errorHandle } from 'utils/errorHandle';

class AuthStore {
  accessToken: string = getFromLS(LSKeys.AccessToken, '');
  refreshToken: string = getFromLS(LSKeys.RefreshToken, '');

  constructor() {
    makeAutoObservable(this);
  }

  get isDao(): boolean {
    try {
      const decoded: IDecoded = jwt_decode(this.accessToken);
      return decoded.user.role.toLowerCase() === 'dao';
    } catch (error) {
      return false;
    }
  }

  get isAdmin(): boolean {
    try {
      const decoded: IDecoded = jwt_decode(this.accessToken);
      return decoded.user.role.toLowerCase() === 'admin';
    } catch (error) {
      return false;
    }
  }

  get isAuthorized(): boolean {
    return Boolean(this.accessToken) && Boolean(walletStore.walletAddress);
    // && walletStore.connectedAccount === this.accountAddress;
  }

  async signIn(account: InjectedAccountWithMeta) {
    if (!this.isAuthorized) {
      await this.signInWithSignature(account);
    }
  }

  async refreshAccessToken() {
    const { accessToken, refreshToken } = await refreshAccessTokenReq({
      refreshToken: this.refreshToken,
    });
    this.setTokens(accessToken, refreshToken);
  }

  setTokens(access: string, refresh: string) {
    this.accessToken = access;
    this.refreshToken = refresh;
    setToLS(LSKeys.AccessToken, access);
    setToLS(LSKeys.RefreshToken, refresh);
  }

  signOut() {
    this.accessToken = '';
    this.refreshToken = '';
    removeFromLS(LSKeys.AccessToken);
    removeFromLS(LSKeys.RefreshToken);
    walletStore.signOut();
  }

  private async signInWithSignature(account: InjectedAccountWithMeta) {
    try {
      const walletAddress = account.address;
      const injector = await web3FromSource(account.meta.source);
      const signRaw = injector?.signer?.signRaw;
      if (signRaw) {
        const { signature } = await signRaw({
          address: walletAddress,
          data: stringToHex('cdfd4FKD845845rASHCHDFHeru4856'),
          type: 'bytes',
        });
        const { accessToken, refreshToken } = await signIn({
          walletAddress,
          message: 'cdfd4FKD845845rASHCHDFHeru4856',
          signedMessage: signature,
        });
        walletStore.setWallet(walletAddress);
        await walletStore.getBalances();
        walletStore.setAccount(account);
        this.setTokens(accessToken, refreshToken);

        showNotification({ title: 'SignIn successful', message: `${addressFormat(walletAddress)}` });
      }
    } catch (err) {
      errorHandle(err);
      this.signOut();
    }
  }
}

export const authStore = new AuthStore();
