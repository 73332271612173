import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  cardsWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '14px 32px 14px 52px',
    borderBottom: '1px solid #EDEDF8',

    // [theme.breakpoints.down('xl')]: {
    //   padding: '30px',
    // },

    // [theme.breakpoints.down('md')]: {
    //   padding: '20px',
    // },
  },
  table: {
    width: '100%',
    height: 'calc(100% - 144px)',
    // minHeight: 'calc(100% - 144px)',
    padding: '14px 32px 38px 54px',

    // [theme.breakpoints.down('xl')]: {
    //   padding: '30px',
    // },

    // [theme.breakpoints.down('md')]: {
    //   padding: '20px',
    //   height: '100%',
    // },
  },
  cardStyles: {
    height: '100%',
    padding: '20px 12px 38px',
  },
  cardTitleStyles: {
    marginBottom: 30,
  },
  searchInput: {
    // [theme.breakpoints.down('md')]: {
    //   height: 28,
    //   fontSize: '13px',
    //   lineHeight: '14px',
    // },
    maxWidth: '100%',
    width: '350px',
  },
  closeBtn: {
    color: '#B4B4B5',
    fontSize: 30,
    fontFamily: 'Matter, Arial, sans-serif',
    fontWeight: 500,
    position: 'absolute',
    right: 33,
    top: 15,
  },
  title: {
    color: '#000',
    fontSize: 36,
    fontFamily: 'Matter, Arial, sans-serif',
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 25,
    fontWeight: 500,
    padding: '41px 32px 35px 32px',
    borderBottom: '1px solid #CFCFE4',
  },
  tableTopBar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 50,
    paddingBottom: 22,
  },
  ordersTitle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 50,
    paddingBottom: 22,
  },
  btnWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    maxWidth: '730px',
    padding: '0 44px 80px 0',
  },
  btn: {
    width: '30px',
    height: '30px',
    background: '#9CBFCA',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    border: '1px solid #D9D9D9',
    borderRadius: '100%',
    fontFamily: 'Matter, Arial, sans-serif',
    fontWeight: 500,
    fontSize: 30,
    lineHeight: '18px',
    color: '#fff',
    textTransform: 'capitalize',

    '&:hover': {
      background: 'rgba(156, 191, 202, 0.9)',
    },
  },

  breadcrumbs: {
    fontFamily: 'Questrial',
    fontSize: '16px',
    paddingBottom: '16px',
  },

  titleStyle: {
    fontFamily: 'Questrial',
    fontWeight: 'bold',
    fontSize: '34px !important',
  },

  tableTitleStyle: {
    fontFamily: 'Questrial',
    fontWeight: 'bold',
    fontSize: '25px',
    padding: '20px 0px 20px 0px',
    marginTop: '20px',
  },

  tableStyle: {
    maxWidth: '1200px',
    width: '100%',
    margin: '100px auto 0 auto',
  },
}));
