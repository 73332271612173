import React from 'react';

const useForceUpdate = () => {
  const [dependency, setDependency] = React.useState(false);
  const forceUpdate = () => setDependency(!dependency);
  return {
    dependency,
    forceUpdate,
  };
};

export default useForceUpdate;
