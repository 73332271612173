import { Grid, Box, Skeleton } from '@mui/material';
import React from 'react';

const InsuranceSkeleton = () => {
  return (
    <Box style={{ padding: '10px 10px', display: 'flex', justifyContent: 'center' }}>
      <Grid container justifyContent='center' spacing={3} width={{ lg: 1200, md: 900, sm: 400 }}>
        {Array.from({ length: 6 }).map((item, index) => (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key={index}>
            <Box sx={{ width: '100%', maxWidth: '378px', marginX: 'auto' }}>
              <Skeleton sx={{ borderRadius: 4 }} variant='rectangular' width='100%' height={150} />
              <Box sx={{ py: 0.5 }}>
                <Skeleton height={50} />
                <Skeleton height={50} />
              </Box>
              <Skeleton sx={{ borderRadius: 1 }} variant='rectangular' height={50} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default InsuranceSkeleton;
