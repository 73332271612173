import {
  getPRMXBuyers,
  getPRMXLiquidityProviders,
  getPRMXOrders,
  IPaginationResponse,
  type IGetPRMXBuyersResponseData,
  type IInsuranceRequestParams,
  type IUnifiedMarketCardData,
} from 'api/insurances';
import { createInfiniteQuery } from 'react-query-kit';

export const INSURANCE_PAGE_SIZE = 40;

export const useGetPRMXBuyers = createInfiniteQuery<
  IPaginationResponse<IGetPRMXBuyersResponseData>,
  IInsuranceRequestParams
>({
  queryKey: ['insurance, buyers'],
  fetcher: (variables, { pageParam }) => getPRMXBuyers({ ...variables, page: pageParam }),
  getNextPageParam: (lastPage, pages) => {
    const totalPage = Math.ceil(lastPage.total / INSURANCE_PAGE_SIZE);
    const currentPage = pages.length;
    return currentPage < totalPage ? currentPage + 1 : undefined;
  },
  initialPageParam: 1,
});

export const useGetPRMXLiquidityProviders = createInfiniteQuery<
  IPaginationResponse<IUnifiedMarketCardData>,
  IInsuranceRequestParams
>({
  queryKey: ['insurance, lps'],
  fetcher: (variables, { pageParam }) => getPRMXLiquidityProviders({ ...variables, page: pageParam }),
  getNextPageParam: (lastPage, pages) => {
    const totalPage = Math.ceil(lastPage.total / INSURANCE_PAGE_SIZE);
    const currentPage = pages.length;
    return currentPage < totalPage ? currentPage + 1 : undefined;
  },
  initialPageParam: 1,
});

export const useGetPRMXOrders = createInfiniteQuery<
  IPaginationResponse<IUnifiedMarketCardData>,
  IInsuranceRequestParams
>({
  queryKey: ['insurance, orders'],
  fetcher: (variables, { pageParam }) => getPRMXOrders({ ...variables, page: pageParam }),
  getNextPageParam: (lastPage, pages) => {
    const totalPage = Math.ceil(lastPage.total / INSURANCE_PAGE_SIZE);
    const currentPage = pages.length;
    return currentPage < totalPage ? currentPage + 1 : undefined;
  },
  initialPageParam: 1,
});
