import classNames from 'classnames';
import 'font-awesome/css/font-awesome.min.css';
import { Box } from '@mui/material';

interface Props {
  loading?: boolean;
  size?: number;
}

const LoadingSpinner = ({ loading = true, size = 36 }: Props) => (
  <Box className={classNames('-loading', { 'active -active': loading })}>
    <Box id='spinner' sx={{ top: '50%', textAlign: 'center' }}>
      <i className='fa fa-spinner fa-pulse fa-3x fa-fw' style={{ fontSize: size, color: 'rgb(53, 85, 200)' }} />
    </Box>
  </Box>
);

export default LoadingSpinner;
