import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export const addressFormat = (str = '', start = 4, end = 4) => {
  if (str.indexOf('0x') === 0) {
    str = str.slice(2).toUpperCase();
  }
  return str.length > 9 ? `0x${str.slice(0, start)}...${str.slice(-end)}` : str;
};

//ToDo Remove HH:mm in production. It is only for test
export const dateFormat = (date?: string | number | Date | dayjs.Dayjs, template?: string) => {
  return dayjs(date).format(template || 'MM/DD/YYYY HH:mm');
};

export const pdfFormat = (date?: string | number | Date | dayjs.Dayjs) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  return dayjs.tz(date, dayjs.tz.guess()).toISOString();
};

export const getTimezone = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  return dayjs.tz.guess();
};
