import React from 'react';
import TablePagination, { TablePaginationBaseProps } from '@mui/material/TablePagination';
import { styled } from '@mui/material';
import { ROWS_PER_PAGE_OPTIONS } from 'constants/index';
import { useTranslation } from 'react-i18next';

interface IProps {
  count: number;
  setRowsPerPage: (a: number) => void;
  setPage: (a: number) => void;
  page: number;
  rowsPerPage: number;
}

export const DaoPagination = ({ count, setRowsPerPage, setPage, page, rowsPerPage }: IProps) => {
  const { t } = useTranslation();
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <StyledTablePagination
      rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
      component='div'
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelRowsPerPage={t('pagination.per_page')}
    />
  );
};

interface IStyledTablePagination extends TablePaginationBaseProps {
  component: string;
}

const StyledTablePagination = styled(TablePagination)<IStyledTablePagination>({
  color: 'rgba(41, 45, 63, 0.48)',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '24px',
  textTransform: 'capitalize',
  overflowX: 'hidden',
});
