import { Box } from '@mui/material';
import { ButtonInstance } from 'components';
import SuccessfullyIMG from 'img/sucessfully.png';

interface IProps {
  close: () => void;
}

export const VoteSuccessBannerView = ({ close }: IProps) => {
  return (
    <Box sx={containerStyle}>
      <Box
        sx={{
          textAlign: 'center',
          marginBottom: '20px',
        }}
      >
        <Box component='img' alt='Filters' src={SuccessfullyIMG} sx={{ paddingRight: '10px' }} />
      </Box>
      <Box sx={titleStyle}>Request sent successfully</Box>

      <Box>
        <ButtonInstance title='Finish' onClick={close} style={{ width: '100%' }} />
      </Box>
    </Box>
  );
};

const containerStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '24px',
  color: 'rgba(41, 45, 63, 0.72)',
};

const titleStyle = {
  fontSize: '28px',
  color: '#292D3F',
  fontWeight: 'bold',
  marginBottom: '20px',
  textAlign: 'center',
} as React.CSSProperties;
