import { useState, useEffect, useCallback } from 'react';
import { errorHandle } from 'utils/errorHandle';

export const useFetch = <T,>(
  callback: () => Promise<T>,
  initialValue: T,
  dependencies: Array<unknown> = [],
  autoRetry = false,
  retryTimer = 5000,
  hideAutoRetry = true,
) => {
  const [response, setResponse] = useState<T>(initialValue);
  const [error, setError] = useState<unknown>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [retryTrigger, setRetryTrigger] = useState<boolean>(false);
  const [updateTrigger, setUpdate] = useState<boolean>(autoRetry);

  const retry = useCallback(() => {
    setRetryTrigger(!retryTrigger);
  }, [retryTrigger]);

  const autoUpdate = useCallback((isUpdate: boolean) => {
    setUpdate(isUpdate);
  }, []);

  const fetchData = useCallback(async (signal: AbortSignal) => {
    if (!hideAutoRetry) {
      setLoading(true);
    }
    try {
      const res = await callback();
      if (!signal.aborted) {
        setResponse(res);
      }
    } catch (error) {
      if (!signal.aborted) {
        setError(error);
        errorHandle(error);
      }
    } finally {
      if (!signal.aborted) {
        setLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    fetchData(signal);

    return () => {
      abortController.abort();
    };
  }, [retryTrigger, ...dependencies]);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    let timer: ReturnType<typeof setInterval>;

    if (updateTrigger) {
      timer = setInterval(fetchData, retryTimer, signal);
    }

    return () => {
      abortController.abort();
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [updateTrigger, ...dependencies]);

  return { response, error, loading, retry, autoUpdate };
};
