import { externalLinks } from 'constants/index';
import { ButtonInstance } from './ButtonInstance';

export const ButtonInstallPolkadot = () => {
  return (
    <ButtonInstance
      title='polkadot install'
      style={{ width: '100%', fontSize: '14px', padding: '8px' }}
      onClick={() => {
        window.open(externalLinks.polkadotExtensionInstall, '_blank');
      }}
    />
  );
};
