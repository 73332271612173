import { LSKeys } from 'constants/index';
import { ApiPromise, WsProvider } from '@polkadot/api';
import '@polkadot/api-augment';
import { web3Enable, web3FromAddress } from '@polkadot/extension-dapp';
import { appConfig } from 'config';
import { walletStore } from 'store';
import { VoteCallbackType } from 'types';
import { showNotification, setToLS } from 'utils';
import { throttle } from 'utils/throttle';

export const getWSProvider = () => new WsProvider(appConfig.webSocket);

const [throttleNotification] = throttle(showNotification, 750);

const errorHandler = (err: unknown) => {
  if (err instanceof Error) {
    showNotification({ title: 'PLEASE REFRESH PAGE', message: err.message, type: 'warning' });
  } else {
    throttleNotification({ title: 'PLEASE REFRESH PAGE', message: 'Unknown Error', type: 'warning' });
  }
};

export const createApi = async () => {
  const provider = getWSProvider();
  const api = new ApiPromise({ provider });
  api.on('error', errorHandler);
  await api.isReady;
  return api;
};

export const daoApi = {
  api: null as ApiPromise | null,
  unsubscribe: null as (() => void) | null,
  async insuranceVote(proposalHash: string, index: string, decision: boolean, callBack: VoteCallbackType) {
    this.api = await createApi();
    await web3Enable('decentro.parametricx.io');
    const { signer } = await web3FromAddress(walletStore.walletAddress);

    this.unsubscribe = await this.api?.tx.dao
      .vote(proposalHash, index, decision)
      .signAndSend(walletStore.walletAddress, { signer }, async (res) => {
        const { isCompleted } = res;
        if (isCompleted) {
          callBack((prevState) => {
            const newState = [...prevState, { isCompleted, index }];
            setToLS(LSKeys.VotedArr, newState);
            return newState;
          });
          this.unsubscribe && this.unsubscribe();
          await this.close();
        }
      });
  },
  async close() {
    if (this.api) {
      await this.api.disconnect();
      this.api = null;
    }
  },

  subscribeCancel() {
    if (this.unsubscribe) {
      this.unsubscribe();
      this.unsubscribe = null;
    }
  },
};

export const walletApi = {
  api: null as ApiPromise | null,
  async getBalance(wallet: string): Promise<{ balance: string; nonce: string }> {
    this.api = await createApi();

    const { nonce: previousNonce } = await this.api.query.system.account(wallet);
    const response = await this.api.query.assets.account(appConfig.USDT, wallet);

    const balance = response.value.get('balance')?.toString() ?? '0';

    return { balance: balance.toString(), nonce: previousNonce.toString() };
  },
  async close() {
    if (this.api) {
      await this.api.disconnect();
    }
  },
};
