import { Button, Typography } from '@mui/material';
import React from 'react';

interface EventStyles {
  border: string;
  backgroundColor: string;
  color: string;
}

export interface EventStylesConfig {
  [key: string]: EventStyles;
}

interface StatusLabelProps {
  event: string;
  eventStyles: EventStyles;
}

export const StatusLabel: React.FC<StatusLabelProps> = ({ event, eventStyles }) => {
  return (
    <Button
      style={{
        display: 'inline-block',
        borderRadius: '12px',
        ...eventStyles,
        justifyContent: 'center',
        padding: '0px 8px',
        width: 'auto',
        cursor: 'default',
      }}
      key='Claim'
      color='inherit'
    >
      <Typography variant='h6'>{event}</Typography>
    </Button>
  );
};

// COLORS TAKEN FROM https://m2.material.io/design/color/the-color-system.html#tools-for-picking-colors
const defaultConfig: EventStylesConfig = {
  Approved: {
    border: '2px solid #2b8cb6',
    backgroundColor: '#359ec8',
    color: '#fff',
  },
  Active: {
    border: '2px solid #3555C8',
    backgroundColor: '#576fd1',
    color: '#FFFFFF',
  },
  'Paid Out': {
    border: '2px solid #1da51d',
    backgroundColor: '#3ac835',
    color: '#fff',
  },
  Expired: {
    border: '2px solid #A9A9A9',
    backgroundColor: '#D3D3D3',
    color: '#696969',
  },
  Default: {
    border: '2px solid rgba(41, 45, 63, 0.3)',
    backgroundColor: 'rgba(41, 45, 63, 0.3)',
    color: 'white',
  },
  Occurred: {
    border: '2px solid #B71C1C',
    backgroundColor: '#C62828',
    color: 'white',
  },
  'Not Started': {
    border: '2px solid rgba(41, 45, 63, 0.3)',
    backgroundColor: 'rgba(41, 45, 63, 0.3)',
    color: 'white',
  },
};

export class EventStylesConfigBuilder {
  private config: EventStylesConfig = {};
  private defaultConfig: EventStylesConfig = defaultConfig;

  addConfig(eventKey: string, styles: EventStyles): EventStylesConfigBuilder {
    this.config[eventKey] = { ...styles };
    return this; // Return this for chaining
  }

  overwriteConfig(eventKey: string, styles: Partial<EventStyles>): EventStylesConfigBuilder {
    if (this.config[eventKey]) {
      this.config[eventKey] = { ...this.config[eventKey], ...styles };
    } else {
      console.warn(`Event key '${eventKey}' not found. Adding as new config.`);
      this.config[eventKey] = { ...styles } as EventStyles;
    }
    return this; // Return this for chaining
  }

  withConfig(eventKey: string): EventStylesConfigBuilder {
    const defaultStyle = this.defaultConfig[eventKey];
    if (defaultStyle) {
      this.config[eventKey] = { ...defaultStyle };
    } else {
      console.warn(`Default configuration for '${eventKey}' not found. Skipping.`);
    }
    return this; // Return this for chaining
  }

  build(): EventStylesConfig {
    return this.config;
  }
}
