import React from 'react';
import { TablePagination, Button, Box, Stack } from '@mui/material';
import ConfirmEvent from 'components/ConfirmEvent';
import { authStore } from 'store';

interface CustomTablePaginationProps {
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => void;
  page: number;
  rowsPerPage: number;
  data: any;
  isAdmin: boolean;
  triggerSelection: (rowId?: number) => void;
}

const CustomTablePagination: React.FC<CustomTablePaginationProps> = ({
  onPageChange,
  onRowsPerPageChange,
  page,
  rowsPerPage,
  data,
  isAdmin,
  triggerSelection,
}) => {
  return (
    <Stack
      width='100%'
      justifyContent='space-between'
      alignItems='center'
      sx={{
        flexDirection: { xs: 'column', md: 'row' },
        padding: { xs: authStore.isAdmin ? '10px 0' : '0', md: '0 10px' },
      }}
    >
      {/* <Button
        style={{
          fontFamily: 'Questrial',
          border: data.some((item: any) => item.isChecked === true)
            ? '2px solid #3555C8'
            : '2px solid rgba(41, 45, 63, 0.3)',
          borderRadius: '12px',
          backgroundColor: data.some((item: any) => item.isChecked === true) ? '#3555C8' : 'rgba(41, 45, 63, 0.3)',
          justifyContent: 'center',
          padding: '4px 6px',
          width: 'auto',
          fontSize: '12px',
          color: 'white',
          // textTansform: 'none !important',
          marginLeft: '10px',
        }}
        onClick={() => handleButtonClick()}
        {...{
          key: 'Claim',
          color: 'inherit',
        }}
        disabled={!data.some((item: any) => item.isChecked === true)}
      >
        Trigger Selection
      </Button> */}
      {isAdmin && data.length ? <ConfirmEvent triggerEvent={triggerSelection} /> : <Box />}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        labelRowsPerPage='Items per page'
        style={{
          color: '#939AAB',
          overflowX: 'hidden',
        }}
      />
    </Stack>
  );
};

export default CustomTablePagination;
