import React, { useMemo } from 'react';
import {
  Box,
  Button,
  InputLabel,
  InputLabelProps,
  MenuItem,
  Select,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Checkbox from '@mui/material/Checkbox';
import { web3Enable } from '@polkadot/extension-dapp';
import { ModalInstance } from './modals';
import { useModal } from 'hooks';
import { authStore, walletStore } from 'store';
import { ButtonInstallPolkadot, ButtonInstance } from './buttons';
import { showNotification } from 'utils';
import { useTranslation } from 'react-i18next';
import { DateTimePicker, DesktopDatePicker } from '@mui/x-date-pickers';
import { AccountsDropdown } from './dropdowns';
import { appConfig } from 'config';
import { IOracleFormValues } from 'views';

interface IConfirmEventProps {
  rowId?: number;
  triggerEvent: (rowId?: number) => void;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.08), 0px 32px 40px -2px rgba(0, 0, 0, 0.08)',
  borderRadius: '16px',
  p: 4,
};

const buttonContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '32px',
} as React.CSSProperties;

const StyledHeader = styled(Typography)<TypographyProps>({
  marginBottom: '32px',
  fontFamily: 'Poppins',
  fontWeight: 600,
  fontSize: '28px',
  lineHeight: '40px',
  color: '#292D3F',
});

const StyledLabel = styled(InputLabel)<InputLabelProps>({
  fontFamily: 'Poppins',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '24px',
});

const cardButtonStyle = {
  fontFamily: 'Questrial',
  border: '2px solid #3555C8',
  borderRadius: '12px',
  backgroundColor: '#3555C8',
  justifyContent: 'center',
  padding: '4px 6px',
  width: 'auto',
  fontSize: '12px',
  color: 'white',
  textTransform: 'none !important',
};

const ConfirmEvent: React.FC<IConfirmEventProps> = ({ rowId, triggerEvent }: IConfirmEventProps) => {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onConfirm = () => {
    setOpen(false);
    triggerEvent(rowId);
    return true;
  };

  return (
    <>
      <ButtonInstance
        // sx={cardButtonStyle}
        onClick={handleOpen}
        {...{
          key: 'Trigger Event',
          color: 'inherit',
        }}
        title={`${rowId ? t('trigger_event') : t('trigger_selection')}`}
      />

      <ModalInstance isOpen={open} close={handleClose}>
        <Box>
          {/* <Box sx={style} className='modal-content'> */}
          <StyledHeader variant='h2'>{t('modal_form.confirmation_title')}</StyledHeader>
          <StyledLabel id='accounts-select-label'>{t('modal_form.confirmation')}</StyledLabel>
          <Box className='modal-actions' sx={buttonContainer}>
            <ButtonInstance onClick={handleClose} title='cancel' style={{ margin: 0 }} colorType='red' />
            <ButtonInstance onClick={onConfirm} title='confirm' />
          </Box>
        </Box>
      </ModalInstance>
    </>
  );
};

export default ConfirmEvent;
