import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { authStore } from 'store';

interface IHookOption {
  isRedirect?: boolean;
  redirectRoute?: string;
}

export const useDAORequire = ({ isRedirect = true, redirectRoute = '/' }: IHookOption = {}): { isLoading: boolean } => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const isDao = authStore.isDao;

  useEffect(() => {
    if (!isDao && isRedirect) {
      return navigate(redirectRoute);
    }
    setIsLoading(false);
  }, [isDao, redirectRoute, navigate, isRedirect]);

  return { isLoading };
};
