import { ApiPromise, WsProvider } from '@polkadot/api';
import { appConfig } from 'config';
import { handleTransactionResult } from './handleTransactionResult';

export default async function BuyPRMXLiquidityProviders(
  PRMXData: string,
  setStatus: React.Dispatch<React.SetStateAction<NonNullable<string>>>,
) {
  const wsProvider = new WsProvider(appConfig.webSocket);
  const api = await ApiPromise.create({
    provider: wsProvider,
    types: {},
  });
  await api.isReady;

  const [collectionId, insuranceId] = PRMXData.split(':');
  const request = api.tx.marketplace.provideLiquidity(collectionId, insuranceId);
  const result = await handleTransactionResult(request, api, 'provide liquidity', setStatus);

  return result;
}
