import { getCountry } from 'services/CountryOptions';
import { appConfig, pricingConfig } from '../config';
import axios from 'axios';

export interface IGetPriceParams {
  lat: number;
  lon: number;
  startdate: number;
  duration_in_hours: number;
  threshold: number;
  coverage: number;
  number_of_simulations: number;
  ROC: number;
}

export interface IPriceInfo {
  avg_cost: number;
  required_capital: number;
  diversified_capital: number;
  recommended_premium: number;
  closest_point: number;
  dist_closest_point_km: number;
}

export const getPrice = async (params: IGetPriceParams) => {
  const { data } = await axios.get(`${appConfig.pricingApiUrl}/pricing`, {
    params,
  });
  const parsedData = JSON.parse(data[0]) as IPriceInfo;
  return {
    ...parsedData,
    recommended_premium: (parsedData.recommended_premium ?? 0) / Math.pow(10, appConfig.USDT_DECIMAL_SCALE),
  };
};

export const getPricingParams = (
  startDate: number,
  durationInHours: number,
  underwriteAmount: number,
  location = 'Philippines',
) => {
  const { long, lat } = getCountry(location);
  return {
    lat: lat,
    lon: long,
    startdate: startDate,
    // End date - Start date in hour
    duration_in_hours: durationInHours,
    // Just for testing we should calculate it on PROD
    threshold: pricingConfig.threshold,
    coverage: underwriteAmount,
    number_of_simulations: pricingConfig.numberOfSimulations,
    ROC: pricingConfig.ROC,
  } as IGetPriceParams;
};
