import Card from './Card';
import { Grid, Box } from '@mui/material';
import { ICardData } from 'types';

interface Props {
  data: ICardData[];
  filters: () => void;
}

export default function InsuranceTabCards({ data, filters }: Props) {
  return (
    <Box style={{ padding: '10px 10px', display: 'flex', justifyContent: 'center' }}>
      <Grid container justifyContent='center' spacing={3} width={{ lg: 1200, md: 900, sm: 400 }}>
        {data.map((item, index) => (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key={index}>
            <Box>
              <Card data={item} key={index} filters={filters} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
