import insurance1 from '../img/assets/insurance-1.png';
import insurance2 from '../img/assets/insurance-2.png';
import insurance3 from '../img/assets/insurance-3.png';
import insurance4 from '../img/assets/insurance-4.png';
import insurance5 from '../img/assets/insurance-5.png';
import insurance6 from '../img/assets/insurance-6.png';
import insurance7 from '../img/assets/insurance-7.png';
import insurance8 from '../img/assets/insurance-8.png';
import insurance9 from '../img/assets/insurance-9.png';
import insurance10 from '../img/assets/insurance-10.png';
import insurance11 from '../img/assets/insurance-11.png';
import insurance12 from '../img/assets/insurance-12.png';
import insurance13 from '../img/assets/insurance-13.png';
import insurance14 from '../img/assets/insurance-14.png';
import insurance15 from '../img/assets/insurance-15.png';
import insurance16 from '../img/assets/insurance-16.png';
import insurance17 from '../img/assets/insurance-17.png';
import insurance18 from '../img/assets/insurance-18.png';
import ModalForm from './ModalForm';
import { useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import { ICardData } from 'types';

const cardHeaderBoxStyle = {
  padding: '24px',
  color: '#FFFFFF',
  fontFamily: 'Questrial',
  position: 'relative',
  height: '124px',
};

const cardHeaderTitleStyle = {
  width: '200px',
  color: '#ffffff',
};

const cardHeaderTextStyle = {
  paddingTop: '4px',
  fontWeight: 400,
};

const cardBodyBoxStyle = {
  fontFamily: 'Questrial',
  color: '#3555C8',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
};

const cardBodyBoxDetailStyle = {
  fontFamily: 'Questrial',
  color: 'rgba(41, 45, 63, 0.3)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  lineHeight: '28px',
};

const cardBodyBoxDetailAboutStyle = {
  color: '#292D3F',
  paddingTop: '14px',
  textAlign: 'justify',
};

const cardBodyCostDetailStyle = {
  textAlign: 'right',
  color: '#292D3F',
  fontFamily: 'Questrial',
};

interface Props {
  data: ICardData;
  filters: () => void;
}

export default function Card({ data, filters }: Props) {
  const cardHeaderStyle = {
    background: `url(${data['image']})`,
    backgroundSize: '100%',
    minHeight: '300px',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    borderRadius: '18px',
  };
  const { t } = useTranslation();

  const [copied, setCopied] = useState(false);

  const handleClick = () => {
    if (data['holder'] === undefined) return;
    navigator.clipboard
      .writeText(data['holder'])
      .then(() => {
        setCopied(true);
        setTimeout(setCopied, 3000, false);
      })
      .catch((error) => {
        console.error('Error copying text to clipboard:', error);
      });
  };

  switch (data['image']) {
    case 'insurance1':
      cardHeaderStyle.background = `url(${insurance1}) no-repeat`;
      break;
    case 'insurance2':
      cardHeaderStyle.background = `url(${insurance2}) no-repeat`;
      break;
    case 'insurance3':
      cardHeaderStyle.background = `url(${insurance3}) no-repeat`;
      break;
    case 'insurance4':
      cardHeaderStyle.background = `url(${insurance4}) no-repeat`;
      break;
    case 'insurance5':
      cardHeaderStyle.background = `url(${insurance5}) no-repeat`;
      break;
    case 'insurance6':
      cardHeaderStyle.background = `url(${insurance6}) no-repeat`;
      break;
    case 'insurance7':
      cardHeaderStyle.background = `url(${insurance7}) no-repeat`;
      break;
    case 'insurance8':
      cardHeaderStyle.background = `url(${insurance8}) no-repeat`;
      break;
    case 'insurance9':
      cardHeaderStyle.background = `url(${insurance9}) no-repeat`;
      break;
    case 'insurance10':
      cardHeaderStyle.background = `url(${insurance10}) no-repeat`;
      break;
    case 'insurance11':
      cardHeaderStyle.background = `url(${insurance11}) no-repeat`;
      break;
    case 'insurance12':
      cardHeaderStyle.background = `url(${insurance12}) no-repeat`;
      break;
    case 'insurance13':
      cardHeaderStyle.background = `url(${insurance13}) no-repeat`;
      break;
    case 'insurance14':
      cardHeaderStyle.background = `url(${insurance14}) no-repeat`;
      break;
    case 'insurance15':
      cardHeaderStyle.background = `url(${insurance15}) no-repeat`;
      break;
    case 'insurance16':
      cardHeaderStyle.background = `url(${insurance16}) no-repeat`;
      break;
    case 'insurance17':
      cardHeaderStyle.background = `url(${insurance17}) no-repeat`;
      break;
    default:
      cardHeaderStyle.background = `url(${insurance18}) no-repeat`;
  }

  const cardHeaderActionStyle = {
    position: 'absolute',
    top: '24px',
    right: '24px',
    background: '#FFFFFF',
    color: '#3555C8',
    borderRadius: '18px',
    padding: '2px 12px',
    fontWeight: 'bold',
  };

  switch (data['status']) {
    case 'Expired':
      cardHeaderActionStyle.background = '#939AAB';
      cardHeaderActionStyle.color = '#FFFFFF';
      break;
    default:
      cardHeaderActionStyle.background = '#FFFFFF';
      cardHeaderActionStyle.color = '#3555C8';
  }

  const cardBodyStyle = {
    background: '#FFFFFF',
    borderRadius: '16px',
    top: '124px',
    position: 'absolute',
    width: '100%',
    height: 'fit-content',
    padding: '40px 24px 20px',
    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.08), 0px 16px 20px -2px rgba(0, 0, 0, 0.08)',
  };

  const cardBoxStyle = {
    width: '100%',
    maxWidth: '378px',
    position: 'relative',
    borderRadius: '16px',
    fontFamily: 'Questrial',
  };

  const [isDetailsShow, setDetailsShowOrHide] = useState(false);
  const detailsShowOrHide = () => {
    if (isDetailsShow) {
      setDetailsShowOrHide(false);
    } else {
      setDetailsShowOrHide(true);
    }
  };

  return (
    <Box
      sx={{
        ...cardBoxStyle,
        margin: 'auto',
        gap: { xs: 3, sm: 2, md: 1 },
        height: 'auto',
        minHeight: {
          xs: isDetailsShow ? '510px' : '350px',
          sm: isDetailsShow ? '530px' : '370px',
        },
      }}
    >
      <Box sx={cardHeaderStyle}>
        <Box sx={cardHeaderBoxStyle}>
          <Typography variant='h2' sx={cardHeaderTitleStyle}>
            {data['title']}
          </Typography>
          {data['location'] && (
            <Typography variant='subtitle2' sx={cardHeaderTextStyle}>
              {data['location']}, {data['country']}
            </Typography>
          )}
          {!data['location'] && (
            <Typography variant='subtitle2' sx={cardHeaderTextStyle}>
              {data['country']}
            </Typography>
          )}
          <Typography variant='h6' sx={cardHeaderActionStyle}>
            {data['status']}
          </Typography>
        </Box>
        <Box sx={cardBodyStyle}>
          {data['premium'] && (
            <Box sx={cardBodyBoxStyle}>
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Typography
                  variant='h5'
                  sx={{ fontFamily: 'Questrial', fontWeight: '700 !important', fontSize: '1rem !important' }}
                >
                  {t('card.underwrite_amount')}
                </Typography>
                <Typography
                  variant='h4'
                  sx={{
                    fontFamily: 'Questrial',
                    textAlign: 'right',
                    fontWeight: {
                      xs: 900,
                      md: 'bold',
                    },
                    fontSize: {
                      xs: '1rem',
                      md: '1.125rem',
                    },
                  }}
                >
                  {data['underwrite']} {t('USDT')}
                </Typography>
              </Box>
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Typography
                  variant='h5'
                  sx={{ fontFamily: 'Questrial', fontWeight: '700 !important', fontSize: '1rem !important' }}
                >
                  {t('card.premium_to_pay')}
                </Typography>
                <Typography
                  variant='h4'
                  sx={{
                    fontFamily: 'Questrial',
                    textAlign: 'right',
                    fontWeight: {
                      xs: 900,
                      md: 'bold',
                    },
                    fontSize: {
                      xs: '1rem',
                      md: '1.125rem',
                    },
                  }}
                >
                  {data['premium']} {t('USDT')}
                </Typography>
              </Box>
            </Box>
          )}
          {data['amount'] && (
            <Box sx={cardBodyBoxStyle}>
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Typography
                  variant='h5'
                  sx={{ fontFamily: 'Questrial', fontWeight: '700 !important', fontSize: '1rem !important' }}
                >
                  {t('card.amount_to_lock')}
                </Typography>
                <Typography
                  variant='h4'
                  sx={{
                    fontFamily: 'Questrial',
                    textAlign: 'right',
                    fontWeight: {
                      xs: 900,
                      md: 'bold',
                    },
                    fontSize: {
                      xs: '1rem',
                      md: '1.125rem',
                    },
                  }}
                >
                  {data['amount']} {t('USDT')}
                </Typography>
              </Box>
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Typography
                  variant='h5'
                  sx={{ fontFamily: 'Questrial', fontWeight: '700 !important', fontSize: '1rem !important' }}
                >
                  {t('card.reward')}
                </Typography>
                <Typography
                  variant='h4'
                  sx={{
                    fontFamily: 'Questrial',
                    textAlign: 'right',
                    fontWeight: {
                      xs: 900,
                      md: 'bold',
                    },
                    fontSize: {
                      xs: '1rem',
                      md: '1.125rem',
                    },
                  }}
                >
                  {Number(
                    Number.isInteger(Number(data['reward']))
                      ? Number(data['reward'])
                      : Number(data['reward']).toFixed(4),
                  )}{' '}
                  {t('USDT')}
                </Typography>
              </Box>
            </Box>
          )}
          {data['available'] && (
            <Box sx={cardBodyBoxStyle}>
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Typography
                  variant='h5'
                  sx={{ fontFamily: 'Questrial', fontWeight: '700 !important', fontSize: '1rem !important' }}
                >
                  {t('card.available')}
                </Typography>
                <Typography
                  variant='h4'
                  sx={{
                    fontFamily: 'Questrial',
                    textAlign: 'right',
                    fontWeight: {
                      xs: 900,
                      md: 'bold',
                    },
                    fontSize: {
                      xs: '1rem',
                      md: '1.125rem',
                    },
                  }}
                >
                  {data['available']}
                </Typography>
              </Box>
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Typography
                  variant='h5'
                  sx={{ fontFamily: 'Questrial', fontWeight: '700 !important', fontSize: '1rem !important' }}
                >
                  {t('card.price_per_nft')}
                </Typography>
                <Typography
                  variant='h4'
                  sx={{
                    fontFamily: 'Questrial',
                    textAlign: 'right',
                    fontWeight: {
                      xs: 900,
                      md: 'bold',
                    },
                    fontSize: {
                      xs: '1rem',
                      md: '1.125rem',
                    },
                  }}
                >
                  {data['price']} {t('USDT')}
                </Typography>
              </Box>
            </Box>
          )}

          <ModalForm data={data} filters={filters} triggerProps={{ sx: { marginTop: '20px' } }} />

          {isDetailsShow && (
            <Box sx={{ paddingTop: '24px' }} className={'animation-open'}>
              {data['holder'] && (
                <Box sx={cardBodyBoxDetailStyle}>
                  <Typography variant='h5' sx={{ fontFamily: 'Questrial' }}>
                    {t('card.nft_creator')}
                  </Typography>

                  <Typography variant='h5' sx={cardBodyCostDetailStyle}>
                    <IconButton size='small' onClick={handleClick} disabled={copied}>
                      {copied ? <CheckIcon fontSize='inherit' /> : <ContentCopyIcon fontSize='inherit' />}
                    </IconButton>
                    {data['holder'].substring(0, 5) + '...' + data['holder'].substring(42, 48)}
                  </Typography>
                </Box>
              )}
              {data['startTime'] && (
                <Box sx={cardBodyBoxDetailStyle}>
                  <Typography variant='h5' sx={{ fontFamily: 'Questrial' }}>
                    {t('card.insurance_start_date')}
                  </Typography>
                  <Typography variant='h5' sx={cardBodyCostDetailStyle}>
                    {dayjs(data['startTime']).format('MM/DD/YYYY')}
                  </Typography>
                </Box>
              )}
              {data['endTime'] && (
                <Box sx={cardBodyBoxDetailStyle}>
                  <Typography variant='h5' sx={{ fontFamily: 'Questrial' }}>
                    {t('card.insurance_end_date')}
                  </Typography>
                  <Typography variant='h5' sx={cardBodyCostDetailStyle}>
                    {dayjs(data['endTime']).format('MM/DD/YYYY')}
                  </Typography>
                </Box>
              )}

              {data['premium'] && (
                <Box sx={cardBodyBoxDetailStyle}>
                  <Typography variant='h5' sx={{ fontFamily: 'Questrial' }}>
                    {t('card.source_authority')}
                  </Typography>
                  <Typography variant='h5' sx={cardBodyCostDetailStyle}>
                    {t('card.highty')}
                  </Typography>
                </Box>
              )}

              {data['premium'] && (
                <Box sx={cardBodyBoxDetailStyle}>
                  <Typography variant='h5' sx={{ fontFamily: 'Questrial' }}>
                    {t('card.threshold_value')}
                  </Typography>
                  <Typography variant='h5' sx={cardBodyCostDetailStyle}>
                    100
                  </Typography>
                </Box>
              )}

              {data['premium'] && (
                <Box sx={cardBodyBoxDetailStyle}>
                  <Typography variant='h5' sx={{ fontFamily: 'Questrial' }}>
                    {t('card.expiration_date')}
                  </Typography>
                  <Typography variant='h5' sx={cardBodyCostDetailStyle}>
                    {dayjs(dayjs(new Date()).add(12, 'month').subtract(1, 'day')).format('MM/DD/YYYY')}
                  </Typography>
                </Box>
              )}
              <Box sx={cardBodyBoxDetailAboutStyle}>
                {data['holder'] ? (
                  <Stack direction='column'>
                    <Typography variant='h6'>{t('card.insurance_owner_details')}</Typography>
                    <Typography variant='h6'>{t('card.insurance_owner_start')}</Typography>
                    <Typography variant='h6'>{t('card.insurance_owner_expires')}</Typography>
                  </Stack>
                ) : (
                  <Stack direction='column'>
                    <Typography variant='h6'>{t('card.insurance_details_date')}</Typography>
                    <Typography variant='h6'>{t('card.insurance_details_minimum')}</Typography>
                    <Typography variant='h6'>{t('card.insurance_details_pricing')}</Typography>
                  </Stack>
                )}
              </Box>
            </Box>
          )}
          <Box
            sx={{
              width: '100%',
              height: '100px',
              background: '#E6F0FD',
              borderRadius: '18px',
              position: 'absolute',
              left: '0',
              bottom: '-50px',
              zIndex: -1,
            }}
          ></Box>
          <Box
            onClick={detailsShowOrHide}
            sx={{
              width: '100%',
              cursor: 'pointer',
              marginTop: '60px',
              position: 'absolute',
              bottom: 0,
              left: 0,
              outline: 'none !important',
            }}
          >
            <Typography
              variant='h5'
              sx={{
                textAlign: 'center',
                textDecoration: 'underline',
                color: '#3555C8',
                fontWeight: 'bold !important',
                transform: 'translateY(35px)',
              }}
            >
              {t('card.details')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
