import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  insuranceStyle: {
    width: '100%',
    margin: '0 auto',
  },

  insuranceTabsStyle: {
    display: 'flex',
    justifyContent: 'center',
    gap: '76px',
    flexGrow: '1',
    fontSize: '16px',
    fontFamily: 'Questrial',
    color: '#292D3F',
    fontWeight: 'bold',
  },

  insuranceTabStyle: {
    display: 'flex',
    textAlign: 'center',
    // marginRight: '76px',
    lineHeight: '32px',
    cursor: 'pointer',
    outline: 'none',
  },

  insuranceTabTitleStyle: {
    fontSize: '25px',
    fontFamily: 'Questrial',
    color: '#292D3F',
    fontWeight: 'bold',
    padding: '20px 10px',
  },

  FiltersStyle: {
    position: 'relative',
  },

  insuranceButtonBoxStyle: {
    fontSize: '25px',
    fontFamily: 'Questrial',
    color: '#292D3F',
    fontWeight: 'bold',
    textAlign: 'center',
    paddingTop: '34px',
  },

  insuranceButtonStyle: {
    fontFamily: 'Questrial',
    border: `2px solid #3555C8`,
    borderRadius: `12px`,
    backgroundColor: 'white',
    justifyContent: 'center',
    padding: '8px 12px',
    width: 'fit-content',
    height: '40px',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#3555C8',
    marginLeft: '18px',
  },

  FilterButtonStyle: {
    fontFamily: 'Questrial',
    border: `2px solid #3555C8`,
    borderRadius: `12px`,
    backgroundColor: 'white',
    justifyContent: 'center',
    padding: '8px 12px',
    width: 'auto',
    height: '40px',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#3555C8',
    marginLeft: '18px',
    position: 'absolute',
    top: '-54px',
    right: '0px',
    fontWeight: 'bold',
  },
}));
