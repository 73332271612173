/* eslint-disable @typescript-eslint/ban-types */
import * as React from 'react';
import Box from '@mui/material/Box';
import Step1 from './Step1';
import Step2, { FormDataStep2 } from './Step2';
import Step3 from './Step3';
import { useState } from 'react';
import { walletStore } from 'store/wallet';
import CancelPutOnSaleOrder from 'services/CancelPutOnSaleOrder';
import LoadingSpinner from './LoadingSpinner';
import { ButtonInstance } from './buttons';
import { useTranslation } from 'react-i18next';
import { ModalInstance } from './modals';
import { ButtonProps } from '@mui/material';
import { ICardData } from 'types';
import { Dayjs } from 'dayjs';

export type IDataType = ICardData & {
  insured?: boolean;
  available?: string;
  title?: string;
};

interface Props {
  data: IDataType;
  filters?: () => void;
  triggerProps?: ButtonProps;
}

export interface FormData {
  start_date: Dayjs | number;
  end_date: Dayjs | number;
  premium_amount: number;
  underwrite_amount: number;
  location: string;
  event: string;
  step: number;
  PRMXLiquidityProvidersId?: number | string;
  PRMXSMLiquidityBuyersId?: number | string;
  PRMXSMLiquidityBuyersAmount?: number;
  PRMXSMLiquidityBuyersPrice?: number;
  agree?: boolean;
  agree2?: boolean;
  price_per_token: number;
  number_of_copies: number;
}

const mappingModalType = (data: IDataType) => {
  if (data['insured']) {
    return 'get_a_quote';
  } else if (data['underwrite'] && data['premium']) {
    return 'insurance_buyer';
  } else if (data['amount'] && data['reward']) {
    return 'liquidity_provider';
  } else if (data['numberOfCopies'] && data['price']) {
    return 'token_buyer';
  } else {
    return 'insurance_buyer';
  }
};

export default function ModalForm({ data, filters, triggerProps }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [orderStatus, setStatus] = React.useState('');
  const [postData, setPostData] = useState<boolean>(false);
  const [postDataInner, setPostDataInner] = useState(false);

  React.useEffect(() => {
    if (data['available']) {
      if (orderStatus === 'Finalized') {
        filters?.();
        setPostData(false);
      }

      if (orderStatus === 'error') {
        setPostData(false);
      }
    }
  }, [orderStatus]);

  const modalType = React.useMemo(() => {
    return mappingModalType(data);
  }, [data]);

  const handleOpen = async () => {
    // Tab 1:
    if (data['premium']) {
      setOpen(true);
    }

    // Tab 2:
    if (data['holder'] && !data['available']) {
      setOpen(true);
    }

    // Tab 3:
    if (data['available']) {
      if (walletStore.walletAddress !== data['holder']) {
        setOpen(true);
      } else {
        setPostData(true);
        CancelPutOnSaleOrder(Number(data['id']), setStatus);
      }
    }

    // Get a Quote:
    if (data['insured']) {
      data.country = 'Philippines';
      data.event = 'Earthquake';
      setOpen(true);
    }
  };

  const handleClose = () => {
    if (step === 2 && postDataInner) return;
    setStep(1);
    setOpen(false);
  };

  const [step, setStep] = useState<number>(1);
  const [formValues, setFormValues] = useState<FormData | FormDataStep2>({} as any);

  const disabled = !data['available'] && data['holder'] === walletStore.walletAddress;

  const buttonTittle = () => {
    let name = '';

    if (data['premium']) {
      name = t('modal_form.buy_now');
    }
    if (data['amount'] && data['holder'] !== walletStore.walletAddress) {
      name = t('modal_form.become');
    }
    if (data['amount'] && data['holder'] === walletStore.walletAddress) {
      name = t('modal_form.owner');
    }
    if (data['available'] && walletStore.walletAddress !== data['holder']) {
      name = t('modal_form.buy_now');
    }
    if (data['available'] && walletStore.walletAddress === data['holder']) {
      name = t('modal_form.cancel');
    }
    if (data['insured']) {
      name = t('modal_form.get');
    }
    // if (data['event']) {
    //   name = t('modal_form.confirm');
    // }

    return name;
  };

  return (
    <>
      {postData ? (
        <div style={{ paddingTop: '2px' }}>
          <LoadingSpinner />
        </div>
      ) : (
        <ButtonInstance
          onClick={handleOpen}
          disabled={disabled}
          title={buttonTittle()}
          style={{ width: data['insured'] ? 'auto' : '100%', height: data['insured'] ? '40px' : '48px' }}
          {...triggerProps}
        />
      )}

      <ModalInstance isOpen={open} close={handleClose}>
        <Box
          sx={{
            width: '100%',
            maxHeight: '100vh',
            overflowY: 'auto',
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {step === 1 && (
            <Step1
              setStep={setStep}
              formValues={formValues as FormData}
              setFormValues={setFormValues as React.Dispatch<React.SetStateAction<NonNullable<FormData>>>}
              data={data}
              closeModal={handleClose}
            />
          )}

          {step === 2 && (
            <Step2
              modalType={modalType}
              setStep={setStep}
              formValues={formValues as FormDataStep2}
              setFormValues={setFormValues as React.Dispatch<React.SetStateAction<NonNullable<FormDataStep2>>>}
              handleClose={handleClose}
              postData={postDataInner}
              setPostData={setPostDataInner}
            />
          )}
          {step === 3 && (
            <Step3
              setStep={setStep}
              formValues={formValues}
              setFormValues={setFormValues}
              handleClose={handleClose}
              data={data}
            />
          )}
        </Box>
      </ModalInstance>
    </>
  );
}
