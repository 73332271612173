import { useState } from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled, Typography } from '@mui/material';
import { ButtonInstance, Loader, ModalInstance } from 'components';
import { InsuranceTimerView } from './InsuranceTimerView';
import { dateFormat, formatNumber, getFromLS } from 'utils';
import { activeColumns, locations, LocationsKeys, LSKeys } from 'constants/index';
import { walletStore } from 'store';
import { IInsurance, IModalDetails, IRequest, IResults, IVoteCompleted } from 'types';
import { useModal } from 'hooks';
import { MyDAOVotingModalFormView } from './MyDAOVotingModalFormView';

interface IProps {
  results: IResults[];
}

export const ActiveTableBody = ({ results }: IProps) => {
  const [completedIndex, setCompletedIndex] = useState<IVoteCompleted[]>(() => {
    const saved = getFromLS(LSKeys.VotedArr, []);
    return saved;
  });
  const [request, setRequest] = useState<IRequest | null>(null);
  const [modalDetails, setModalDetails] = useState<IModalDetails | null>(null);
  const { open, close, isOpen } = useModal();
  const [isLoadingVoteModal, setIsLoadingVoteModal] = useState(false);

  const handleOpenModal = (row: IInsurance, request: IRequest) => {
    const { risk, location, startTime, endTime, premium, underwrite } = row;
    setModalDetails({ risk, location, startTime, endTime, premium, underwrite });
    setRequest(request);
    open();
  };

  const handleClose = () => {
    const isCompleted = completedIndex.some((el) => el.index === request?.proposalIndex);
    if (isLoadingVoteModal && !isCompleted) {
      return null;
    } else {
      close();
    }
  };

  return (
    <>
      <ModalInstance isOpen={isOpen} close={handleClose}>
        {request ? (
          <MyDAOVotingModalFormView
            modalDetails={modalDetails}
            request={request}
            closeModal={close}
            setCompletedIndex={setCompletedIndex}
            completed={completedIndex}
            isLoading={isLoadingVoteModal}
            setIsLoading={setIsLoadingVoteModal}
          />
        ) : (
          <Loader />
        )}
      </ModalInstance>
      <TableBody>
        {results.map((result, index) => {
          const { insurance, request, votes } = result;
          const { startTime, endTime } = insurance;
          const { timestamp, proposalIndex } = request;
          const isCompleted = completedIndex.some((elem) => elem.index === proposalIndex);
          const isVoted = votes.some(({ voter }) => voter === walletStore.walletAddress);
          const voted = isCompleted || isVoted;

          const row = {
            ...insurance,
            timer: timestamp,
            startTime: dateFormat(startTime),
            endTime: dateFormat(endTime),
          };

          return (
            <TableRow hover role='checkbox' tabIndex={-1} key={index}>
              {activeColumns.map((column) => {
                const value = row[column.id];
                let render = null;

                switch (column.id) {
                  case 'timer':
                    render = <InsuranceTimerView timestampMs={value as string} />;
                    break;
                  case 'status':
                    render = (
                      <ButtonInstance
                        style={{ width: '103px', margin: 0 }}
                        disabled={voted}
                        onClick={() => handleOpenModal(row, request)}
                        title={voted ? 'Voted' : 'Vote'}
                      />
                    );
                    break;
                  case 'underwrite':
                  case 'premium':
                    render = <Typography variant='body1'>{formatNumber(value) + ' USDT'}</Typography>;
                    break;
                  case 'location':
                    render = <Typography variant='body1'>{locations[value as LocationsKeys] || value}</Typography>;
                    break;

                  case 'startTime':
                  case 'endTime': {
                    render = (
                      <Typography variant='body1' sx={{ maxWidth: '100px', width: '100%', float: 'right' }}>
                        {value}
                      </Typography>
                    );
                    break;
                  }

                  default:
                    render = <Typography variant='body1'>{value}</Typography>;
                    break;
                }

                return (
                  <StyledTableCell key={column.id} align={column.align}>
                    {render}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </>
  );
};

const StyledTableCell = styled(TableCell)<TableCellProps>({
  color: '#292D3F',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '24px',
  textTransform: 'capitalize',
  boxSizing: 'border-box',
  [`&:first-of-type`]: {
    fontSize: '16px',
    lineHeight: '28px',
  },
});
