interface Locations {
  [key: string]: string;
}

export const locations: Locations = {
  // '0': 'Florida, USA',
  // '1': 'Philippines',
  '1': 'Philippines',
  // '3': 'Cuba',
  // '4': 'Bahamas',
  // '5': 'Madagascar',
  // '6': 'Mexico',
  // '7': 'India',
  // '8': 'Ecuador',
  // '9': 'Indonesia',
  // '10': 'California, USA',
} as const;

export type LocationsKeys = keyof typeof locations;
// eslint-disable-next-line prettier/prettier
export type LocationsValues = typeof locations[LocationsKeys];

export const locationsArr = [
  // {
  //   title: 'Florida, USA',
  //   events: ['Cyclone'],
  //   locationCoefficient: 0.1004,
  //   localId: 0,
  // },
  // {
  //   title: 'Philippines',
  //   events: ['Cyclone'],
  //   locationCoefficient: 0.251,
  //   localId: 1,
  // },
  {
    title: 'Philippines',
    events: ['Earthquake'],
    locationCoefficient: 0.5396,
    localId: 1,
  },
  // {
  //   title: 'Cuba',
  //   events: ['Cyclone'],
  //   locationCoefficient: 0.1004,
  //   localId: 3,
  // },
  // {
  //   title: 'Bahamas',
  //   events: ['Cyclone'],
  //   locationCoefficient: 0.1004,
  //   localId: 4,
  // },
  // {
  //   title: 'Madagascar',
  //   events: ['Cyclone'],
  //   locationCoefficient: 0.1004,
  //   localId: 5,
  // },
  // {
  //   title: 'Mexico',
  //   events: ['Earthquake'],
  //   locationCoefficient: 0.1004,
  //   localId: 6,
  // },
  // {
  //   title: 'India',
  //   events: ['Earthquake'],
  //   locationCoefficient: 0.1004,
  //   localId: 7,
  // },
  // {
  //   title: 'Ecuador',
  //   events: ['Earthquake'],
  //   locationCoefficient: 0.1004,
  //   localId: 8,
  // },
  // {
  //   title: 'Indonesia',
  //   events: ['Earthquake'],
  //   locationCoefficient: 0.1004,
  //   localId: 9,
  // },
  // {
  //   title: 'California, USA',
  //   events: ['Earthquake'],
  //   locationCoefficient: 0.1004,
  //   localId: 10,
  // },
] as const;
