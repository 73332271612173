import React, { useState, useEffect } from 'react';
import { Box, Button, ButtonBaseProps, Typography, TypographyProps } from '@mui/material';
import styled from '@emotion/styled';
import { Loader } from 'components';
import { VoteSuccessBannerView } from './VoteSuccessBannerView';
import { showNotification } from 'utils';
import { IModalDetails, IRequest, IVoteCompleted, VoteCallbackType } from 'types/index';
import { daoApi } from 'api/ws';
import { errorHandle } from 'utils/errorHandle';
import { locations } from 'constants/index';

interface IProps {
  modalDetails: IModalDetails | null;
  request: IRequest;
  setCompletedIndex: VoteCallbackType;
  closeModal: () => void;
  completed: IVoteCompleted[];
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MyDAOVotingModalFormView = ({
  modalDetails,
  request,
  closeModal,
  setCompletedIndex,
  completed,
  isLoading,
  setIsLoading,
}: IProps) => {
  const isCompleted = completed.some((el) => el.index === request?.proposalIndex);

  useEffect(() => {
    if (isCompleted) {
      setIsLoading(false);
      showNotification({ title: 'Vote completed', message: 'Your vote is counted' });
    }
  }, [isCompleted, closeModal, setCompletedIndex, request?.proposalIndex]);

  const handleVote = (result: boolean) => {
    setIsLoading(true);
    const { proposalHash, proposalIndex } = request;
    daoApi.insuranceVote(proposalHash, proposalIndex, result, setCompletedIndex).catch((err) => {
      errorHandle(err);
      setIsLoading(false);
    });
  };

  if (isLoading || isCompleted) {
    return (
      <Box>
        {!isCompleted ? (
          <>
            <Loader />
            <Typography variant='h3' sx={{ textAlign: 'center' }}>
              Voting is in progress, please wait
            </Typography>
          </>
        ) : (
          <VoteSuccessBannerView close={closeModal} />
        )}
      </Box>
    );
  }

  return (
    <>
      <StyledTypographyH2 variant='h2'>Review insurance request</StyledTypographyH2>
      <ul style={{ padding: 0 }}>
        {modalDetails &&
          Object.entries(modalDetails).map(([key, value]) => (
            <li key={key} style={stylesLi}>
              <StyledListTypographyKey variant='body1'>{key}</StyledListTypographyKey>
              <StyledListTypographyValue variant='body2'>
                {key === 'location' ? locations[value] : value}
              </StyledListTypographyValue>
            </li>
          ))}
      </ul>
      <Box style={stylesButtonContainer}>
        <StyledModalButton onClick={() => handleVote(true)}>
          <Typography variant='h5' sx={stylesButtonText}>
            Approve
          </Typography>
        </StyledModalButton>
        <StyledModalButton onClick={() => handleVote(false)}>
          <Typography variant='h5' sx={stylesButtonText}>
            Reject
          </Typography>
        </StyledModalButton>
      </Box>
    </>
  );
};

const StyledTypographyH2 = styled(Typography)<TypographyProps>({
  marginBottom: '32px',
  fontFamily: 'Poppins',
  fontWeight: 600,
  lineHeight: '40px',
  fontSize: '28px',
  textAlign: 'center',
});

const StyledListTypographyKey = styled(Typography)<TypographyProps>({
  fontFamily: 'Poppins',
  fontWeight: 400,
  lineHeight: '28px',
  fontSize: '16px',
  textAlign: 'center',
  color: 'rgba(41, 45, 63, 0.3)',
});

const StyledModalButton = styled(Button)<ButtonBaseProps>({
  padding: '10px 16px',
  width: '100%',
  border: `2px solid #3555C8`,
  borderRadius: `12px`,

  [`&.Mui-disabled`]: {
    backgroundColor: 'rgba(41, 45, 63, 0.3)',
    border: 'none',
  },
});

const stylesLi = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  listStyle: 'none',
  textTransform: 'capitalize',
} as React.CSSProperties;

const StyledListTypographyValue = styled(Typography)<TypographyProps>({
  fontFamily: 'Poppins',
  fontWeight: 400,
  lineHeight: '28px',
  fontSize: '16px',
  textAlign: 'center',
  color: '#292D3F',
});

const stylesButtonContainer = {
  marginTop: '32px',
  display: 'flex',
  justifyContent: 'space-between',
  textTransform: 'capitalize',
  gap: '28px',
} as React.CSSProperties;

const stylesButtonText = {
  fontSize: '16px',
  fontWeight: '500 !important',
  lineHeight: '28px',
  color: '#3555C8',
} as React.CSSProperties;
