import React, { useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Container, Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import GetLiquidityProviders from '../services/GetLiquidityProviders';
import LoadingSpinner from './LoadingSpinner';
import PutOnSaleModalForm from './PutOnSaleModalForm';
import Claim from 'services/Claim';
import { authStore } from 'store';
import { differenceInDays } from 'date-fns';
import { useFetch } from 'hooks';
import { PdfLinkButton } from 'features/policies/PdfLinkButton';
import { EventStylesConfig, EventStylesConfigBuilder, StatusLabel } from 'features/policies/StatusLabel';
import useTableSort from 'hooks/useTableSort';
import { HeadCell, SortableTableHead } from './table/SortableTableHead';
import TableRowSkeleton from 'features/policies/TableRowSkeleton';

type Liquidity = Awaited<ReturnType<typeof GetLiquidityProviders>>[0];
const headCells: HeadCell<Liquidity>[] = [
  {
    id: 'active',
    disablePadding: true,
    sortType: 'character',
    label: 'policies.tables.event status',
    align: 'center',
  },
  {
    id: 'name',
    disablePadding: true,
    sortType: 'character',
    label: 'policies.tables.type',
    align: 'left',
  },
  {
    id: 'location',
    disablePadding: true,
    sortType: 'character',
    label: 'policies.tables.location',
    align: 'left',
  },
  {
    id: 'startDate',
    disablePadding: true,
    sortType: 'date',
    label: 'policies.tables.start date',
    align: 'right',
  },
  {
    id: 'expiration',
    disablePadding: true,
    sortType: 'date',
    label: 'policies.tables.expiration',
    align: 'right',
  },
  {
    id: 'comission',
    disablePadding: true,
    sortType: 'numeric',
    label: 'policies.tables.rewards',
    align: 'right',
    maxWidth: '90px',
  },
  {
    id: 'underwrite',
    disablePadding: true,
    sortType: 'numeric',
    label: 'policies.tables.locked',
    align: 'right',
  },
  {
    id: 'tokens',
    disablePadding: true,
    sortType: 'numeric',
    label: 'policies.tables.tokens',
    align: 'right',
  },
  {
    id: 'until',
    disablePadding: true,
    sortType: 'numeric',
    label: 'policies.tables.until',
    align: 'right',
  },
  {
    id: 'claim',
    disablePadding: true,
    disabledSort: true,
    label: 'policies.tables.claim',
    align: 'left',
  },
];

export const LiquidityProviderTable: React.FC = () => {
  const { response, loading, autoUpdate, retry } = useFetch(
    GetLiquidityProviders,
    [],
    [],
    true,
    // REFRESH_LP_TABLE,
  );
  const data = useMemo(() => response.filter((item: any) => !item.isClaimed), [response]);
  const isAdmin = authStore.isAdmin;

  // const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  // const [orderBy, setOrderBy] = useState<string>('expiration');

  const { getSortedData, onRequestSort, order, orderBy } = useTableSort({
    headCells,
    defaultOrder: 'asc',
    defaultOrderBy: 'mint',
  });

  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [dense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderStatus, setStatus] = useState<{ [key: string]: string }>({});

  // const handleSelectAllClick = (event: any) => {
  //   if (!isAdmin) return;
  //   if (event.target.checked) {
  //     const newSelected = data.map((n: any) => n.name);
  //     setSelected(newSelected);
  //     return;
  //   }
  //   setSelected([]);
  // };

  const handleClick = (event: any, name: string) => {
    if (!isAdmin) return;
    const selectedIndex = selected.indexOf(name);
    let newSelected: any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  React.useEffect(() => {
    if (Object.values(orderStatus).includes('loading')) {
      autoUpdate(false);
    } else {
      autoUpdate(true);
      retry();
    }
  }, [orderStatus]);

  const handleOpen = async (id: string) => {
    setStatus((current) => ({ ...current, [id]: 'loading' }));

    await Claim(id, (status: string) =>
      setStatus((current) => {
        if (status === 'Finalized' || status === 'error') {
          return { ...current, [id]: status };
        }
        return current;
      }),
    );
  };

  const eventStylesConfig: EventStylesConfig = new EventStylesConfigBuilder()
    .withConfig('Not Started')
    .withConfig('Active')
    .withConfig('Occurred')
    .withConfig('Expired')
    .withConfig('Approved')
    .build();

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer
          sx={{
            py: 1,
            overflowX: {
              xs: 'auto',
              xl: 'hidden',
            },
          }}
        >
          <Table aria-labelledby='tableTitle' size={dense ? 'small' : 'medium'}>
            <SortableTableHead
              headCells={headCells}
              onRequestSort={onRequestSort}
              order={order}
              orderBy={orderBy}
              tableCellProps={{ sx: { padding: '16px' } }}
            />
            <TableBody>
              {getSortedData(
                data.map((row: any) => ({
                  ...row,
                  startDate: row.mint,
                  tokens: row.free,
                  until:
                    differenceInDays(new Date(row.expiration), new Date()) > 0
                      ? differenceInDays(new Date(row.expiration), new Date())
                      : 0,
                })),
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  let status = '';

                  switch (row.active) {
                    case 'NotStarted':
                      status = 'Not Started';
                      break;

                    case 'Active':
                      status = 'Active';
                      break;

                    case 'Occurred':
                      status = 'Occurred';
                      break;

                    case 'Expired':
                      status = 'Expired';
                      break;

                    case 'Approved':
                      status = 'Approved';
                      break;
                  }

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      role='checkbox'
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell align='center'>
                        <StatusLabel event={status} eventStyles={eventStylesConfig[status]} />
                      </TableCell>

                      <TableCell align='right' style={{ padding: '8px' }}>
                        <Box id={labelId}>
                          <Stack direction='row' alignItems='center'>
                            <Typography variant='body1'>{row.name}</Typography>&nbsp;
                            <PdfLinkButton row={row} />
                          </Stack>
                        </Box>
                      </TableCell>
                      <TableCell align='left' style={{ padding: '8px' }}>
                        <Typography variant='body1'>{row.location}</Typography>
                      </TableCell>
                      <TableCell align='right' style={{ padding: '8px' }}>
                        <Typography variant='body1' sx={{ maxWidth: '110px', textAlign: 'right' }}>
                          {row.mint}
                        </Typography>
                      </TableCell>
                      <TableCell align='right' style={{ padding: '8px' }}>
                        <Typography
                          variant='body1'
                          sx={{ width: '100%', float: 'right', maxWidth: '110px', textAlign: 'right' }}
                        >
                          {row.expiration}
                        </Typography>
                      </TableCell>
                      <TableCell align='right' style={{ padding: '8px' }}>
                        <Typography variant='body1'>{row.comission}</Typography>
                      </TableCell>
                      <TableCell align='right' style={{ padding: '8px' }}>
                        <Typography variant='body1'>{row.underwrite}</Typography>
                      </TableCell>
                      <TableCell align='right' style={{ padding: '8px' }}>
                        <Typography variant='body1'>
                          {row.free}/{row.underwrite / 100}
                        </Typography>
                      </TableCell>
                      <TableCell align='right' style={{ padding: '8px' }}>
                        <Typography variant='body1'>
                          {row.until === 1 ? `${row.until} Day` : `${row.until} Days`}
                        </Typography>
                      </TableCell>
                      <TableCell align='left' style={{ padding: '8px' }}>
                        <Container
                          style={{ display: 'flex', alignItems: 'stretch', flexDirection: 'column', gap: '5px' }}
                        >
                          <PutOnSaleModalForm
                            data={{
                              country: row.location,
                              event: row.name,
                              amount: row.free,
                              price: 100,
                              smtId: row.smtId,
                              available: String(row.underwrite / 100),
                              status: row.active,
                              startTime: row.mint,
                              expireTime: row.expiration,
                            }}
                            updateLPT={retry}
                            autoUpdate={autoUpdate}
                          />
                          {orderStatus[row.smtId] === 'loading' ? (
                            <Box sx={{ paddingTop: '0px' }}>
                              <LoadingSpinner size={32} />
                            </Box>
                          ) : (
                            <Button
                              style={{
                                fontFamily: 'Questrial',
                                border:
                                  row.active === 'Occurred' || row.active === 'Expired'
                                    ? '2px solid #3555C8'
                                    : '2px solid rgba(41, 45, 63, 0.3)',
                                borderRadius: '12px',
                                backgroundColor:
                                  row.active === 'Occurred' || row.active === 'Expired'
                                    ? '#3555C8'
                                    : 'rgba(41, 45, 63, 0.3)',
                                justifyContent: 'center',
                                padding: '4px 6px',
                                width: 'auto',
                                color: 'white',
                              }}
                              onClick={() => handleOpen(row.smtId)}
                              {...{
                                key: 'Claim',
                                color: 'inherit',
                              }}
                              disabled={
                                row.active === 'Active' ||
                                row.active === 'Approved' ||
                                orderStatus[row.id] === 'Finalized'
                              }
                            >
                              <Typography variant='subtitle1'>Claim</Typography>
                            </Button>
                          )}
                        </Container>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {loading &&
                Array.from({ length: rowsPerPage }, (_, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <TableRowSkeleton animation='wave' align='center' width='70px' />
                    </TableCell>
                    <TableCell>
                      <TableRowSkeleton animation='wave' width='150px' />
                    </TableCell>
                    <TableCell>
                      <TableRowSkeleton animation='wave' width='120px' />
                    </TableCell>
                    <TableCell>
                      <TableRowSkeleton animation='wave' width='80px' align='right' />
                    </TableCell>
                    <TableCell>
                      <TableRowSkeleton animation='wave' width='80px' align='right' />
                    </TableCell>
                    <TableCell>
                      <TableRowSkeleton animation='wave' align='right' width='70px' />
                    </TableCell>
                    <TableCell>
                      <TableRowSkeleton animation='wave' align='right' />
                    </TableCell>
                    <TableCell>
                      <TableRowSkeleton animation='wave' align='right' width='50px' />
                    </TableCell>
                    <TableCell>
                      <TableRowSkeleton animation='wave' width='70px' align='right' />
                    </TableCell>
                    <TableCell>
                      <TableRowSkeleton animation='wave' width='80px' align='center' />
                    </TableCell>
                  </TableRow>
                ))}

              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                    lineHeight: '24px',
                  }}
                >
                  <TableCell colSpan={10} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack direction='row' alignItems='center' sx={{ justifyContent: { xs: 'center', md: 'flex-end' } }}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage='Items per page'
            style={{ color: '#939AAB', overflowX: 'hidden' }}
          />
        </Stack>
      </Paper>
    </Box>
  );
};
