import React from 'react';
import { Box, Container, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDAORequire } from '../../hooks';
import { CustomBreadcrumbs, Loader } from 'components';
import { MyDAOActiveTableView } from './activeTable';
import { MyDAOHistoryTableView } from './historyTable';
import { useTranslation } from 'react-i18next';

export const MyDAOView = () => {
  const { t } = useTranslation();
  const { isLoading } = useDAORequire();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container
      maxWidth='xl'
      sx={{
        padding: '76px 16px',
      }}
    >
      <CustomBreadcrumbs />
      <Box className='firstTable' sx={{ margin: '15px 0 24px 0' }}>
        <StyledTypographyH2 variant='h2'>{t('dao.my_dao')}</StyledTypographyH2>
        <StyledTypographyH3 variant='h2'>{t('dao.active_requests')}</StyledTypographyH3>
        <MyDAOActiveTableView />
      </Box>
      <Box className='secondTable'>
        <StyledTypographyH3 variant='h2'>{t('dao.request_history')}</StyledTypographyH3>
        <MyDAOHistoryTableView />
      </Box>
    </Container>
  );
};

const StyledTypographyH2 = styled(Typography)<TypographyProps>({
  margin: '0 0 15px',
  color: '#292D3F',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '34px',
  lineHeight: '48px',
});
const StyledTypographyH3 = styled(Typography)<TypographyProps>({
  margin: '0 0 24px',
  color: '#292D3F',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '25px',
  lineHeight: '40px',
});
