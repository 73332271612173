import { ApiPromise } from '@polkadot/api';
import { SubmittableExtrinsic } from '@polkadot/api/types';
import { web3Enable, web3FromAddress } from '@polkadot/extension-dapp';
import { ISubmittableResult } from '@polkadot/types/types';
import { walletStore } from 'store';
import { showNotification, showTransactionStatus } from 'utils';

const removeStatusCodeIfExist = (text: string) => {
  const pattern = /^(\d+):(.*)/;
  const matchResult = text.match(pattern);

  if (matchResult) {
    // const statusCode = matchResult[1];
    const result = matchResult[2].trim();
    return result;
  } else {
    return text;
  }
};

export const handleTransactionResult = async (
  request: SubmittableExtrinsic<'promise', ISubmittableResult>,
  api: ApiPromise,
  name: string,
  setStatus: React.Dispatch<React.SetStateAction<string>> | ((status: string) => void),
) => {
  await web3Enable('decentro.parametricx.io');
  const { signer } = await web3FromAddress(walletStore.walletAddress);
  let operationResult = 'success';

  await request
    .signAndSend(walletStore.walletAddress, { signer }, async (result) => {
      if (result.dispatchError) {
        const { docs } = await api.registry.findMetaError(result.dispatchError.asModule);
        showNotification({
          title: 'Transaction status',
          message: removeStatusCodeIfExist(docs.join()),
          type: 'warning',
        });
        operationResult = 'error';

        if (setStatus) {
          setStatus(operationResult);
        }
        api.disconnect();
      } else {
        const status = result.status.type;
        showTransactionStatus(status, name);

        if (setStatus) {
          setStatus(status);
        }
        if (status === 'Finalized') {
          api.disconnect();
        }
      }
    })
    .catch((error: Error) => {
      showNotification({ title: 'Transaction status:', message: `${error.message}`, type: 'warning' });
      operationResult = 'cancel';

      if (setStatus) {
        setStatus('error');
      }
      api.disconnect();
    });

  return operationResult;
};
