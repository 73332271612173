import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import SucessfullyIMG from '../img/sucessfully.png';
import { NavLink, useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { IDataType } from './ModalForm';

const FormStyle = {
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '24px',
  color: 'rgba(41, 45, 63, 0.72)',
  fontFamily: 'Questrial',
};
const ButtonStyle = {
  fontFamily: 'Questrial',
  border: `2px solid #3555C8`,
  borderRadius: `12px`,
  backgroundColor: '#3555C8',
  justifyContent: 'center',
  padding: '8px 12px',
  cursor: 'pointer',
  fontSize: '14px',
  lineHeight: '24px',
  color: 'white',
  textTansform: 'none !important',
  marginLeft: '0px',
  width: '100%',
  height: '48px',
  marginTop: '18px',
};

interface Props {
  setStep: (step: number) => void;
  formValues: NonNullable<any>;
  setFormValues: React.SetStateAction<NonNullable<any>>;
  handleClose: () => void;
  data: IDataType;
}

export default function Step3({ setStep, formValues, setFormValues, handleClose, data }: Props) {
  const { t } = useTranslation();
  const history = useNavigate();
  const { handleSubmit } = useForm();

  const onSubmit: SubmitHandler<FieldValues> = async (values) => {
    setFormValues({
      ...formValues,
      ...values,
    });

    setStep(1);
    handleClose();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={FormStyle}>
      <Box
        sx={{
          textAlign: 'center',
          marginBottom: '20px',
        }}
      >
        <Box component='img' alt='Filters' src={SucessfullyIMG} sx={{ paddingRight: '10px' }} />
      </Box>
      <Typography
        variant='h2'
        sx={{
          color: '#292D3F',
          fontWeight: 'bold',
          marginBottom: '20px',
          textAlign: 'center',
          lineHeight: {
            xs: '28px',
            sm: '42px',
          },
        }}
      >
        {data.holder && t('modal_steps.purchased')}
        {!data.holder && t('modal_steps.request')}
      </Typography>

      <Box
        sx={{
          textAlign: 'center',
        }}
      >
        <Typography variant='subtitle2'>
          {data.holder && t('modal_steps.you_can')}
          {data.holder && <br />}
          {data.holder && <NavLink to='/my-policies'>{t('modal_steps.view')}</NavLink>}
          {!data.holder && (
            <Trans
              i18nKey='modal_steps.during'
              components={{ link1: <NavLink to={'/my-policies'} title={''} onClick={() => history('/my-policies')} /> }}
            />
          )}
        </Typography>
      </Box>

      <Box>
        <Button type='submit' sx={ButtonStyle}>
          <Typography variant='button'>{t('modal_steps.finish_btn')}</Typography>
        </Button>
      </Box>
    </form>
  );
}
