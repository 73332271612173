import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Checkbox from '@mui/material/Checkbox';
import { authStore } from 'store';
import { MAX_SAFE_INTEGER } from '../constants';
import { useTranslation } from 'react-i18next';

import { calculateTransactionFeeForSale } from '../services/PutOnSale';
import { Loader } from 'components';
import { Box, Button, IconButton, InputAdornment, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { IDataType } from './PutOnSaleModalForm';

const FormStyle = {
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '24px',
  color: 'rgba(41, 45, 63, 0.72)',
  fontFamily: 'Questrial',
};
const InfoStyle = {
  fontSize: '12px',
  lineHeight: '20px',
  position: 'relative',
  justifyContent: 'space-between',
  marginBottom: '10px',
  marginTop: '10px',
  display: 'flex',
  flexDirection: 'row',
};
const TextFieldStyle = {
  color: 'rgba(41, 45, 63, 0.72)',
  display: 'inline-block',
  margin: '0px 36px 0px 0px',
  borderRadius: '12px !important',
  background: 'rgba(41, 45, 63, 0.03)',
  width: '100%',
};

const FakeTextFieldStyle = {
  margin: 0,
  padding: '16.5px 14px',
  width: '100%',
  background: 'rgba(41, 45, 63, 0.03)',
  border: ' 1px solid rgba(118, 118, 118, 0.5)',
  borderRadius: '12px',
  color: 'rgba(41, 45, 63, 0.72)',
  boxSizing: 'border-box',
  fontSize: '14px',
  lineHeight: '19px',
  fontFamily: 'Questrial',
};

const LabelStyle = {
  fontSize: '12px',
  color: 'rgba(41, 45, 63, 0.72)',
  textTransform: 'capitalize',
};

const ContainerStyle = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '10px',
};

export interface FormData {
  start_date: number;
  end_date: number;
  premium_amount: number;
  underwrite_amount: number;
  location: string;
  event: string;
  step: number;
  agree?: boolean;
  agree2?: boolean;
  number_of_copies: number;
  agreeIAgreePT: boolean;
  agreeIAgreePP: boolean;
  smtId: number;
  amount: number;
  price: number;
}

interface Props {
  setStep: (step: number) => void;
  formValues: FormData;
  setFormValues: React.Dispatch<React.SetStateAction<NonNullable<FormData>>>;
  data: IDataType;
  closeModal: () => void;
}

export default function PutOnSaleStep1({ setStep, formValues, setFormValues, data, closeModal }: Props) {
  const { t } = useTranslation();

  const [txFee, setTxFee] = useState('');

  const checkboxChangeIAgreePT = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      agree: event.target.checked,
    });
  };
  const checkboxChangeIAgreePP = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      agree2: event.target.checked,
    });
  };

  useEffect(() => {
    function insertData() {
      if (data.country) {
        setFormValues({
          ...formValues,
          location: data.country,
        });
      }

      if (data.event) {
        setFormValues({
          ...formValues,
          event: data.event,
        });
      }

      if (data.amount) {
        setAmount(data.amount);
        setAmountZero(false);
      }
      if (data.price) {
        setPricePerToken(data.price);
        setPriceZero(false);
      }
    }

    insertData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const methods = useForm<FormData>({
    defaultValues: {
      smtId: data?.smtId,
    },
  });

  const formData = methods.watch();

  const handleDateChange = (date: Date | null) => {
    // handle the selected date
  };

  const onSubmit: SubmitHandler<FormData> = async (values) => {
    if (startDate !== undefined && endDate !== undefined && amount !== undefined && data.smtId !== undefined) {
      values.start_date = startDate;
      values.end_date = endDate;

      values.amount = amount;
      values.smtId = data.smtId;

      setFormValues({
        ...formValues,
        ...values,
      });

      setStep(2);
    } else {
      console.error('Form data is undefined');
    }
  };

  const startDate = data.startTime;

  const endDate = data.expireTime;

  const [amount, setAmount] = useState(data.amount);
  const [amountZero, setAmountZero] = useState(true);
  const handleChangeAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isSafeInteger(+event.target.value)) {
      const value = Number(event.target.value);
      setAmount(value);

      if (Number(value) < 1 || Number(value) > data.amount) {
        setAmountZero(true);
      } else {
        setAmountZero(false);
      }
    }
  };

  const [pricePerToken, setPricePerToken] = useState(data.price);
  const [priceZero, setPriceZero] = useState(false);
  const handleChangePricePerToken = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isSafeInteger(+event.target.value)) {
      const value = Number(event.target.value);
      setPricePerToken(value);

      if (value < 1 || value > MAX_SAFE_INTEGER) {
        setPriceZero(true);
      } else {
        setPriceZero(false);
      }
    }
  };

  const ButtonStyle = {
    fontFamily: 'Questrial',
    border:
      !formValues?.agree || !formValues?.agree2 || amountZero || priceZero
        ? '2px solid rgba(41, 45, 63, 0.3)'
        : '2px solid #3555C8',
    borderRadius: `12px`,
    backgroundColor:
      !formValues?.agree || !formValues?.agree2 || amountZero || priceZero ? 'rgba(41, 45, 63, 0.3)' : '#3555C8',
    justifyContent: 'center',
    padding: '8px 12px',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '24px',
    color: 'white',
    textTansform: 'none !important',
    marginLeft: '0px',
    width: '100%',
    height: '48px',
    marginTop: '10px',
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    (async () => {
      if (Boolean(String(formData.smtId)) && amount && pricePerToken) {
        try {
          timeout = setTimeout(
            async () => setTxFee(await calculateTransactionFeeForSale(formData.smtId, amount, pricePerToken)),
            2000,
          );
        } catch (error) {
          console.log('Error fee', error);
        }
      }
    })();

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [formData, amount, pricePerToken]);

  const matchesSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} style={FormStyle}>
        <Box>
          <Stack
            direction='row'
            width='100%'
            alignItems='center'
            sx={{ justifyContent: { xs: 'space-between', md: 'left' } }}
          >
            <Typography
              variant='h2'
              sx={{
                color: '#292D3F',
                fontWeight: 'bold',
                marginBottom: '10px',
              }}
            >
              {t('put_on_sale.put_on_sale')}
            </Typography>
            {matchesSm && (
              <IconButton onClick={closeModal}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='lucide lucide-x'
                >
                  <path d='M18 6 6 18' />
                  <path d='m6 6 12 12' />
                </svg>
              </IconButton>
            )}
          </Stack>

          <Box sx={ContainerStyle}>
            <Box>
              <Typography variant='subtitle1' sx={LabelStyle}>
                {t('put_on_sale.location')}
              </Typography>
              <Box {...methods.register('location')} sx={FakeTextFieldStyle}>
                <Typography variant='subtitle2'>{data.country}</Typography>
              </Box>
            </Box>
            <Box>
              <Typography variant='subtitle1' sx={LabelStyle}>
                {t('put_on_sale.event')}
              </Typography>
              <Box {...methods.register('event')} sx={FakeTextFieldStyle}>
                <Typography variant='subtitle2'>{data.event}</Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: '100%',
              borderTop: '1px solid rgba(41, 45, 63, 0.13)',
              marginTop: '10px',
              height: '10px',
            }}
          >
            &nbsp;
          </Box>

          <Box sx={ContainerStyle}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box>
                <Typography
                  variant='subtitle1'
                  sx={{
                    color: 'rgba(41, 45, 63, 0.72)',
                  }}
                >
                  {t('put_on_sale.start_date')}
                </Typography>
                <DesktopDatePicker
                  inputFormat='MM/DD/YYYY'
                  value={startDate}
                  minDate={new Date()}
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...methods.register('start_date')}
                      sx={TextFieldStyle}
                      className={'DatePickerFull'}
                    />
                  )}
                  disabled={true}
                />
              </Box>
              <Box>
                <Typography
                  variant='subtitle1'
                  sx={{
                    color: 'rgba(41, 45, 63, 0.72)',
                  }}
                >
                  {t('put_on_sale.end_date')}
                </Typography>
                <DesktopDatePicker
                  inputFormat='MM/DD/YYYY'
                  value={endDate}
                  minDate={new Date()}
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...methods.register('end_date')}
                      sx={TextFieldStyle}
                      className={'DatePickerFull'}
                    />
                  )}
                  disabled={true}
                />
              </Box>
            </LocalizationProvider>
          </Box>

          <Box
            sx={{
              width: '100%',
              borderTop: '1px solid rgba(41, 45, 63, 0.13)',
              marginTop: '10px',
              height: '10px',
            }}
          >
            &nbsp;
          </Box>

          <Box
            sx={{
              position: 'relative',
              color: 'rgba(41, 45, 63, 0.72)',
            }}
          >
            <Typography variant='subtitle1'>{t('put_on_sale.number_of_copies')}</Typography>
          </Box>
          <TextField
            {...methods.register('amount')}
            sx={TextFieldStyle}
            className={'TextFieldFull'}
            value={amount}
            onChange={handleChangeAmount}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Typography variant='subtitle2'>{t('put_on_sale.copies')}</Typography>
                </InputAdornment>
              ),
            }}
          />
          <Box
            sx={{
              fontSize: '12px',
              color: 'rgba(41, 45, 63, 0.72)',
              marginBottom: '10px',
            }}
          >
            <Typography variant='subtitle1'>
              {t('put_on_sale.current')} - {data.amount}/{data.available}
            </Typography>
            {amountZero && data.amount !== undefined && data.amount > 0 && (
              <Typography variant='subtitle1' sx={{ color: '#FF8663' }}>
                {t('put_on_sale.enter')} {data.amount}.
              </Typography>
            )}
            {amountZero && data.amount !== undefined && data.amount < 1 && (
              <Typography variant='subtitle1' sx={{ color: '#FF8663' }}>
                {t('put_on_sale.available')}
              </Typography>
            )}
          </Box>

          <Box
            sx={{
              position: 'relative',
              color: 'rgba(41, 45, 63, 0.72)',
            }}
          >
            <Typography variant='subtitle1'>{t('put_on_sale.price')}</Typography>
          </Box>

          <TextField
            {...methods.register('price')}
            sx={TextFieldStyle}
            className={'TextFieldFull'}
            disabled={false}
            value={pricePerToken}
            onChange={handleChangePricePerToken}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Typography variant='subtitle2'>{t('USDT')}</Typography>
                </InputAdornment>
              ),
            }}
          />

          {priceZero && (
            <Typography variant='subtitle1' sx={{ color: '#FF8663' }}>
              {t('put_on_sale.number')} {MAX_SAFE_INTEGER}
            </Typography>
          )}

          <Box sx={InfoStyle}>
            <Typography variant='subtitle1'>{t('put_on_sale.transaction')}</Typography>
            <Box
              sx={{
                width: '100px',
                borderTop: '1px solid rgba(41, 45, 63, 0.72)',
                marginTop: 'inherit',
              }}
            >
              &nbsp;
            </Box>
            <Box sx={{ marginLeft: { xs: '5px', sm: '0' } }}>
              {txFee ? <Typography variant='subtitle1'>{txFee}</Typography> : <Loader size={10} small />}
            </Box>
          </Box>
        </Box>

        <Stack direction='row' alignItems='center'>
          <Checkbox {...methods.register('agree')} onChange={checkboxChangeIAgreePT} checked={formValues?.agree} />{' '}
          <Typography variant='subtitle2'>
            {t('put_on_sale.agree')}
            &nbsp;
            <NavLink to='/'>{t('put_on_sale.terms')}</NavLink>
          </Typography>
        </Stack>

        <Stack direction='row' alignItems='center'>
          <Checkbox {...methods.register('agree2')} onChange={checkboxChangeIAgreePP} checked={formValues?.agree2} />{' '}
          <Typography variant='subtitle2'>
            {t('put_on_sale.agree')}
            &nbsp;
            <NavLink to='/'>{t('put_on_sale.privacy')}</NavLink>
          </Typography>
        </Stack>

        <Box>
          {authStore.isAuthorized && (
            <Button
              type='submit'
              sx={ButtonStyle}
              disabled={!formValues?.agree || !formValues?.agree2 || amountZero || priceZero}
            >
              <Typography variant='button'>{t('put_on_sale.continue')}</Typography>
            </Button>
          )}
        </Box>
      </form>
    </FormProvider>
  );
}
