import React, { useMemo } from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import { getAdminStats } from 'api';
import { StatsType } from 'types';
import { Box, styled } from '@mui/material';
import { useFetch } from 'hooks/useFetch';
import { Loader } from 'components';
import { useTranslation } from 'react-i18next';
import InsuranceTable from 'features/policies/InsuranceTable';

const initialValue = {
  activeInsurances: 0,
  activePremium: 0,
  activeUnderwrite: 0,
  concludedInsurances: 0,
  concludedPremium: 0,
  concludedUnderwrite: 0,
};

export const AdminInsurancesView = () => {
  const { t } = useTranslation();
  const { loading, response } = useFetch(getAdminStats, initialValue);
  const { active, concluded } = useMemo(() => {
    const formattedData = Object.entries(response) as StatsType;

    return formattedData.reduce(
      (acc, [name, value]) => {
        const flatWord = t(`admin.${name}`);

        if (name.includes('active')) {
          return { ...acc, active: [...acc.active, [flatWord, value] as [string, number]] };
        } else {
          return { ...acc, concluded: [...acc.concluded, [flatWord, value] as [string, number]] };
        }
      },
      { active: [] as [string, number][], concluded: [] as [string, number][] },
    );
  }, [response, t]);

  return (
    <>
      <StyledTypography variant='h1'>Active Insurances</StyledTypography>
      <InsuranceTable />
    </>
  );
};

const StyledTypography = styled(Typography)<TypographyProps>({
  margin: '84px 0 24px',
  fontFamily: 'Poppins',
});

const StyledTableCell = styled(TableCell)<React.CSSProperties>({
  padding: '20px 32px',
  border: '1px solid rgba(41, 45, 63, 0.13)',
  width: '33.33%',
});

const StyledTableTitle = styled(Typography)<TypographyProps>({
  margin: '0 0 8px 0',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '20px',
  lineHeight: '32px',
  color: 'rgba(41, 45, 63, 0.48)',
  textTransform: 'lowercase',
  whiteSpace: 'nowrap',
  textAlign: 'left',
  [`&:first-letter`]: {
    textTransform: 'uppercase',
  },
});
const containerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
} as React.CSSProperties;

const StyledTableMessage = styled(Typography)<TypographyProps>({
  margin: '0',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '28px',
  lineHeight: '40px',
  color: '#292D3F',
  textAlign: 'left',
});

const StyledTablePercent = styled(Typography)<TypographyProps>({
  margin: '0',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '28px',
  color: '#292D3F',
});
