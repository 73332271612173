import { authStore, walletStore } from 'store';
import { countryOptions } from './CountryOptions';
import axios from 'axios';
import { dateFormat } from 'utils';
import { appConfig } from '../config';

const getCountry = (value: string) => {
  const options = countryOptions.filter((countryData) => {
    return countryData.value === value;
  });

  return options[0]?.label ? options[0]?.label : '';
};

const createData = (
  id: number,
  name: string,
  location: string,
  mint: string,
  expiration: string,
  comission: string,
  underwrite: string,
  active: number,
  smtId: number,
  amount: number,
  free: number,
  partId: number,
  isClaimed: boolean,
) => {
  return {
    id,
    name,
    location,
    mint,
    expiration,
    comission,
    underwrite,
    active,
    smtId,
    amount,
    free,
    partId,
    isClaimed,
  };
};

export default async function GetLiquidityProviders() {
  const liquidityProvider = [];

  let i = 1;

  try {
    const response = await axios.get(`${appConfig.apiUrl}/insurance/lp/${walletStore.walletAddress}?pageSize=1000000`, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${authStore.accessToken}`,
      },
    });

    if (response.data.total > 0) {
      for (const row of response.data.results) {
        liquidityProvider.push(
          createData(
            i++,
            row.insurance.risk,
            getCountry(row.insurance.location),
            dateFormat(row.insurance.startTime),
            dateFormat(row.insurance.endTime),
            `${row.insurance.premium}`,
            `${row.insurance.underwrite}`,
            row.insurance.status,
            row.insurance.smtId,
            row.amount,
            row.free,
            row.insurance.id,
            row.isClaimed,
          ),
        );
      }
    }
  } catch (error) {
    console.log('Missing data for Liquidity Providers table');
  }

  return liquidityProvider;
}
